import {Box, Container} from "@mui/material";
import {useEffect, useMemo, useState} from "react";
import {BaseTable} from "src/components/BaseTable";
import {FacilityRow} from "src/components/FacilityRow";
import {
  useGetUsersQuery,
  useLazyGetCurrentScheduleQuery,
} from "src/redux/api/userApi";
import {CalendarView} from "./components/CalendarView";
import {DayModal} from "./components/DayModal";
import dayjs from "dayjs";
import {useAppSelector} from "src/redux/store";
import {useNavigate} from "react-router-dom";
import {routes} from "src/routes/routes";

export const MySchedulePage = () => {
  const navigate = useNavigate();
  const [year, setYear] = useState<number>(new Date().getFullYear());
  const [month, setMonth] = useState<number>(new Date().getMonth());
  const [dayModalOpen, setDayModalOpen] = useState(false);
  const [modalDate, setModalDate] = useState<string | undefined>(
    new Date().toISOString()
  );
  const activeUser = useAppSelector((state) => state.userState.user);

  const {data: users} = useGetUsersQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const [fetchSchedules, {data: schedules}] = useLazyGetCurrentScheduleQuery();

  const handleDayClick = (date: string) => {
    setDayModalOpen(true);
    setModalDate(date);
  };

  useEffect(() => {
    fetchSchedules({}); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSetMonth = (newMonth: number) => {
    if (newMonth === 12) {
      setMonth(0);
      setYear((prev) => prev + 1);
    } else if (newMonth === -1) {
      setMonth(11);
      setYear((prev) => prev - 1);
    } else {
      setMonth(newMonth);
    }
  };

  const handleCloseModal = () => {
    setDayModalOpen(false);
    setModalDate(undefined);
  };

  const selectedDate = useMemo(() => new Date(year, month), [month, year]);

  const daySchedules = useMemo(
    () =>
      modalDate
        ? schedules?.filter(
            (item) =>
              new Date(item.startDate).getDate() ===
                new Date(modalDate).getDate() &&
              new Date(item.startDate).getMonth() === month
          )
        : [], // eslint-disable-next-line react-hooks/exhaustive-deps
    [modalDate, schedules]
  );

  return (
    <Container
      maxWidth={false}
      sx={{
        display: "grid",
        gap: 6,
        gridAutoFlow: "column",
        gridAutoColumns: "1fr 1fr",
        minHeight: "100%",
        py: 2,
      }}
    >
      <BaseTable
        headerTitle="Lista dyżurów"
        headerButtonTitle={
          activeUser?.role === "senior_security_worker" ? "Budynki" : undefined
        }
        headerClick={() =>
          activeUser?.role === "senior_security_worker" &&
          navigate(routes.schedules.facility)
        }
      >
        {schedules &&
          [...schedules]
            .sort(
              (a, b) =>
                new Date(a.startDate).getTime() -
                new Date(b.startDate).getTime()
            )
            .map((schedule) => (
              <FacilityRow
                key={schedule.id}
                id={schedule.id}
                name={`${dayjs(schedule.startDate).format("DD.MM.YYYY HH:mm")}-
              ${dayjs(schedule.endDate).format("HH:mm")}`}
                activeId=""
                location={schedule.facilityLocation}
                onClick={() => {}}
                description={schedule.facilityName}
                additionalDescription={schedule.subFacilityName}
              />
            ))}
      </BaseTable>
      {schedules && (
        <Box sx={{gridColumnStart: 2, gridColumnEnd: 4}}>
          <BaseTable
            headerTitle={
              users?.find((item) => item.id === activeUser?.id)?.username ?? ""
            }
          >
            <CalendarView
              schedules={schedules.filter(
                (item) =>
                  dayjs(item.endDate).format("YYYY-MM") ===
                  dayjs(new Date(year, month)).format("YYYY-MM")
              )}
              month={month}
              year={year}
              selectedDate={selectedDate}
              setMonth={handleSetMonth}
              setYear={setYear}
              onClick={handleDayClick}
            />
          </BaseTable>
        </Box>
      )}
      <DayModal
        handleClose={handleCloseModal}
        daySchedules={daySchedules}
        isOpen={dayModalOpen}
        title={
          `Grafik - ${
            users?.find((item) => item.id === activeUser?.id)?.username
          }` ?? ""
        }
      ></DayModal>
    </Container>
  );
};
