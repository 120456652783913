import {Box, Container} from "@mui/system";
import {useNavigate} from "react-router";
import {BaseTable} from "src/components/BaseTable";
import {DutyForm} from "./components/DutyForm";
import {useAddDutyListMutation} from "src/redux/duties/dutiesApi";
import {AddDutyFormType} from "src/redux/duties/duties.types";
import {useEffect} from "react";
import {toast} from "react-toastify";

export const AddDutiesFormPage = () => {
  const navigate = useNavigate();

  const [createDutyList, {isSuccess}] = useAddDutyListMutation();

  const handleSubmitForm = (values: AddDutyFormType) => {
    createDutyList(values);
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success("Pomyśnie dodano listę");
      navigate(-1);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  return (
    <Container
      maxWidth={false}
      sx={{
        display: "grid",
        gap: 6,
        gridAutoFlow: "column",
        gridAutoColumns: "1fr 1fr",
        minHeight: "100%",
        py: 2,
      }}
    >
      <BaseTable
        headerButtonTitle={"Powrót"}
        headerClick={() => navigate(-1)}
        headerTitle={"Dodaj listę"}
      >
        <Box py={2} px={3} gap={3} display="flex" flexDirection="column">
          <DutyForm onSubmit={handleSubmitForm} />
        </Box>
      </BaseTable>
    </Container>
  );
};
