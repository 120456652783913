import {
  Badge,
  Box,
  Container,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import { MenuItem } from "./MenuItem";
import {
  AppsRounded,
  WarningRounded,
  Message,
  Notifications,
  Business,
  Work,
  Security,
  PeopleAlt,
  AccountBox,
  ListAlt,
  CalendarMonth,
  WatchLater,
} from "@mui/icons-material";
import { useLocation, useMatch, useNavigate } from "react-router-dom";

import { PrimaryButton } from "./PrimaryButton";
import { useDispatch } from "react-redux";
import { logout } from "src/redux/user/userSlice";
import { routes } from "src/routes/routes";
import { NotificationsModal } from "./notifications/NotificationsModal";
import { useEffect, useMemo, useState } from "react";
import { useAppSelector } from "src/redux/store";
import { useGetNotificationsQuery } from "src/redux/api/userApi";

const pageSize = 6;

export const SideMenu = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const user = useAppSelector((state) => state.userState.user);

  const { pathname } = useLocation();

  const logoutPress = () => {
    dispatch(logout());
  };

  const { data: notifications, refetch } = useGetNotificationsQuery(
    {
      PageSize: pageSize,
      Page: pageNumber,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const refreshData = async () => {
    await refetch();
  };

  const unreadCount = useMemo(
    () => notifications?.totalUnreadCount ?? 0,
    [notifications]
  );

  const handleRedirectSchedule = () => {
    if (user?.role === "employee" || user?.role === "senior_security_worker") {
      navigate(routes.schedules.me);
      return;
    }
    navigate(routes.schedules.main);
  };

  useEffect(() => {
    if (user?.role === "employee" && unreadCount && unreadCount > 0) {
      setModalOpen(true);
    }
  }, [unreadCount, user]);

  const handleCloseModal = () => {
    if (user?.role === "employee" && unreadCount && unreadCount > 0) {
      return;
    } else {
      setModalOpen(false);
    }
  };

  const isActive = (param: string) => {
    return pathname.includes(param);
  };

  return (
    <Container
      maxWidth="xs"
      disableGutters
      sx={{
        bgcolor: theme.palette.lightGray,
        flex: 1,
        paddingTop: 10,
        paddingBottom: 5,
        px: 7,
        mx: 0,
        position: "relative",
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
        gap: 5,
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography fontSize={18} fontFamily="Bold">
            {user?.username}
          </Typography>

          <Typography color={theme.palette.silver} fontSize={16}>
            {user?.internalId}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <PrimaryButton
            onClick={logoutPress}
            fontSize={18}
            px={2}
            py={1}
            name="wyloguj"
          />
          {user && user.role !== "customer_representative" && (
            <IconButton onClick={() => setModalOpen(true)}>
              <Badge
                badgeContent={unreadCount}
                color="error"
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              >
                <Notifications sx={{ fontSize: 32 }} />
              </Badge>
            </IconButton>
          )}
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
        <MenuItem
          visibility={["admin", "employee", "leader", "senior_security_worker"]}
          onClick={() => navigate(routes.home.main)}
          active={!!useMatch(routes.home.main)}
          name="Strona główna"
        >
          {({ color }) => (
            <AppsRounded fontSize="large" sx={{ color: color }} />
          )}
        </MenuItem>
        <MenuItem
          visibility={["admin", "employee", "leader", "senior_security_worker"]}
          onClick={() => navigate(routes.incidents.main)}
          active={isActive(routes.incidents.main)}
          name="Zgłoszenia"
        >
          {({ color }) => (
            <WarningRounded fontSize="large" sx={{ color: color }} />
          )}
        </MenuItem>
        <MenuItem
          visibility={[
            "customer_representative",
            "employee",
            "senior_security_worker",
          ]}
          onClick={() => navigate(routes.systemMessage.main)}
          active={isActive(routes.systemMessage.main)}
          name="Wiadomość systemowa"
        >
          {({ color }) => <Message fontSize="large" sx={{ color: color }} />}
        </MenuItem>
        <MenuItem
          visibility={["admin", "employee", "leader", "senior_security_worker"]}
          onClick={() => navigate(routes.messages.main)}
          active={isActive(routes.messages.main)}
          name="komunikaty"
        >
          {({ color }) => (
            <Notifications fontSize="large" sx={{ color: color }} />
          )}
        </MenuItem>
        <MenuItem
          visibility={["admin"]}
          onClick={() => navigate(routes.users.list("leader"))}
          active={isActive(routes.users.list("leader"))}
          name="kierownicy"
        >
          {({ color }) => <Work fontSize="large" sx={{ color: color }} />}
        </MenuItem>
        <MenuItem
          visibility={["admin", "employee", "leader", "senior_security_worker"]}
          onClick={handleRedirectSchedule}
          active={isActive(routes.schedules.main)}
          name="Grafik"
        >
          {({ color }) => (
            <CalendarMonth fontSize="large" sx={{ color: color }} />
          )}
        </MenuItem>
        <MenuItem
          visibility={["admin", "leader"]}
          onClick={() => navigate(routes.users.list("employee"))}
          active={isActive(routes.users.list("employee"))}
          name="pracownicy"
        >
          {({ color }) => <PeopleAlt fontSize="large" sx={{ color: color }} />}
        </MenuItem>
        <MenuItem
          visibility={["admin", "leader"]}
          onClick={() => navigate(routes.users.list("senior_security_worker"))}
          active={isActive(routes.users.list("senior_security_worker"))}
          name="starsi pracownicy"
        >
          {({ color }) => <Security fontSize="large" sx={{ color: color }} />}
        </MenuItem>
        <MenuItem
          visibility={["admin", "leader"]}
          onClick={() => navigate(routes.users.list("customer_representative"))}
          active={isActive(routes.users.list("customer_representative"))}
          name="reprezentanci"
        >
          {({ color }) => <AccountBox fontSize="large" sx={{ color: color }} />}
        </MenuItem>
        <MenuItem
          visibility={["admin", "leader"]}
          onClick={() => navigate(routes.facilities.main)}
          active={isActive(routes.facilities.main)}
          name="obiekty"
        >
          {({ color }) => <Business fontSize="large" sx={{ color: color }} />}
        </MenuItem>
        <MenuItem
          visibility={["admin", "leader"]}
          onClick={() => navigate(routes.duties.main)}
          active={isActive(routes.duties.main)}
          name="listy"
        >
          {({ color }) => <ListAlt fontSize="large" sx={{ color: color }} />}
        </MenuItem>
        <MenuItem
          visibility={["admin", "leader"]}
          onClick={() => navigate(routes.worklog.main)}
          active={isActive(routes.worklog.main)}
          name="Raporty Pracy"
        >
          {({ color }) => <WatchLater fontSize="large" sx={{ color: color }} />}
        </MenuItem>
        {/* <MenuItem
          visibility={["admin", "employee", "leader", "senior_security_worker"]}
          onClick={() => navigate("/")}
          active={isActive("/aa")}
          name="informator"
        >
          {({color}) => <Info fontSize="large" sx={{color: color}} />}
        </MenuItem> */}
      </Box>
      {notifications && (
        <NotificationsModal
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          refresh={refreshData}
          total={notifications.totalCount}
          notifications={notifications?.items}
          handleClose={handleCloseModal}
          isOpen={modalOpen}
          pageSize={pageSize}
        />
      )}
    </Container>
  );
};
