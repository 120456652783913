import {
  Container,
  FormControl,
  FormHelperText,
  Input,
  InputProps,
  Typography,
  useTheme,
} from "@mui/material";
import {FC} from "react";
import {Controller, useFormContext} from "react-hook-form";

type IFormInputProps = {
  name: string;
  label: string;
} & InputProps;

export const FormInput: FC<IFormInputProps> = ({
  name,
  label,
  ...otherProps
}) => {
  const {
    control,
    formState: {errors},
  } = useFormContext();

  const theme = useTheme();

  return (
    <Controller
      control={control}
      defaultValue=""
      name={name}
      render={({field}) => (
        <FormControl fullWidth={otherProps.fullWidth}>
          <Container
            disableGutters
            maxWidth={false}
            sx={{display: "flex", gap: 2, alignItems: "center"}}
          >
            {label && (
              <Typography fontFamily="Regular" minWidth={180} fontSize={20}>
                {label}
              </Typography>
            )}
            <Input
              sx={{
                padding: 0.8,
                fontSize: 18,
                borderWidth: 1,
                fontFamily: "Regular",
                borderColor: theme.palette.silver,
                borderStyle: "solid",
              }}
              fullWidth
              {...field}
              disableUnderline
              error={!!errors[name]}
              {...otherProps}
            />
          </Container>
          <FormHelperText sx={{px: 0, mx: 0}} error={!!errors[name]}>
            <Typography textAlign={"left"} fontSize={20}>
              {errors[name] ? errors[name]?.message?.toString() : ""}
            </Typography>
          </FormHelperText>
        </FormControl>
      )}
    />
  );
};
