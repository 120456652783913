import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {RootState} from "../store";
import {
  IIncident,
  IncidentCreateForm,
  IncidentListItem,
} from "./incidents.types";
import {
  PaginatorParams,
  PaginatorParamsFilterType,
  PaginatorResponse,
} from "../api/types";
import querystring from "query-string";
const BASE_URL = process.env.REACT_APP_API_URL as string;

export const incidentsApi = createApi({
  reducerPath: "incidentsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL + "/api/incidents",
    prepareHeaders: (headers, {getState}) => {
      const token = (getState() as RootState).userState.accessToken;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getIncidents: builder.query<
      PaginatorResponse<IncidentListItem[]>,
      PaginatorParams<PaginatorParamsFilterType>
    >({
      query(queryParms) {
        return {
          url: `/?${querystring.stringify(queryParms)}`,
          method: "GET",
          credentials: "include",
        };
      },
    }),
    getIncidentById: builder.query<IIncident, {id: string}>({
      query(query) {
        return {
          url: `/${query.id}`,
          method: "GET",
          credentials: "include",
        };
      },
    }),
    createIncident: builder.mutation<{}, IncidentCreateForm>({
      query(query) {
        return {
          url: `/`,
          method: "POST",
          body: query,
          credentials: "include",
        };
      },
    }),
  }),
});

export const {
  useGetIncidentsQuery,
  useLazyGetIncidentsQuery,
  useGetIncidentByIdQuery,
  useCreateIncidentMutation,
} = incidentsApi;
