import {Button, ButtonProps, Typography, useTheme} from "@mui/material";

type IconButtonProps = {
  name: string;
  fontSize?: number;
  bgColor?: string;
  textColor?: string;
  fontFamily?: "Bold" | "SemiBold" | "Regular" | "Light";
  px?: number;
  py?: number;
  children?: React.ReactNode;
} & ButtonProps;

export const PrimaryButton = ({
  name,
  fontSize = 20,
  bgColor,
  px = 2,
  py = 2,
  textColor,
  children,
  fontFamily = "Bold",
  disabled = false,
  ...otherProps
}: IconButtonProps) => {
  const theme = useTheme();

  const backgroundColor = bgColor ? bgColor : theme.palette.warningRed;
  const typographyColor = textColor ? textColor : theme.palette.white;
  return (
    <Button
      {...otherProps}
      disabled={disabled}
      sx={{
        ...otherProps.sx,
        backgroundColor: backgroundColor,
        ":hover": {backgroundColor: backgroundColor, opacity: 0.5},
        borderRadius: 0,
        paddingX: px,
        paddingY: py,
        gap: 1,
        opacity: disabled ? 0.4 : 1,
      }}
    >
      {children}
      <Typography
        fontFamily={fontFamily}
        fontSize={fontSize}
        color={typographyColor}
      >
        {name}
      </Typography>
    </Button>
  );
};
