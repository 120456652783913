import {Box, Container} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import {BaseTable} from "src/components/BaseTable";
import {FacilityUsers} from "src/redux/facilities/facilities.types";
import {
  useAssignToSubFacilityMutation,
  useGetSubFacilityQuery,
  useGetSubFacilityUnassignedEmployeesQuery,
  useUnassignFromSubFacilityMutation,
} from "src/redux/facilities/facilitiesApi";
import {FacilityUserRow} from "../../components/FacilityUserRow";

type UsersManagementType = keyof FacilityUsers;

const getName = (userType: UsersManagementType) => {
  switch (userType) {
    case "customerRepresentatives":
      return {
        all: "Wszyscy reprezentanci klienta",
        assigned: "przypisani reprezentanci do obiektu",
        role: "customer_representative",
      };
    case "employees":
      return {
        all: "Wszyscy pracownicy",
        assigned: "Przypisani pracownicy do obiektu",
        role: "employee",
      };
    case "leaders":
      return {
        all: "Wszyscy kierownicy",
        assigned: "przypisani kierownicy do obiektu",
        role: "leader",
      };
    case "seniorSecurityWorkers":
      return {
        all: "Wszyscy starsi ochroniarze",
        assigned: "przypisani starsi ochroniarze do obiektu",
        role: "senior_security_worker",
      };
  }
};
export const SubFacilityUsersPage = () => {
  const {id, type} = useParams();
  const navigate = useNavigate();
  const userType = type as UsersManagementType;

  const {data: facility, refetch: refreshFacility} = useGetSubFacilityQuery(
    {id: id ? id : ""},
    {refetchOnMountOrArgChange: true}
  );

  const {data: unassignedUsers, refetch: refreshUnassigned} =
    useGetSubFacilityUnassignedEmployeesQuery(
      {
        id: id ? id : "",
        role: userType ? getName(userType).role : "",
      },
      {
        refetchOnMountOrArgChange: true,
      }
    );

  const [unassignUser] = useUnassignFromSubFacilityMutation();

  const [assignUser] = useAssignToSubFacilityMutation();

  if (!id || !type || !facility || !unassignedUsers) {
    return null;
  }

  const manageUsers = (assign: boolean, userId: string) => {
    if (assign === true) {
      assignUser({subFacilityId: id, userId: userId})
        .then(() => refreshFacility())
        .then(() => refreshUnassigned());
    } else {
      unassignUser({subFacilityId: id, userId: userId})
        .then(() => refreshFacility())
        .then(() => refreshUnassigned());
    }
  };

  const {name, assignedUsers} = facility;

  return (
    <Container
      maxWidth={false}
      sx={{
        minHeight: "100%",
        py: 2,
        gap: 2,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <BaseTable
        headerTitle={name}
        headerButtonTitle="Powrót"
        headerClick={() => navigate(-1)}
      />
      <Box
        sx={{
          display: "grid",
          gridAutoFlow: "column",
          gridAutoColumns: "1fr 1fr",
          height: "100%",
          gap: 4,
        }}
      >
        <BaseTable headerTitle={getName(userType).all}>
          <Box gap={3} display="flex" flexDirection="column">
            {unassignedUsers.map((user) => (
              <FacilityUserRow
                key={user.id}
                name={user.username}
                description={user.internalId}
                id={user.id}
                onClick={() => manageUsers(true, user.id)}
              />
            ))}
          </Box>
        </BaseTable>
        <BaseTable headerTitle={getName(userType).assigned}>
          <Box gap={3} display="flex" flexDirection="column">
            {assignedUsers[userType].map((user) => (
              <FacilityUserRow
                key={user.id}
                name={user.username}
                description={user.internalId}
                id={user.id}
                deleteMode
                onClick={() => manageUsers(false, user.id)}
              />
            ))}
          </Box>
        </BaseTable>
      </Box>
    </Container>
  );
};
