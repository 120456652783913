import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {LoginInput} from "../../pages/login.page";
import {setAccessToken} from "../user/userSlice";
import {userApi} from "./userApi";
import {ForgotPasswordInput} from "src/pages/forgotPassword.page";
import {ResetPasswordPayload} from "src/pages/resetPassword.page";
const BASE_URL = process.env.REACT_APP_API_URL as string;

export type ApiError = {
  code: string;
  reason: string;
};

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL + "/api/users",
  }),

  endpoints: (builder) => ({
    loginUser: builder.mutation<{accessToken: string}, LoginInput>({
      query(data) {
        return {
          url: "sign-in",
          method: "POST",
          body: data,
          credentials: "include",
        };
      },
      async onQueryStarted(args, {dispatch, queryFulfilled}) {
        try {
          const {data} = await queryFulfilled;
          dispatch(setAccessToken({accessToken: data.accessToken}));
          dispatch(
            userApi.endpoints.getMe.initiate(null, {forceRefetch: true})
          );
        } catch (error) {
          console.log("error", error);
        }
      },
    }),
    forgotPassword: builder.mutation<{}, ForgotPasswordInput>({
      query(data) {
        return {
          url: "forgot-password",
          method: "POST",
          body: data,
          credentials: "include",
        };
      },
    }),
    resetPassword: builder.mutation<{}, ResetPasswordPayload>({
      query(data) {
        return {
          url: "reset-password",
          method: "POST",
          body: data,
          credentials: "include",
        };
      },
    }),
  }),
});

export const {
  useLoginUserMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
} = authApi;
