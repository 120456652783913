import {FC, useState} from "react";
import {Controller, useFormContext} from "react-hook-form";
import {
  Autocomplete,
  Container,
  FormControl,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import {useDebouncedState} from "src/hooks/useDebounce";
import {useGetFacilitiesByUserQuery} from "src/redux/api/userApi";

type IFormInputProps = {
  name: string;
  label: string;
  userId: string;
};

export const AutocompleteFacilityInput: FC<IFormInputProps> = ({
  name,
  label,
  userId,
}) => {
  const {
    control,
    formState: {errors},
  } = useFormContext();
  const [query, setQuery] = useState("");
  const debSearchText = useDebouncedState(query, 500);

  const {data} = useGetFacilitiesByUserQuery({id: userId, name: debSearchText});

  return (
    <Controller
      control={control}
      name={name}
      render={({field}) => {
        const selectedOption =
          data?.find((item) => item.id === field.value) || null;

        return (
          <FormControl fullWidth={true}>
            <Container
              disableGutters
              maxWidth={false}
              sx={{display: "flex", gap: 2, alignItems: "center"}}
            >
              {label && (
                <Typography fontFamily="Regular" minWidth={180} fontSize={20}>
                  {label}
                </Typography>
              )}
              <Autocomplete
                fullWidth
                options={data ?? []}
                getOptionLabel={(option) => option.name}
                value={selectedOption}
                onChange={(event, newValue) =>
                  field.onChange(newValue?.id ?? undefined)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={label}
                    onChange={(e) => setQuery(e.target.value)}
                  />
                )}
              />
            </Container>
            <FormHelperText sx={{px: 0, mx: 0}} error={!!errors[name]}>
              <Typography textAlign={"left"} fontSize={20}>
                {errors[name] ? errors[name]?.message?.toString() : ""}
              </Typography>
            </FormHelperText>
          </FormControl>
        );
      }}
    />
  );
};
