import {Box, Container} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {BaseTable} from "src/components/BaseTable";
import {FacilityForm} from "./components/FacilityForm";
import {useAddFacilityMutation} from "src/redux/facilities/facilitiesApi";
import {useEffect} from "react";
import {toast} from "react-toastify";

export const FacilityFormPage = () => {
  const navigate = useNavigate();

  const [addFacility, {isSuccess}] = useAddFacilityMutation();

  useEffect(() => {
    if (isSuccess) {
      toast.success("Pomyślnie dodano obiekt");
      navigate("/facilities");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  return (
    <Container
      maxWidth={false}
      sx={{
        minHeight: "100%",
        py: 2,
      }}
    >
      <BaseTable
        headerTitle="dodaj obiekt"
        headerButtonTitle="Powrót"
        headerClick={() => navigate(-1)}
      >
        <Box py={2} px={3} gap={3} display="flex" flexDirection="column">
          <FacilityForm onSubmit={addFacility} />
        </Box>
      </BaseTable>
    </Container>
  );
};
