import {ArrowDownward} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  useTheme,
} from "@mui/material";

type CustomAccordionProps = {
  title: string;
  children: React.ReactNode;
};

export const CustomAccordion = ({title, children}: CustomAccordionProps) => {
  const theme = useTheme();
  return (
    <Accordion
      sx={{
        boxShadow: "0 0 0 0",
        borderBottomWidth: 1,
        borderRadius: 0,
        borderBottomStyle: "solid",
        borderBottomColor: theme.palette.silver,
        backgroundColor: theme.palette.lightGray,
      }}
    >
      <AccordionSummary expandIcon={<ArrowDownward />}>
        <Typography fontFamily="SemiBold" fontSize={20}>
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};
