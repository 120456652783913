import {Box, Modal, Typography, useTheme} from "@mui/material";
import {BaseTable} from "../BaseTable";
import {Container} from "@mui/system";
import {
  useDeleteNotificationMutation,
  useMarkAsReadNotificationMutation,
} from "src/redux/api/userApi";
import {useEffect, useMemo, useState} from "react";
import {CustomPagination} from "../CustomPagination";
import {NotificationListItem, NotificationModule} from "src/redux/api/types";
import dayjs from "dayjs";
import {PrimaryButton} from "../PrimaryButton";
import {NotificationItem} from "./NotificationItem";
import {toast} from "react-toastify";
import {useNavigate} from "react-router";
import {routes} from "src/routes/routes";

type NotificationsModalProps = {
  isOpen: boolean;
  handleClose: () => void;
  pageNumber: number;
  setPageNumber: (value: number) => void;
  notifications: NotificationListItem[];
  total: number;
  pageSize: number;
  refresh: () => void;
};

export const NotificationsModal = ({
  isOpen,
  handleClose,
  pageNumber,
  setPageNumber,
  notifications,
  refresh,
  total,
  pageSize,
}: NotificationsModalProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [activeNotificationId, setActiveNotificationId] = useState<string>();

  const [handleDelete, {isSuccess: successDelete}] =
    useDeleteNotificationMutation();

  const [handleMarkAsRead, {isSuccess: successUpdate}] =
    useMarkAsReadNotificationMutation();

  useEffect(() => {
    if (!activeNotification && notifications) {
      setActiveNotificationId(notifications[0]?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications]);

  const handleDeleteNotification = async (id: string) => {
    await handleDelete({id});
    refresh();
  };

  const handleMarkAsReadNotification = async (id: string) => {
    await handleMarkAsRead({id});
    refresh();
  };

  useEffect(() => {
    if (successDelete) {
      toast.success("Pomyślnie usunięto");
    }
    if (successUpdate) {
      toast.success("Pomyślnie zaktualizowano");
    }
  }, [successUpdate, successDelete]);

  const handleRedirectNotification = (module: NotificationModule) => {
    switch (module) {
      case "duty": {
        return;
      }
      case "facility": {
        navigate(routes.facilities.main);
        return;
      }
      case "incident": {
        navigate(routes.incidents.main);
        return;
      }
      case "schedule": {
        return;
      }
      case "time-message": {
        navigate(routes.messages.main);
        return;
      }
      default:
        return;
    }
  };

  const activeNotification: NotificationListItem | undefined = useMemo(
    () => notifications.find((item) => item.id === activeNotificationId),
    [notifications, activeNotificationId]
  );

  return (
    <Modal
      sx={{
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        paddingY: 15,
      }}
      open={isOpen}
      onClose={handleClose}
    >
      <Container
        maxWidth={"lg"}
        sx={{
          display: "grid",
          gap: 6,
          minHeight: "100%",
          gridAutoFlow: "column",
          gridAutoColumns: "1fr 1fr",
          background: theme.palette.white,
          p: 3,
        }}
      >
        {activeNotification && (
          <BaseTable headerTitle={activeNotification.title}>
            <Box>
              <Box p={3} gap={2} display="flex" flexDirection="column">
                <Typography fontSize={18} fontFamily="Light">
                  {dayjs(activeNotification.createdAt).format(
                    "DD.MM.YYYY HH:mm"
                  )}
                </Typography>
                <Typography
                  fontFamily="Bold"
                  fontSize={20}
                  textTransform="uppercase"
                >
                  {activeNotification.message}
                </Typography>
                <PrimaryButton
                  onClick={() =>
                    handleRedirectNotification(activeNotification.module)
                  }
                  sx={{maxWidth: "20%"}}
                  name="Przejdź"
                  px={1}
                  py={1}
                  fontSize={16}
                />
                <Box
                  sx={{
                    borderWidth: 1,
                    alignSelf: "end",
                    borderColor: theme.palette.raisinBlack,
                    borderStyle: "solid",
                    width: "fit-content",
                  }}
                >
                  <PrimaryButton
                    {...(activeNotification.isRead === true && {
                      bgColor: theme.palette.white,
                      textColor: theme.palette.raisinBlack,

                      disabled: true,
                    })}
                    onClick={() =>
                      handleMarkAsReadNotification(activeNotification.id)
                    }
                    fontSize={16}
                    px={1}
                    py={1}
                    name="Przeczytane"
                  />
                  <PrimaryButton
                    {...(activeNotification.isRead === false && {
                      bgColor: theme.palette.white,
                      textColor: theme.palette.raisinBlack,
                      disabled: true,
                    })}
                    onClick={() =>
                      handleMarkAsReadNotification(activeNotification.id)
                    }
                    fontSize={16}
                    px={1}
                    py={1}
                    name="Nieprzeczytane"
                  />
                </Box>
              </Box>
            </Box>
          </BaseTable>
        )}
        <BaseTable
          headerTitle="Powiadomienia"
          headerButtonTitle="Zamknij"
          headerClick={handleClose}
        >
          <Box display="flex" flexDirection="column" height={"100%"}>
            {notifications && notifications.length > 0 ? (
              notifications.map((item) => (
                <NotificationItem
                  handleDelete={handleDeleteNotification}
                  hideDeleteButton
                  handleMarkAsRead={handleMarkAsReadNotification}
                  isUnread={!item.isRead}
                  id={item.id}
                  title={item.title}
                  handleClick={() => setActiveNotificationId(item.id)}
                  created_date={dayjs(item.createdAt).format(
                    "DD.MM.YYYY HH:mm"
                  )}
                  key={item.id}
                  isActive={item.id === activeNotification?.id}
                />
              ))
            ) : (
              <Box p={3}>
                <Typography
                  fontFamily="SemiBold"
                  fontSize={20}
                  textTransform="uppercase"
                >
                  Twoja lista powiadomień jest pusta
                </Typography>
              </Box>
            )}
            {notifications && (
              <CustomPagination
                page={pageNumber}
                pageSize={pageSize}
                setPage={setPageNumber}
                total={total}
              />
            )}
          </Box>
        </BaseTable>
      </Container>
    </Modal>
  );
};
