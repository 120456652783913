import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {RootState} from "../store";
import {
  AddFacility,
  AddSubFacilityType,
  Facility,
  FacilityAssignedUserType,
  FacilityListItem,
  SubFacility,
  SubFacilityShort,
  UpdateFacilityType,
} from "./facilities.types";
const BASE_URL = process.env.REACT_APP_API_URL as string;

export const facilitiesApi = createApi({
  reducerPath: "facilitiesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL + "/api/facilities",

    prepareHeaders: (headers, {getState}) => {
      const token = (getState() as RootState).userState.accessToken;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getFacilities: builder.query<FacilityListItem[], {}>({
      query() {
        return {
          url: `/`,
          method: "GET",
          credentials: "include",
        };
      },
    }),
    getFacilitiesByQuery: builder.query<
      FacilityListItem[],
      {searchTerm: string}
    >({
      query(query) {
        return {
          url: `/${query.searchTerm}`,
          method: "GET",
          credentials: "include",
        };
      },
    }),
    getSubFacilitiesByQuery: builder.query<
      FacilityListItem[],
      {id: string; searchTerm: string; userId: string}
    >({
      query(query) {
        return {
          url: `/${query.id}/sub?search-term=${query.searchTerm}&user-id=${query.userId}`,
          method: "GET",
          credentials: "include",
        };
      },
    }),
    getFacilityDetails: builder.query<Facility, {id: string}>({
      query(query) {
        return {
          url: `/${query.id}`,
          method: "GET",
          credentials: "include",
        };
      },
    }),
    getFacilityUnassignedEmployees: builder.query<
      FacilityAssignedUserType[],
      {id: string; role: string}
    >({
      query(query) {
        return {
          url: `/unassigned-users/?FacilityId=${query.id}&Role=${query.role}`,
          method: "GET",
          credentials: "include",
        };
      },
    }),
    getSubFacility: builder.query<SubFacility, {id: string}>({
      query(query) {
        return {
          url: `/sub/${query.id}`,
          method: "GET",
          credentials: "include",
        };
      },
    }),
    deleteFacility: builder.mutation<{}, {id: string}>({
      query(query) {
        return {
          url: `/${query.id}`,
          method: "DELETE",
          credentials: "include",
        };
      },
    }),
    addFacility: builder.mutation<{}, AddFacility>({
      query(data) {
        return {
          url: `/`,
          method: "POST",
          body: data,
          credentials: "include",
        };
      },
    }),
    updateFacility: builder.mutation<{}, UpdateFacilityType>({
      query(data) {
        return {
          url: `/${data.id}`,
          method: "PUT",
          body: data,
          credentials: "include",
        };
      },
    }),
    assignToFacility: builder.mutation<
      {},
      {userId: string; facilityId: string}
    >({
      query(data) {
        return {
          url: `/assign-user`,
          method: "POST",
          body: data,
          credentials: "include",
        };
      },
    }),
    unassignFromFacility: builder.mutation<
      {},
      {userId: string; facilityId: string}
    >({
      query(data) {
        return {
          url: `/unassign-user`,
          method: "POST",
          body: data,
          credentials: "include",
        };
      },
    }),
    deleteSubFacility: builder.mutation<{}, {id: string}>({
      query(query) {
        return {
          url: `/sub/${query.id}`,
          method: "DELETE",
          credentials: "include",
        };
      },
    }),
    addSubFacility: builder.mutation<{}, AddSubFacilityType>({
      query(data) {
        return {
          url: `/sub`,
          method: "POST",
          body: data,
          credentials: "include",
        };
      },
    }),
    updateSubFacility: builder.mutation<{}, SubFacilityShort>({
      query(data) {
        return {
          url: `/sub/${data.id}`,
          method: "PUT",
          body: data,
          credentials: "include",
        };
      },
    }),
    getSubFacilityUnassignedEmployees: builder.query<
      FacilityAssignedUserType[],
      {id: string; role: string}
    >({
      query(query) {
        return {
          url: `/sub/unassigned-users/?SubFacilityId=${query.id}&Role=${query.role}`,
          method: "GET",
          credentials: "include",
        };
      },
    }),
    assignToSubFacility: builder.mutation<
      {},
      {userId: string; subFacilityId: string}
    >({
      query(data) {
        return {
          url: `/sub/assign-user`,
          method: "POST",
          body: data,
          credentials: "include",
        };
      },
    }),
    unassignFromSubFacility: builder.mutation<
      {},
      {userId: string; subFacilityId: string}
    >({
      query(data) {
        return {
          url: `/sub/unassign-user`,
          method: "POST",
          body: data,
          credentials: "include",
        };
      },
    }),
  }),
});

export const {
  useGetFacilitiesQuery,
  usePrefetch,
  useGetFacilityDetailsQuery,
  useDeleteFacilityMutation,
  useAddFacilityMutation,
  useGetFacilityUnassignedEmployeesQuery,
  useAssignToFacilityMutation,
  useUnassignFromFacilityMutation,
  useUpdateFacilityMutation,
  useAssignToSubFacilityMutation,
  useUnassignFromSubFacilityMutation,
  useGetSubFacilityUnassignedEmployeesQuery,
  useAddSubFacilityMutation,
  useDeleteSubFacilityMutation,
  useUpdateSubFacilityMutation,
  useGetSubFacilityQuery,
  useGetFacilitiesByQueryQuery,
  useLazyGetFacilitiesByQueryQuery,
  useGetSubFacilitiesByQueryQuery,
} = facilitiesApi;
