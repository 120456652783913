import {Box, Container} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {BaseTable} from "src/components/BaseTable";
import {MessageForm} from "./components/MessageForm";
import {MessageCreateFormType} from "src/redux/messages/messages.types";
import {useCreateMessageMutation} from "src/redux/messages/messages.api";
import {toast} from "react-toastify";
import {useEffect} from "react";
import {useAppSelector} from "src/redux/store";

export const AddMessageFormPage = () => {
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.userState.user);

  const handleGoBack = () => {
    navigate(-1);
  };

  const [addMessage, {isSuccess, isLoading}] = useCreateMessageMutation();

  const handleSubmitForm = (values: MessageCreateFormType) => {
    console.log("values", values.targetRole);
    addMessage({
      ...values,
      targetRole:
        values.targetRole === "leader" ||
        values.targetRole === "senior_security_worker"
          ? values.targetRole
          : null,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success("Dodano komunikat");
      handleGoBack();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  console.log("user", user);

  if (!user) {
    return null;
  }

  return (
    <Container
      maxWidth={false}
      sx={{
        minHeight: "100%",
        py: 2,
        display: "flex",
        gap: 4,
        flexDirection: "column",
      }}
    >
      <BaseTable
        headerTitle={"Dodaj Komunikat"}
        headerClick={handleGoBack}
        headerButtonTitle="Powrót"
        hideButton={user.role === "customer_representative"}
      >
        <Box py={2} px={3} gap={3} display="flex" flexDirection="column">
          <MessageForm
            isLoading={isLoading}
            userRole={user?.role}
            onSubmit={handleSubmitForm}
          />
        </Box>
      </BaseTable>
    </Container>
  );
};
