import {zodResolver} from "@hookform/resolvers/zod";
import {Box, CircularProgress, LinearProgress, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {FormProvider, SubmitHandler, useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {FormInput} from "src/components/FormInput";
import {PrimaryButton} from "src/components/PrimaryButton";
import {Section} from "src/components/Section";
import {IUser} from "src/redux/api/types";
import {useUpdatePasswordMutation} from "src/redux/api/userApi";
import {TypeOf, object, string} from "zod";

export type UpdatePasswordType = {
  password: string;
  newPassword: string;
};

const updatePasswordSchema = object({
  oldPassword: string({required_error: "Stare hasło jest wymagane"}),
  password: string({required_error: "Hasło jest wymagane"}).regex(
    /^(?=.[A-Za-z])(?=.*\d)(?=.*[^A-Za-z0-9]).{8,}$/,
    "Hasło musi mieć minimum 8 znaków, zawierać cyfrę i znak specjalny."
  ),
  confirmPassword: string({required_error: "Hasło jest wymagane"}),
}).refine((data) => data.password === data.confirmPassword, {
  message: "Hasła do siebie nie pasują",
  path: ["confirmPassword"],
});

type UpdatePasswordFormType = TypeOf<typeof updatePasswordSchema>;

type MailFormProps = {
  user?: IUser;
};

export const ChangePasswordForm = ({user}: MailFormProps) => {
  const methods = useForm<UpdatePasswordFormType>({
    resolver: zodResolver(updatePasswordSchema),
    mode: "all",
  });

  const [updatePassword, {isLoading, isSuccess}] = useUpdatePasswordMutation();
  const [progressValue, setProgressValue] = useState<number>(0);
  const [progressStatus, setProgressStatus] = useState<string>("Bardzo słabe");

  const {
    reset,
    handleSubmit,
    watch,
    formState: {isSubmitSuccessful, isDirty},
  } = methods;

  const passwordValue = watch("password");

  const onSubmit: SubmitHandler<UpdatePasswordFormType> = (values) => {
    updatePassword({
      password: values.oldPassword,
      newPassword: values.password,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success("Pomyślnie zaktualizowano hasło użytkownika");
    }
  }, [isSuccess]);

  useEffect(() => {
    reset(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful]);

  useEffect(() => {
    if (!passwordValue || passwordValue.length === 0) {
      setProgressStatus("Bardzo słabe");
      setProgressValue(0);
    } else if (
      passwordValue.match(/^(?=.[a-zA-Z])(?=.*\d)(?=.*[^A-Za-z\d])\S{12,}$/)
    ) {
      setProgressStatus("Bardzo dobre");
      setProgressValue(100);
    } else if (
      passwordValue.match(/^(?=.[A-Za-z])(?=.*\d)(?=.*[^A-Za-z0-9]).{8,}$/)
    ) {
      setProgressStatus("Solidne");
      setProgressValue(70);
    } else if (
      passwordValue.match(/^(?=.*[A-Za-z])(?=.*d)(?=.*[^A-Za-zd]).{6,}$/)
    ) {
      setProgressStatus("Przeciętne");
      setProgressValue(50);
    } else if (passwordValue.match(/^(?=.*[A-Za-zd]).{4,}$/)) {
      setProgressStatus("Słabe");
      setProgressValue(20);
    }
  }, [passwordValue]);

  return (
    <Section name="zmiana hasła">
      <FormProvider {...methods}>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          display="flex"
          flexDirection="column"
          gap={2}
          sx={{width: "100%"}}
        >
          <FormInput
            fullWidth
            label="Podaj nowe hasło"
            name="password"
            type="password"
          />

          <Box sx={{ml: "200px", width: "20%"}}>
            <LinearProgress
              variant="determinate"
              color={
                progressValue > 99
                  ? "success"
                  : progressValue > 60
                  ? "info"
                  : "warning"
              }
              value={progressValue}
            />
            <Typography fontFamily={"SemiBold"} fontSize={18}>
              {progressStatus}
            </Typography>
          </Box>
          <FormInput
            fullWidth
            label="Potwierdź nowe hasło"
            name="confirmPassword"
            type="password"
          />
          <FormInput
            fullWidth
            label="Podaj obecne hasło"
            name="oldPassword"
            type="password"
          />
          {isDirty && (
            <>
              {isLoading ? (
                <CircularProgress />
              ) : (
                <PrimaryButton
                  fontSize={16}
                  px={1}
                  py={1}
                  type="submit"
                  name="Aktualizuj dane"
                />
              )}
            </>
          )}
        </Box>
      </FormProvider>
    </Section>
  );
};
