import {PriorityHigh} from "@mui/icons-material";
import {Box, Typography, useTheme} from "@mui/material";

type IncidentRowPropsType = {
  isImportant: boolean;
  createdDate: Date;
  title: string;
  handleClick?: (id: string) => void;
  id?: string;
  activeId?: string;
  isActive?: boolean;
};

export const IncidentRow = ({
  isImportant,
  createdDate,
  title,
  id,
  handleClick,
  activeId,
  isActive = true,
}: IncidentRowPropsType) => {
  const theme = useTheme();
  const date = new Date(createdDate).toLocaleString("PL-pl");
  return (
    <Box
      {...(id && handleClick && {onClick: () => handleClick(id)})}
      sx={{
        px: 3,
        py: 1,
        display: "flex",
        gap: 1,
        borderBottomWidth: 1,
        borderBottomColor: theme.palette.silver,
        opacity: isActive ? 1 : 0.5,
        borderBottomStyle: "solid",
        ...(handleClick && {
          cursor: "pointer",
          ":hover": {
            backgroundColor: theme.palette.silver,
          },
        }),
        ...(id &&
          activeId &&
          id === activeId && {
            backgroundColor: theme.palette.silver,
          }),
      }}
    >
      <PriorityHigh sx={{opacity: isImportant ? 1 : 0}} />
      <Typography fontSize={20} fontFamily={"Regular"}>
        {date}
      </Typography>
      <Typography fontSize={20} fontFamily={"Regular"}>
        {title}
      </Typography>
    </Box>
  );
};
