import {Box, Container, Typography, useTheme} from "@mui/material";
import dayjs from "dayjs";
import {useState} from "react";
import {useSearchParams} from "react-router-dom";
import {BaseTable} from "src/components/BaseTable";
import {CustomPagination} from "src/components/CustomPagination";
import {FacilityRow} from "src/components/FacilityRow";
import {PaginatorWorkLogFilterType} from "src/redux/api/types";
import {
  useGetWorklogsQuery,
  useLazyGetWorklogByIdQuery,
} from "src/redux/duties/dutiesApi";
import {FilterBar} from "../Messages/components/FilterBar";
import {MessageInfoBar} from "../Messages/components/MessageInfoBar";
import {Check, Close} from "@mui/icons-material";

const pageSize = 10;
export const WorklogPage = () => {
  const theme = useTheme();
  let [searchParams, setSearchParams] = useSearchParams();
  let activeWorklog = searchParams.get("id") ?? "";
  const [filterType, setFilterType] = useState<PaginatorWorkLogFilterType>();

  const [pageNumber, setPageNumber] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const setActiveWorklog = (id: string) => {
    setSearchParams({id: id});
    fetchWorklog({id: id});
  };

  const {data: worklogs} = useGetWorklogsQuery(
    {
      Page: pageNumber,
      PageSize: pageSize,
      SearchTerm: searchValue,
      FilterType: filterType,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const handleSetFilterType = (value?: PaginatorWorkLogFilterType) => {
    setFilterType(value);
    setSearchValue("");
  };

  const [fetchWorklog, {data: currentWorklog}] = useLazyGetWorklogByIdQuery();

  return (
    <Container
      maxWidth={false}
      sx={{
        display: "grid",
        gap: 6,
        gridAutoFlow: "column",
        gridAutoColumns: "1fr 1fr",
        minHeight: "100%",
        py: 1.5,
      }}
    >
      {worklogs && (
        <BaseTable headerTitle="Raporty">
          <FilterBar
            variant="worklog"
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            filterType={filterType} //@ts-ignore
            setFilterType={handleSetFilterType}
          />
          <Box sx={{display: "flex", flexDirection: "column", height: "100%"}}>
            {worklogs?.items.map((item) => (
              <FacilityRow
                activeId={activeWorklog}
                id={item.id}
                name={dayjs(item.scheduleAssignedDate).format(
                  "DD.MM.YYYY HH:mm"
                )}
                onClick={setActiveWorklog}
                description={item.username}
                location={item.facilityName}
              />
            ))}
            <CustomPagination
              page={pageNumber}
              setPage={setPageNumber}
              pageSize={pageSize}
              total={worklogs.totalCount}
            />
          </Box>
        </BaseTable>
      )}
      {activeWorklog && currentWorklog && (
        <BaseTable headerTitle="Raport szczegółowy">
          <Box sx={{p: 2, display: "flex", flexDirection: "column", gap: 3}}>
            <Box
              display="flex"
              sx={{
                borderBottomStyle: "solid",
                borderBottomColor: theme.palette.silver,
                borderBottomWidth: 1,
              }}
            >
              <MessageInfoBar
                border={theme.palette.silver}
                title="Data dodania:"
                value={dayjs(currentWorklog.scheduleAssignedDate).format(
                  "DD.MM.YYYY HH:mm"
                )}
              />
              {currentWorklog && (
                <MessageInfoBar
                  border={theme.palette.silver}
                  title="Data aktualizacji:"
                  value={dayjs(currentWorklog.updateDate).format(
                    "DD.MM.YYYY HH:mm"
                  )}
                />
              )}
              <MessageInfoBar
                title="Pracownik:"
                value={currentWorklog.username}
              />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              gap={2}
              pb={1}
              sx={{
                borderBottomStyle: "solid",
                borderBottomColor: theme.palette.silver,
                borderBottomWidth: 1,
              }}
            >
              <Typography fontFamily="Bold" fontSize={18}>
                Ukończenie zadań:{" "}
                {currentWorklog.dutyCloseDate &&
                  dayjs(currentWorklog.dutyCloseDate).format(
                    "DD.MM.YYYY HH:mm"
                  )}
              </Typography>
              <Box
                display="grid"
                gridAutoFlow="column"
                gridAutoColumns="1fr 1fr"
                gap={2}
              >
                <Box>
                  <Typography fontFamily="Bold" fontSize={18}>
                    Czasy logowań:
                  </Typography>
                  {currentWorklog.loginTimes.map((val) => (
                    <Typography>
                      {dayjs(val).format("DD.MM.YYYY HH:mm")}
                    </Typography>
                  ))}
                </Box>
                <Box>
                  <Typography fontFamily="Bold" fontSize={18}>
                    Czasy wylogowań:
                  </Typography>
                  {currentWorklog.logoutTimes.map((val) => (
                    <Typography>
                      {dayjs(val).format("DD.MM.YYYY HH:mm")}
                    </Typography>
                  ))}
                </Box>
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" gap={1}>
              <Typography fontFamily="Bold" fontSize={18}>
                Lista Zadań
              </Typography>
              {currentWorklog.tasks.map((task, index) => (
                <Box
                  key={task.id}
                  sx={{
                    display: "grid",
                    gridAutoColumns: "5% 1fr 5%",
                    gridAutoFlow: "column",
                    background: theme.palette.white,
                    p: 1,
                  }}
                >
                  <Typography fontFamily="Regular">{index + 1}</Typography>
                  <Typography fontFamily="Regular">
                    {task.description}
                  </Typography>
                  {task.checkTime ? (
                    <Check fontSize="medium" color="success" />
                  ) : (
                    <Close fontSize="medium" color="error" />
                  )}
                </Box>
              ))}
              <Box bgcolor={theme.palette.white} p={1}>
                <Typography fontSize={18} fontFamily="Bold">
                  Opis:
                </Typography>
                <Typography
                  py={1}
                  px={2}
                  fontSize={16}
                  fontFamily="Regular"
                  sx={{textWrap: "wrap"}}
                  paragraph
                >
                  {currentWorklog.description}
                </Typography>
              </Box>
            </Box>
          </Box>
        </BaseTable>
      )}
    </Container>
  );
};
