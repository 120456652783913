import {Box, Container, Menu, MenuItem, Typography} from "@mui/material";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {BaseTable} from "src/components/BaseTable";
import {
  useDeleteSubFacilityMutation,
  useGetFacilityDetailsQuery,
  useGetSubFacilityQuery,
  useUnassignFromSubFacilityMutation,
  useUpdateSubFacilityMutation,
} from "src/redux/facilities/facilitiesApi";
import {FacilityRow} from "../../components/FacilityRow";
import {useEffect, useState} from "react";
import {PrimaryButton} from "src/components/PrimaryButton";
import {CustomAccordion} from "src/components/CustomAccordion";
import {CustomConfirmationModal} from "src/components/CustomConfirmationModal";
import {AddSubFacilityType} from "src/redux/facilities/facilities.types";
import {SubFacilityForm} from "./components/SubFacilityForm";
import {FacilityUserRow} from "../../components/FacilityUserRow";

export const SubFacilitiesPage = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  let [searchParams, setSearchParams] = useSearchParams();
  let activeFacility = searchParams.get("id") ?? "";

  const setActiveFacility = (id: string) => {
    setSearchParams({id: id});
  };

  const {data: parentFacility, refetch: refreshSubFacilities} =
    useGetFacilityDetailsQuery(
      {id: id ? id : ""},
      {refetchOnMountOrArgChange: true}
    );

  const {data: facility, refetch: refreshFacility} = useGetSubFacilityQuery({
    id: activeFacility,
  });

  useEffect(() => {
    refreshFacility(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFacility]);

  const [deleteFacility] = useDeleteSubFacilityMutation();

  const [updateFacility] = useUpdateSubFacilityMutation();

  const handleSubmitDelete = async (id: string) => {
    await deleteFacility({id: id});
    setConfirmModalOpen(false);
    refreshSubFacilities();
    setActiveFacility("");
  };

  // useEffect(() => {
  //   if (parentFacility && !activeFacility) {
  //     if (parentFacility.subFacilities.length > 0) {
  //       setActiveFacility(parentFacility.subFacilities[0].id);
  //     }
  //   } // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [parentFacility, activeFacility]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const redirectOnClick = (path: string) => {
    navigate(`/facilities/sub/manage/${activeFacility}/${path}`);
  };

  const [unassignUser] = useUnassignFromSubFacilityMutation();

  const handleSubmitUpdate = (values: AddSubFacilityType) => {
    updateFacility({
      id: activeFacility,
      name: values.name,
    })
      .then(() => refreshFacility())
      .then(() => refreshSubFacilities());
  };

  if (!parentFacility || !id) {
    return null;
  }

  const manageUsers = (userId: string) => {
    unassignUser({subFacilityId: activeFacility, userId: userId}).then(() =>
      refreshFacility()
    );
  };

  return (
    <Container
      maxWidth={false}
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100%",
        gap: 6,
        py: 2,
      }}
    >
      <Box sx={{display: "grid", gridColumnStart: 1, gridColumnEnd: 3}}>
        <BaseTable
          headerButtonTitle="Powrót"
          headerClick={() => navigate(`/facilities/`)}
          headerTitle={parentFacility?.name}
        />
      </Box>

      <Box
        sx={{
          display: "grid",
          gap: 6,
          gridAutoFlow: "column",
          gridAutoColumns: "1fr 1fr",
          height: "100%",
        }}
      >
        <BaseTable
          headerButtonTitle="dodaj podobiekt"
          headerClick={() => navigate(`/facilities/sub/add/${id}`)}
          headerTitle="Podobiekty"
        >
          {parentFacility?.subFacilities?.map((facility) => (
            <FacilityRow
              onClickDelete={handleSubmitDelete}
              activeId={activeFacility}
              onClick={setActiveFacility}
              key={facility.id}
              name={facility.name}
              id={facility.id}
            />
          ))}
        </BaseTable>
        {activeFacility && (
          <BaseTable headerTitle="dane obiektu">
            <Box py={2} px={3} gap={3} display="flex" flexDirection="column">
              {facility && (
                <SubFacilityForm
                  onSubmit={handleSubmitUpdate}
                  defaultValues={{facilityId: facility.id, name: facility.name}}
                />
              )}
              <Box justifyContent="space-between" display="flex">
                <PrimaryButton
                  onClick={() => setConfirmModalOpen(true)}
                  name="Usuń"
                  px={1}
                  py={1}
                  fontSize={18}
                />
                <PrimaryButton
                  onClick={handleClick}
                  name="Przydziel..."
                  px={1}
                  py={1}
                  fontSize={18}
                />
                <Menu
                  onClose={() => setAnchorEl(null)}
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                >
                  <MenuItem onClick={() => redirectOnClick("leaders")}>
                    <Typography>Kierownika</Typography>
                  </MenuItem>
                  <MenuItem onClick={() => redirectOnClick("employees")}>
                    <Typography>Pracownika</Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={() => redirectOnClick("seniorSecurityWorkers")}
                  >
                    <Typography>Staszego ochroniarza</Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={() => redirectOnClick("customerRepresentatives")}
                  >
                    <Typography>Reprezentanta firmy</Typography>
                  </MenuItem>
                </Menu>
              </Box>
              {facility && facility.assignedUsers && (
                <Box>
                  <CustomAccordion title="Przypisani kierownicy">
                    {facility.assignedUsers.leaders.map((employee) => (
                      <FacilityUserRow
                        key={employee.id}
                        id={employee.id}
                        name={employee.username}
                        deleteMode
                        onClick={() => manageUsers(employee.id)}
                      />
                    ))}
                  </CustomAccordion>
                  <CustomAccordion title="Przypisani pracownicy">
                    {facility.assignedUsers.employees.map((employee) => (
                      <FacilityUserRow
                        key={employee.id}
                        id={employee.id}
                        name={employee.username}
                        deleteMode
                        onClick={() => manageUsers(employee.id)}
                      />
                    ))}
                  </CustomAccordion>
                  <CustomAccordion title="Przypisani starsi ochroniarze">
                    {facility.assignedUsers.seniorSecurityWorkers.map(
                      (employee) => (
                        <FacilityUserRow
                          key={employee.id}
                          id={employee.id}
                          name={employee.username}
                          deleteMode
                          onClick={() => manageUsers(employee.id)}
                        />
                      )
                    )}
                  </CustomAccordion>
                  <CustomAccordion title="Przypisani reprezentanci">
                    {facility.assignedUsers.customerRepresentatives.map(
                      (employee) => (
                        <FacilityUserRow
                          key={employee.id}
                          id={employee.id}
                          name={employee.username}
                          deleteMode
                          onClick={() => manageUsers(employee.id)}
                        />
                      )
                    )}
                  </CustomAccordion>
                </Box>
              )}
            </Box>
          </BaseTable>
        )}
      </Box>
      <CustomConfirmationModal
        confirmAction={() => handleSubmitDelete(activeFacility)}
        handleClose={() => setConfirmModalOpen(false)}
        isOpen={confirmModalOpen}
        title="czy chcesz usunąć dany obiekt?"
      />
    </Container>
  );
};
