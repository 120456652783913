import {Box, Container} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import {BaseTable} from "src/components/BaseTable";
import {FacilityUsers} from "src/redux/facilities/facilities.types";
import {
  useAssignToFacilityMutation,
  useGetFacilityDetailsQuery,
  useGetFacilityUnassignedEmployeesQuery,
  useUnassignFromFacilityMutation,
} from "src/redux/facilities/facilitiesApi";
import {FacilityUserRow} from "../../components/FacilityUserRow";
import {useState} from "react";

type UsersManagementType = keyof FacilityUsers;

const getName = (userType: UsersManagementType) => {
  switch (userType) {
    case "customerRepresentatives":
      return {
        all: "Wszyscy reprezentanci klienta",
        assigned: "przypisani reprezentanci do obiektu",
        role: "customer_representative",
      };
    case "employees":
      return {
        all: "Wszyscy pracownicy",
        assigned: "Przypisani pracownicy do obiektu",
        role: "employee",
      };
    case "leaders":
      return {
        all: "Wszyscy kierownicy",
        assigned: "przypisani kierownicy do obiektu",
        role: "leader",
      };
    case "seniorSecurityWorkers":
      return {
        all: "Wszyscy starsi ochroniarze",
        assigned: "przypisani starsi ochroniarze do obiektu",
        role: "senior_security_worker",
      };
  }
};

export const FacilityUsersPage = () => {
  const {id, type} = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const userType = type as UsersManagementType;

  const {data: facility, refetch: refreshFacility} = useGetFacilityDetailsQuery(
    {id: id ? id : ""},
    {refetchOnMountOrArgChange: true}
  );

  const {data: unassignedUsers, refetch: refreshUnassigned} =
    useGetFacilityUnassignedEmployeesQuery(
      {
        id: id ? id : "",
        role: userType ? getName(userType).role : "",
      },
      {refetchOnMountOrArgChange: true}
    );

  const [unassignUser] = useUnassignFromFacilityMutation();

  const [assignUser] = useAssignToFacilityMutation();

  if (!id || !type || !facility || !unassignedUsers) {
    return null;
  }

  const manageUsers = async (assign: boolean, userId: string) => {
    setIsLoading(true);
    if (assign) {
      await assignUser({facilityId: id, userId: userId});
    } else {
      await unassignUser({facilityId: id, userId: userId});
    }
    await refreshFacility();
    await refreshUnassigned();
    setIsLoading(false);
  };

  const {name, assignedUsers} = facility;

  return (
    <Container
      maxWidth={false}
      sx={{
        minHeight: "100%",
        py: 2,
        gap: 2,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <BaseTable
        headerTitle={name}
        headerButtonTitle="Powrót"
        headerClick={() => navigate("/facilities")}
      />
      <Box
        sx={{
          display: "grid",
          gridAutoFlow: "column",
          gridAutoColumns: "1fr 1fr",
          height: "100%",
          gap: 4,
        }}
      >
        <BaseTable headerTitle={getName(userType).all}>
          <Box display="flex" flexDirection="column">
            {unassignedUsers.map((user) => (
              <FacilityUserRow
                key={user.id}
                name={user.username}
                description={user.internalId}
                id={user.id}
                disabled={isLoading}
                onClick={() => manageUsers(true, user.id)}
              />
            ))}
          </Box>
        </BaseTable>
        <BaseTable headerTitle={getName(userType).assigned}>
          <Box gap={3} display="flex" flexDirection="column">
            {assignedUsers[userType].map((user) => (
              <FacilityUserRow
                key={user.id}
                name={user.username}
                disabled={isLoading}
                description={user.internalId}
                id={user.id}
                deleteMode
                onClick={() => manageUsers(false, user.id)}
              />
            ))}
          </Box>
        </BaseTable>
      </Box>
    </Container>
  );
};
