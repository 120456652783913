import "./App.css";
import {CssBaseline, ThemeProvider} from "@mui/material";
import {ToastContainer} from "react-toastify";
import {theme} from "./theme";
import {AppRoutes} from "./routes/AppRoutes";
import "react-toastify/dist/ReactToastify.css";
import {LocalizationProvider} from "@mui/x-date-pickers";

import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";

function App() {
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <ToastContainer />
          <AppRoutes />
        </ThemeProvider>
      </LocalizationProvider>
    </>
  );
}

export default App;
