import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {RootState} from "../store";
import {
  IMessageListItem,
  MessageCreateFormType,
  MessageDetailsType,
  MessageUpdateFormType,
} from "./messages.types";
import {
  PaginatorParams,
  PaginatorParamsFilterType,
  PaginatorResponse,
} from "../api/types";
import querystring from "query-string";

const BASE_URL = process.env.REACT_APP_API_URL as string;

export const messagesApi = createApi({
  reducerPath: "messagesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL + "/api/time-messages",
    prepareHeaders: (headers, {getState}) => {
      const token = (getState() as RootState).userState.accessToken;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getMessages: builder.query<
      PaginatorResponse<IMessageListItem[]>,
      PaginatorParams<PaginatorParamsFilterType>
    >({
      query(queryParams) {
        return {
          url: `?${querystring.stringify(queryParams)}`,
          method: "GET",
          credentials: "include",
        };
      },
    }),
    getMessageById: builder.query<MessageDetailsType, {id: string}>({
      query(data) {
        return {
          url: `/${data.id}`,
          method: "GET",
          credentials: "include",
        };
      },
    }),
    updateMessage: builder.mutation<{}, MessageUpdateFormType>({
      query(data) {
        return {
          url: `/${data.id}`,
          method: "PUT",
          body: data,
          credentials: "include",
        };
      },
    }),
    createMessage: builder.mutation<{}, MessageCreateFormType>({
      query(data) {
        return {
          url: `/`,
          method: "POST",
          body: data,
          credentials: "include",
        };
      },
    }),

    changeActivityMessage: builder.mutation<{}, {id: string}>({
      query(data) {
        return {
          url: `/change-activity-status/${data.id}`,
          method: "PUT",
          credentials: "include",
        };
      },
    }),

    deleteMessage: builder.mutation<{}, {id: string}>({
      query(data) {
        return {
          url: `/${data.id}`,
          method: "DELETE",
          credentials: "include",
        };
      },
    }),
  }),
});

export const {
  useGetMessagesQuery,
  useCreateMessageMutation,
  useChangeActivityMessageMutation,
  useDeleteMessageMutation,
  useGetMessageByIdQuery,
  useUpdateMessageMutation,
} = messagesApi;
