import {Container} from "@mui/material";
import {SideMenu} from "./SideMenu";
import {Navigate, useMatch} from "react-router-dom";
import {useAppSelector} from "src/redux/store";
import {useEffect, useMemo, useRef, useState} from "react";
import Header from "./Header";
import {UserRole} from "src/redux/api/types";
import {routes} from "src/routes/routes";
import {jwtDecode} from "jwt-decode";
import {useDispatch} from "react-redux";
import {logout} from "src/redux/user/userSlice";
import {toast} from "react-toastify";

type LayoutProps = {
  children: React.ReactNode;
  allowedRoles: UserRole[];
};

export const Layout = ({children, allowedRoles}: LayoutProps) => {
  const headerRef = useRef<HTMLDivElement>(null);

  const [headerHeight, setHeaderHeight] = useState(0);

  const userState = useAppSelector((state) => state.userState);

  const {user, accessToken} = userState;

  const dispatch = useDispatch();

  const expiredDate = useMemo(
    () => (accessToken ? jwtDecode(accessToken).exp : null),
    [accessToken]
  );

  console.log("user", user);

  useEffect(() => {
    if (!expiredDate) return;

    const intervalId = setInterval(() => {
      const currentDateTime = new Date();
      const expirationDateTime = new Date(expiredDate * 1000);

      const timeDiff = expirationDateTime.getTime() - currentDateTime.getTime();
      const secondsUntilExpiration = Math.floor(timeDiff / 1000);

      if (secondsUntilExpiration <= 0) {
        dispatch(logout());
        clearInterval(intervalId);
      } else if (secondsUntilExpiration <= 60) {
        toast.warning("Zostaniesz wylogowany za mniej niż minutę", {
          autoClose: 10000,
        });
      }
    }, 15000);

    return () => clearInterval(intervalId); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expiredDate]);

  useEffect(() => {
    if (headerRef && headerRef.current) {
      setHeaderHeight(headerRef.current.offsetHeight);
    }
  }, []);

  const isSystemMessage = useMatch(routes.systemMessage.main);

  if (!user) {
    return <Navigate to={"/login"} />;
  }

  if (!allowedRoles.includes(user.role)) {
    console.log("aaaaaaa");
    if (user.role === "customer_representative" && !isSystemMessage) {
      return <Navigate to={routes.systemMessage.main} />;
    } else if (user.role !== "customer_representative") {
      return <Navigate to={"/"} />;
    }
  }

  return (
    <Container
      disableGutters
      maxWidth={false}
      sx={{
        display: "flex",
        height: "100%",
        flex: 1,
        flexDirection: "column",
        mx: 0,
      }}
    >
      <Header ref={headerRef} />
      <Container
        maxWidth={false}
        disableGutters
        sx={{
          display: "grid",
          gridAutoFlow: "column",
          height: `calc(100vh - ${headerHeight}px)`,
          gridAutoColumns: "1fr 4fr",
          mx: 0,
        }}
      >
        <SideMenu />
        <Container
          maxWidth={false}
          disableGutters
          sx={{
            padding: 2,
            overflowY: "auto",
          }}
        >
          {children}
        </Container>
      </Container>
    </Container>
  );
};
