import {zodResolver} from "@hookform/resolvers/zod";
import {Box, CircularProgress} from "@mui/material";
import {useEffect} from "react";
import {FormProvider, SubmitHandler, useForm} from "react-hook-form";
import {FormInput} from "src/components/FormInput";
import {PrimaryButton} from "src/components/PrimaryButton";
import {Section} from "src/components/Section";
import {IUser} from "src/redux/api/types";
import {useUpdateMeMutation} from "src/redux/api/userApi";
import {TypeOf, object, string} from "zod";

const userMailSchema = object({
  email: string({required_error: "Adres email jest wymagany"})
    .min(4, "Adres email jest wymagany")
    .max(40)
    .email("Niepoprawny adres email"),
  emailConfirm: string(),
  password: string({required_error: "Hasło jest wymagane"})
    .min(4, "Hasło jest wymagane")
    .max(32, "Hasło jest zbyt długie"),
}).refine((data) => data.email === data.emailConfirm, {
  message: "Emaile do siebie nie pasują",
  path: ["emailConfirm"],
});

type MailFormType = TypeOf<typeof userMailSchema>;

type MailFormProps = {
  user: IUser;
};

export const MailForm = ({user}: MailFormProps) => {
  const methods = useForm<MailFormType>({
    resolver: zodResolver(userMailSchema),
    defaultValues: {
      email: user?.email,
    },
  });

  const [updateUser, {isLoading}] = useUpdateMeMutation();

  const {
    reset,
    handleSubmit,
    formState: {isSubmitSuccessful, dirtyFields},
  } = methods;

  const onSubmit: SubmitHandler<MailFormType> = (values) => {
    updateUser({...values, username: user.username, phone: user.phone});
  };

  useEffect(() => {
    reset({email: user.email, emailConfirm: ""});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful, user]);

  const shouldShowSubmit = dirtyFields.email;

  return (
    <Section name="zmiana e-maila">
      <FormProvider {...methods}>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          display="flex"
          flexDirection="column"
          gap={2}
          sx={{width: "100%"}}
        >
          <FormInput fullWidth label="Adres e-mail" name="email" />
          <FormInput fullWidth label="Potwierdź e-mail" name="emailConfirm" />
          <FormInput
            fullWidth
            label="Podaj obecne hasło"
            name="password"
            type="password"
          />
          {shouldShowSubmit && (
            <>
              {isLoading ? (
                <CircularProgress />
              ) : (
                <PrimaryButton
                  fontSize={16}
                  px={1}
                  py={1}
                  type="submit"
                  name="Aktualizuj dane"
                />
              )}
            </>
          )}
        </Box>
      </FormProvider>
    </Section>
  );
};
