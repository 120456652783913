import {Scheduler} from "@aldabil/react-scheduler";
import {SchedulerRef} from "@aldabil/react-scheduler/types";
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
} from "@mui/icons-material";
import {Box, IconButton, Typography} from "@mui/material";
import {pl} from "date-fns/locale";
import {Dispatch, SetStateAction, useEffect, useMemo, useRef} from "react";
import {ScheduleType} from "src/redux/schedules/schedules.types";
import {CalendarCell} from "./CalendarCell";

type CalendarViewPropsType = {
  setYear: Dispatch<SetStateAction<number>>;
  setMonth: (value: number) => void;
  onClick: (value: string) => void;
  selectedDate: Date;
  month: number;
  year: number;
  schedules: ScheduleType[];
  showFacilityName?: boolean;
};

export const CalendarView = ({
  setYear,
  setMonth,
  month,
  year,
  selectedDate,
  onClick,
  schedules,
  showFacilityName,
}: CalendarViewPropsType) => {
  const ref = useRef<SchedulerRef>(null);

  useEffect(() => {
    if (ref && ref.current) {
      ref.current?.scheduler.handleState(selectedDate, "selectedDate");
    }
  }, [selectedDate]);

  const selectedMonth = useMemo(
    () => new Date(year, month).toLocaleString("pl-PL", {month: "long"}),
    [month, year]
  );

  // const eventsSchedules: ProcessedEvent[] = schedules.map((item) => ({
  //   end: new Date(item.endDate),
  //   event_id: item.id,
  //   start: new Date(item.startDate),
  //   title: item.facilityName,
  // }));

  return (
    <Box p={2}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        py={1}
        px={0.5}
      >
        <Box display="flex">
          <IconButton onClick={() => setYear((prev) => prev - 1)}>
            <KeyboardDoubleArrowLeft color="error" fontSize="large" />
          </IconButton>
          <IconButton onClick={() => setMonth(month - 1)}>
            <KeyboardArrowLeft color="error" fontSize="large" />
          </IconButton>
        </Box>
        <Typography
          display="flex"
          fontFamily="Bold"
          fontSize={20}
          textTransform="capitalize"
        >
          {selectedMonth} {year}
        </Typography>

        <Box display="flex">
          <IconButton onClick={() => setMonth(month + 1)}>
            <KeyboardArrowRight color="error" fontSize="large" />
          </IconButton>
          <IconButton onClick={() => setYear((prev) => prev + 1)}>
            <KeyboardDoubleArrowRight color="error" fontSize="large" />
          </IconButton>
        </Box>
      </Box>
      <Scheduler
        view="month"
        disableViewer
        disableViewNavigator
        selectedDate={selectedDate}
        ref={ref}
        alwaysShowAgendaDays={false}
        navigation={false}
        month={{
          weekDays: [0, 1, 2, 3, 4, 5, 6],
          weekStartOn: 1,
          navigation: false,
          startHour: 0,
          endHour: 23,
          disableGoToDay: true,
          headRenderer: () => <></>,
          cellRenderer: (props) => (
            <CalendarCell
              key={props.end.toUTCString()}
              {...props}
              facilityMode={showFacilityName}
              schedules={schedules}
              month={month}
              year={year}
              handleClick={onClick}
            />
          ),
        }}
        locale={pl}
      />
    </Box>
  );
};
