export const routes = {
  unauthorized: {
    login: "login",
    forgotPassword: "forgot-password",
    resetPassword: "reset-password",
  },
  home: {
    main: "/",
  },
  messages: {
    main: "/messages",
    add: "/messages/add",
  },
  systemMessage: {
    main: "/system-message",
  },
  myAccount: {
    main: "/my-account",
  },
  incidents: {
    main: "/incidents",
    add: "/incidents/add",
  },
  duties: {
    main: "/duties",
    add: "/duties/add",
  },
  worklog: {
    main: "/worklog",
  },
  schedules: {
    main: "/schedules",
    facility: "/schedules/facility",
    me: "/schedules/me",
  },
  facilities: {
    main: "/facilities",
    add: "add",
    manage: (id: string | ":id" = ":id", type: string | ":type" = ":type") =>
      `/facilities/manage/${id}/${type}`,
    sub: {
      main: (id: string | ":id" = ":id") => `/facilities/sub/${id}`,
      add: (id: string | ":id" = ":id") => `/facilities/sub/add/${id}`,
      manage: (id: string | ":id" = ":id", type: string | ":type" = ":type") =>
        `/facilities/sub/manage/${id}/${type}`,
    },
  },
  users: {
    main: "/users",
    list: (type: string | ":type" = ":type") => `/users/${type}`,
    add: (type: string | ":type" = ":type") => `/users/add/${type}`,
  },
};
