import {zodResolver} from "@hookform/resolvers/zod";
import {Box, CircularProgress} from "@mui/material";
import {useEffect} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {FormInput} from "src/components/FormInput";
import {PrimaryButton} from "src/components/PrimaryButton";
import {AddSubFacilityType} from "src/redux/facilities/facilities.types";
import {object, string} from "zod";

type SubFacilityFormProps = {
  defaultValues?: AddSubFacilityType;
  onSubmit: (facility: AddSubFacilityType) => void;
};

const facilityFormSchema = object({
  name: string({required_error: "Nazwa jest wymagana"}),
});

export const SubFacilityForm = ({
  defaultValues,
  onSubmit,
}: SubFacilityFormProps) => {
  const methods = useForm<AddSubFacilityType>({
    resolver: zodResolver(facilityFormSchema),
    defaultValues: defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: {isDirty, isValid, isLoading},
  } = methods;

  useEffect(() => {
    if (defaultValues) {
      reset(defaultValues);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        display="flex"
        flexDirection="column"
        gap={2}
        sx={{width: "100%"}}
      >
        <FormInput fullWidth label="Nazwa" name="name" type="text" />
        {isDirty && (
          <>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <PrimaryButton
                fontSize={16}
                px={1}
                py={1}
                disabled={!isValid}
                type="submit"
                name={defaultValues ? "Zapisz zmiany" : "Zapisz obiekt"}
              />
            )}
          </>
        )}
      </Box>
    </FormProvider>
  );
};
