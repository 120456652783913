import { Business, Check, Close, Delete } from "@mui/icons-material";
import { Box, IconButton, Tooltip, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { CustomConfirmationModal } from "src/components/CustomConfirmationModal";

type FacilityRowProps = {
  name: string;
  id: string;
  location?: string;
  description?: string;
  additionalDescription?: string;
  deleteInfo?: string;
  checked?: boolean;
  activeId: string;
  hideDelete?: boolean;
  onClick: (id: string) => void;
  onClickDelete?: (id: string) => void;
  onClickSubFacilities?: (id: string) => void;
  onCheckClick?: (id: string) => void;
};

export const FacilityRow = ({
  name,
  activeId,
  location,
  description,
  additionalDescription,
  id,
  onClick,
  onClickDelete,
  onClickSubFacilities,
  deleteInfo,
  checked,
  hideDelete,
  onCheckClick,
}: FacilityRowProps) => {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const theme = useTheme();

  return (
    <Box
      onClick={() => {
        onClick(id);
      }}
      sx={{
        cursor: "pointer",
        display: "grid",
        alignItems: "center",
        gridAutoFlow: "column",
        gridAutoColumns: `1fr ${
          !!onCheckClick && !!onClickDelete && !!onClickSubFacilities
            ? "1fr"
            : ""
        } ${location ? "1fr" : ""}  ${description ? "1fr" : ""}`,
        paddingY: 2,
        ":hover": {
          backgroundColor: theme.palette.silver,
        },
        background:
          activeId === id ? theme.palette.silver : theme.palette.white,
      }}
    >
      {name && (
        <Typography fontSize={18} textAlign="left" maxWidth={"80%"}>
          {name}
        </Typography>
      )}

      {location && (
        <Typography fontSize={18} textAlign="center">
          {location}
        </Typography>
      )}

      {description && (
        <Typography fontSize={18} textAlign="center">
          {description}
          <Typography fontSize={14} textAlign="center">
            {additionalDescription ? additionalDescription : ""}
          </Typography>
        </Typography>
      )}
      {(!!onCheckClick ||
        !!onClickDelete ||
        !!onClickSubFacilities ||
        hideDelete) && (
        <Box
          display="flex"
          gap={2}
          justifyItems="center"
          justifyContent="center"
        >
          {!!onClickDelete && (
            <Tooltip title="Usuń">
              <IconButton
                onClick={(e) => {
                  setConfirmModalOpen(true);
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <Delete fontSize="medium" />
              </IconButton>
            </Tooltip>
          )}
          {!!onClickSubFacilities && (
            <Tooltip title="Podobiekty">
              <IconButton
                onClick={(e) => {
                  onClickSubFacilities(id);
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <Business fontSize="medium" />
              </IconButton>
            </Tooltip>
          )}
          {!!onCheckClick && (
            <Tooltip title={checked ? "Aktywuj" : "Dezaktywuj"}>
              <IconButton
                onClick={(e) => {
                  onCheckClick(id);
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                {checked ? (
                  <Close fontSize="medium" />
                ) : (
                  <Check color="error" fontSize="medium" />
                )}
              </IconButton>
            </Tooltip>
          )}
        </Box>
      )}
      {!!onClickDelete && (
        <CustomConfirmationModal
          confirmAction={() => onClickDelete(id)}
          handleClose={() => setConfirmModalOpen(false)}
          isOpen={confirmModalOpen}
          title={deleteInfo ? deleteInfo : "czy chcesz usunąć dany obiekt?"}
        />
      )}
    </Box>
  );
};
