import {zodResolver} from "@hookform/resolvers/zod";
import {Box, CircularProgress} from "@mui/material";
import {useEffect} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {FormInput} from "src/components/FormInput";
import {FormSelect} from "src/components/FormSelect";
import {PrimaryButton} from "src/components/PrimaryButton";
import {UserCreateType, UserRole} from "src/redux/api/types";
import {object, string} from "zod";

type UsersFormProps = {
  defaultValues?: UserCreateType;
  disableRoles?: boolean;
  onSubmit: (user: UserCreateType) => void;
};

const usersFormSchema = object({
  username: string().min(4, "Imię i nazwisko jest zbyt krótkie").max(40),
  phone: string({required_error: "Numer telefonu jest wymagany"})
    .regex(
      /^\+\d{1,3}\s?\d{1,14}$/,
      "Numer telefonu powinien zawierać kod kraju zaczynający się znakiem +"
    )
    .max(14, "Niepoprawny numer telefonu"),
  internalId: string(),
  role: string(),
  email: string()
    .min(4, "Adres email jest wymagany")
    .max(40)
    .email("Niepoprawny adres email"),
});

export const UsersForm = ({
  defaultValues,
  onSubmit,
  disableRoles = false,
}: UsersFormProps) => {
  const methods = useForm<UserCreateType>({
    resolver: zodResolver(usersFormSchema),
    defaultValues: defaultValues,
  });

  const rolesData: {
    value: UserRole;
    name: string;
    disabled?: boolean;
  }[] = [
    {
      name: "Kierownik",
      value: "leader",
    },
    {
      name: "Pracownik",
      value: "employee",
    },
    {
      name: "Starszy pracownik ochrony",
      value: "senior_security_worker",
    },
    {
      name: "Reprezentant klienta",
      value: "customer_representative",
      disabled: true,
    },
  ];

  const {
    handleSubmit,
    reset,
    formState: {isDirty, isValid, isLoading},
  } = methods;

  useEffect(() => {
    if (defaultValues) {
      reset(defaultValues);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        display="flex"
        flexDirection="column"
        gap={2}
        sx={{width: "100%"}}
      >
        <FormInput
          fullWidth
          label="Imię i nazwisko"
          name="username"
          type="text"
        />

        <FormInput fullWidth label="E-mail" name="email" type="text" />
        <FormInput fullWidth label="Numer telefonu" name="phone" type="text" />
        <FormInput
          fullWidth
          label="Kod wewnętrzny"
          name="internalId"
          type="text"
        />
        <FormSelect
          disabled={disableRoles}
          data={rolesData}
          label="Rola"
          name="role"
        />
        {isDirty && (
          <>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <PrimaryButton
                fontSize={16}
                px={1}
                py={1}
                disabled={!isValid}
                type="submit"
                name={defaultValues ? "Zapisz zmiany" : "Zapisz obiekt"}
              />
            )}
          </>
        )}
      </Box>
    </FormProvider>
  );
};
