import {Container, Typography, useTheme} from "@mui/material";

type SectionProps = {
  name: string;
  children: React.ReactNode;
};

export const Section = ({name, children}: SectionProps) => {
  const theme = useTheme();
  return (
    <Container maxWidth={false}>
      <Container
        maxWidth={false}
        sx={{bgcolor: theme.palette.raisinBlack, p: 2}}
      >
        <Typography
          textTransform={"uppercase"}
          fontSize={26}
          color={theme.palette.white}
          fontFamily={"SemiBold"}
        >
          {name}
        </Typography>
      </Container>
      <Container maxWidth={false} sx={{bgcolor: theme.palette.lightGray, p: 3}}>
        {children}
      </Container>
    </Container>
  );
};
