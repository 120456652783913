import {Box, Container} from "@mui/material";
import {useEffect, useMemo, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {BaseTable} from "src/components/BaseTable";
import {FacilityRow} from "src/components/FacilityRow";
import {
  useGetFacilitiesQuery,
  useGetFacilityDetailsQuery,
} from "src/redux/facilities/facilitiesApi";
import {
  useCreateScheduleMutation,
  useDeleteScheduleMutation,
  useLazyGetSchedulesQuery,
  useReplaceScheduleMutation,
} from "src/redux/schedules/schedules.Api";
import {routes} from "src/routes/routes";
import {DayModal} from "./components/DayModal";
import {CalendarView} from "./components/CalendarView";
import {AddScheduleFormType} from "src/redux/schedules/schedules.types";
import {toast} from "react-toastify";
import {FacilityScheduleForm} from "./components/FacilityScheduleForm";
import {useAppSelector} from "src/redux/store";
import {ErrorResponse} from "src/redux/api/types";

export const FacilitySchedulesPage = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  let activeFacility = searchParams.get("id") ?? "";
  const navigate = useNavigate();
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth());
  const [dayModalOpen, setDayModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalDate, setModalDate] = useState<string | undefined>(
    new Date().toISOString()
  );

  const user = useAppSelector((appState) => appState.userState.user);

  const handleSetActiveFacility = (id: string) => {
    setSearchParams({id: id});
  };

  const [fetchSchedules, {data: schedules}] = useLazyGetSchedulesQuery();

  const {data: facilities} = useGetFacilitiesQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const {data: activeFacilityData} = useGetFacilityDetailsQuery({
    id: activeFacility,
  });

  const [addSchedule, {isSuccess: createSuccess}] = useCreateScheduleMutation();

  const [addReplacementSchedule, {isSuccess: replacementSuccess}] =
    useReplaceScheduleMutation();

  const [deleteSchedule, {isSuccess: deleteSuccess}] =
    useDeleteScheduleMutation();

  useEffect(() => {
    if (activeFacility) {
      fetchSchedules({
        Month: month + 1,
        Year: year,
        FacilityId: activeFacility,
      });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year, month, activeFacility]);

  const handleSetMonth = (newMonth: number) => {
    if (newMonth === 12) {
      setMonth(0);
      setYear((prev) => prev + 1);
    } else if (newMonth === -1) {
      setMonth(11);
      setYear((prev) => prev - 1);
    } else {
      setMonth(newMonth);
    }
  };
  const selectedDate = useMemo(
    () => new Date(year, month), // eslint-disable-next-line react-hooks/exhaustive-deps
    [month, year]
  );

  const handleCloseModal = () => {
    setDayModalOpen(false);
    setModalDate(undefined);
  };

  const handleDayClick = (date: string) => {
    setDayModalOpen(true);
    setModalDate(date);
  };

  const shouldShowForm = useMemo(
    () => (modalDate ? new Date(modalDate) > new Date() : false), // eslint-disable-next-line react-hooks/exhaustive-deps
    [modalDate]
  );

  const handleSubmit = async (
    values: AddScheduleFormType,
    isReplacement?: boolean
  ) => {
    if (!modalDate) return undefined;

    setIsLoading(true);

    const startDate = new Date(modalDate);
    const formStartDate = new Date(values.startDate);
    startDate.setHours(formStartDate.getHours());
    startDate.setMinutes(formStartDate.getMinutes());
    const endDate = new Date(modalDate);
    const formEndDate = new Date(values.endDate);
    endDate.setHours(formEndDate.getHours());
    endDate.setMinutes(formEndDate.getMinutes());
    if (startDate > endDate) {
      endDate.setDate(startDate.getDate() + 1);
    }

    if (isReplacement) {
      await addReplacementSchedule({
        facilityId: activeFacility,
        userId: values.userId,
        startDate: startDate,
        endDate: endDate,
        subFacilityId: values.subFacilityId,
      });
      setIsLoading(false);

      fetchSchedules({
        Month: month + 1,
        Year: year,
        FacilityId: activeFacility,
      });

      return undefined;
    }

    return addSchedule({
      facilityId: activeFacility,
      userId: values.userId,
      startDate: startDate,
      endDate: endDate,
      subFacilityId: values.subFacilityId,
    })
      .unwrap()
      .then(() => {
        fetchSchedules({
          Month: month + 1,
          Year: year,
          FacilityId: activeFacility,
        });
        return undefined;
      })
      .catch((err: ErrorResponse) => {
        console.log("err", err);
        if (err.data.code === "user_already_assigned_this_date") {
          return err.data.reason;
        } else {
          return undefined;
        }
      })
      .finally(() => setIsLoading(false));
  };

  const handleDelete = (id: string) => {
    deleteSchedule({id}).then(() =>
      fetchSchedules({Month: month + 1, Year: year, FacilityId: activeFacility})
    );
  };

  useEffect(() => {
    if (deleteSuccess) {
      toast.success("Usunięto pomyślnie");
    }
  }, [deleteSuccess]);

  useEffect(() => {
    if (createSuccess || replacementSuccess) {
      toast.success("Dodano służbę");
    }
  }, [createSuccess, replacementSuccess]);

  const daySchedules = useMemo(
    () =>
      modalDate
        ? schedules?.filter(
            (item) =>
              new Date(item.startDate).getDate() ===
                new Date(modalDate).getDate() &&
              new Date(item.startDate).getMonth() === month
          )
        : [], // eslint-disable-next-line react-hooks/exhaustive-deps
    [modalDate, schedules]
  );

  const assignedEmployees = useMemo(
    () =>
      activeFacilityData
        ? [
            ...(activeFacilityData.assignedUsers?.employees ?? []),
            ...(activeFacilityData.assignedUsers?.seniorSecurityWorkers ?? []),
          ]
        : [], // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeFacilityData]
  );

  return (
    <Container
      maxWidth={false}
      sx={{
        display: "grid",
        gap: 6,
        gridAutoFlow: "column",
        gridAutoColumns: "1fr 1fr 1fr",
        minHeight: "100%",
        py: 2,
      }}
    >
      <BaseTable
        headerTitle="Obiekty"
        headerButtonTitle={
          user?.role === "senior_security_worker"
            ? "Grafik"
            : "grafik pracowników"
        }
        headerClick={() =>
          user?.role === "senior_security_worker"
            ? navigate(routes.schedules.me)
            : navigate(routes.schedules.main)
        }
      >
        <Box>
          {facilities?.map((item) => (
            <FacilityRow
              key={item.id}
              activeId={activeFacility}
              id={item.id}
              name={item.name}
              description={item.location ?? "-"}
              onClick={handleSetActiveFacility}
            />
          ))}
        </Box>
      </BaseTable>

      {schedules && (
        <Box sx={{gridColumnStart: 2, gridColumnEnd: 4}}>
          <BaseTable
            headerTitle={
              facilities?.find((item) => item.id === activeFacility)?.name ?? ""
            }
          >
            <CalendarView
              showFacilityName
              schedules={schedules}
              month={month}
              year={year}
              selectedDate={selectedDate}
              setMonth={handleSetMonth}
              setYear={setYear}
              onClick={handleDayClick}
            />
          </BaseTable>
        </Box>
      )}
      <DayModal
        handleDelete={user?.role !== "employee" ? handleDelete : undefined}
        handleClose={handleCloseModal}
        isOpen={dayModalOpen}
        daySchedules={daySchedules}
        facilityMode
        title={
          `Grafik - ${
            facilities?.find((item) => item.id === activeFacility)?.name
          }` ?? ""
        }
      >
        {modalDate && shouldShowForm && (
          <FacilityScheduleForm
            isLoading={isLoading}
            facilityId={activeFacility}
            assignedUsers={assignedEmployees}
            onSubmit={handleSubmit}
            date={modalDate}
          />
        )}
      </DayModal>
    </Container>
  );
};
