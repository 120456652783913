import {
  Autocomplete,
  Container,
  FormControl,
  FormHelperText,
  InputProps,
  TextField,
  Typography,
} from "@mui/material";
import {FC, useEffect, useRef, useState} from "react";
import {Controller, useFormContext} from "react-hook-form";
import {useDebouncedState} from "src/hooks/useDebounce";
import {FacilityListItem} from "src/redux/facilities/facilities.types";
import {useGetSubFacilitiesByQueryQuery} from "src/redux/facilities/facilitiesApi";

type IFormInputProps = {
  name: string;
  label: string;
  facilityId: string;
  userId: string;
} & InputProps;

export const AutocompleteSubFacilityInput: FC<IFormInputProps> = ({
  name,
  label,
  defaultValue,
  facilityId,
  userId,
  ...otherProps
}) => {
  const [query, setQuery] = useState("");
  const debSearchText = useDebouncedState(query, 500);
  const [selectedValue, setSelectedValue] = useState<FacilityListItem | null>(
    null
  );

  const {
    control,
    setValue,
    formState: {errors},
  } = useFormContext();

  const ref = useRef(null);

  const {data} = useGetSubFacilitiesByQueryQuery(
    {id: facilityId, searchTerm: debSearchText, userId: userId},
    {refetchOnMountOrArgChange: true}
  );

  useEffect(() => {
    setQuery("");
    clearAutocomplete(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilityId]);

  const clearAutocomplete = () => {
    setValue("subFacilityId", null); // Ustawienie wartości na null w React Hook Form
    setSelectedValue(null); // Wyczyszczenie wybranej wartości w stanie komponentu
  };

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={null} // Ustawienie domyślnej wartości na null
      render={({field}) => {
        const defaultVal =
          data?.find((item) => item.id === field.value) || null;

        return (
          <FormControl fullWidth={otherProps.fullWidth}>
            <Container
              disableGutters
              maxWidth={false}
              sx={{display: "flex", gap: 2, alignItems: "center"}}
            >
              {label && (
                <Typography fontFamily="Regular" minWidth={180} fontSize={20}>
                  {label}
                </Typography>
              )}
              <Autocomplete
                ref={ref}
                fullWidth
                options={data ?? []}
                getOptionLabel={(option) => option.name}
                value={selectedValue || defaultVal || null} // Obsługuje zarówno null, undefined, jak i defaultVal
                onChange={(event, newValue) => {
                  setSelectedValue(newValue || null); // Aktualizuje stan komponentu
                  field.onChange(newValue?.id ?? null); // Aktualizuje wartość w formularzu
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                    }}
                    placeholder={query}
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                  />
                )}
              />
            </Container>
            <FormHelperText sx={{px: 0, mx: 0}} error={!!errors[name]}>
              <Typography textAlign={"left"} fontSize={20}>
                {errors[name] ? errors[name]?.message?.toString() : ""}
              </Typography>
            </FormHelperText>
          </FormControl>
        );
      }}
    />
  );
};
