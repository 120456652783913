import {
  Container,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  SelectProps,
  Typography,
  useTheme,
} from "@mui/material";
import {FC} from "react";
import {Controller, useFormContext} from "react-hook-form";

type SelectOption = {
  name: string;
  value: string | number;
  disabled?: boolean;
};

type IFormSelectProps = {
  name: string;
  label: string;
  data: SelectOption[];
} & SelectProps;

export const FormSelect: FC<IFormSelectProps> = ({
  name,
  label,
  data,
  ...otherProps
}) => {
  const {
    control,
    formState: {errors},
  } = useFormContext();

  const theme = useTheme();

  return (
    <Controller
      control={control}
      defaultValue=""
      name={name}
      render={({field}) => (
        <FormControl fullWidth={otherProps.fullWidth}>
          <Container
            disableGutters
            maxWidth={false}
            sx={{display: "flex", gap: 2, alignItems: "center"}}
          >
            {label && (
              <Typography fontFamily="Regular" minWidth={180} fontSize={20}>
                {label}
              </Typography>
            )}
            <Select
              sx={{
                fontSize: 18,
                borderWidth: 1,
                fontFamily: "Regular",
                borderColor: theme.palette.silver,
                borderStyle: "solid",
              }}
              fullWidth
              {...field}
              disableUnderline
              error={!!errors[name]}
              {...otherProps}
            >
              {data.map((item) => (
                <MenuItem disabled={item.disabled} value={item.value}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </Container>
          <FormHelperText sx={{px: 0, mx: 0}} error={!!errors[name]}>
            <Typography textAlign={"left"} fontSize={20}>
              {errors[name] ? errors[name]?.message?.toString() : ""}
            </Typography>
          </FormHelperText>
        </FormControl>
      )}
    />
  );
};
