import {zodResolver} from "@hookform/resolvers/zod";
import {Box, CircularProgress} from "@mui/material";
import {useEffect} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {AutocompleteFormInput} from "src/components/AutocompleteFormInput";
import {FormInput} from "src/components/FormInput";
import {FormRadio} from "src/components/FormRadio";
import {PrimaryButton} from "src/components/PrimaryButton";
import {IncidentCreateForm} from "src/redux/incidents/incidents.types";
import {boolean, object, string} from "zod";

type IncidentFormProps = {
  onSubmit: (values: IncidentCreateForm) => void;
  defaultValues?: IncidentCreateForm;
  isLoading?: boolean;
};

const incidentFormSchema = object({
  title: string({required_error: "Tytuł jest wymagany"}),
  message: string({required_error: "Opis jest wymagany"}),
  facilityId: string({required_error: "Obiekt jest wymagany"}),
  isActive: boolean().nullable(),
  eventType: string({required_error: "Typ jest wymagany"}),
});

export const IncidentForm = ({
  defaultValues,
  onSubmit,
  isLoading = false,
}: IncidentFormProps) => {
  const methods = useForm<IncidentCreateForm>({
    resolver: zodResolver(incidentFormSchema),
    defaultValues: defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: {isDirty, isValid},
  } = methods;

  useEffect(() => {
    if (defaultValues) {
      reset(defaultValues);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        display="flex"
        flexDirection="column"
        gap={2}
        sx={{width: "100%"}}
      >
        {!defaultValues && (
          <FormRadio
            options={[
              {
                name: "Nieaktywny",
                value: false,
              },
              {
                name: "Aktywny",
                value: true,
              },
            ]}
            fullWidth
            label="Aktualny status"
            name="isActive"
            type="radio"
          />
        )}

        <FormRadio
          options={[
            {
              name: "Informacje",
              value: "information",
            },
            {
              name: "Ważne",
              value: "alert",
            },
          ]}
          fullWidth
          label="Rodzaj zdarzenia"
          name="eventType"
          type="radio"
        />
        <AutocompleteFormInput
          fullWidth
          label="Obiekt"
          name="facilityId"
          type="text"
        />

        <FormInput fullWidth label="Tytuł" name="title" type="text" />
        <FormInput fullWidth label="Wiadomość" name="message" type="text" />
        {isDirty && (
          <>
            {isLoading ? (
              <CircularProgress sx={{margin: "auto"}} />
            ) : (
              <PrimaryButton
                fontSize={16}
                px={1}
                py={1}
                disabled={!isValid}
                type="submit"
                name={"Zapisz"}
              />
            )}
          </>
        )}
      </Box>
    </FormProvider>
  );
};
