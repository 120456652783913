import { Box, Container, Modal, useTheme } from "@mui/material";
import dayjs from "dayjs";
import { ReactNode } from "react";
import { BaseTable } from "src/components/BaseTable";
import { FacilityRow } from "src/components/FacilityRow";
import { ScheduleType } from "src/redux/schedules/schedules.types";

type DayModalProps = {
  isOpen: boolean;
  handleClose: () => void;
  handleDelete?: (id: string) => void;
  title: string;
  facilityMode?: boolean;
  children?: ReactNode;
  daySchedules?: ScheduleType[];
};

export const DayModal = ({
  isOpen,
  handleClose,
  handleDelete,
  title,
  children,
  daySchedules,
  facilityMode = false,
}: DayModalProps) => {
  const theme = useTheme();
  const today = new Date();
  today.setHours(0, 0, 0);
  return (
    <Modal
      sx={{ alignItems: "center", display: "flex", justifyContent: "center" }}
      open={isOpen}
      onClose={handleClose}
    >
      <Container
        maxWidth="lg"
        sx={{
          bgcolor: theme.palette.white,
          padding: 4,
          display: "flex",
          gap: 2,
          flexDirection: "column",
        }}
      >
        <BaseTable
          headerTitle={title}
          headerButtonTitle="Zamknij"
          headerClick={handleClose}
        />
        <Box
          sx={{
            display: "grid",
            gridAutoFlow: "column",
            gridAutoColumns: "1fr 1fr",
            gap: 3,
          }}
        >
          <BaseTable headerTitle="Lista dyżurów">
            {daySchedules?.map((schedule) => (
              <FacilityRow
                key={schedule.id}
                id={schedule.id}
                name={`${dayjs(schedule.startDate).format("HH:mm")}-
              ${dayjs(schedule.endDate).format("HH:mm")}`}
                activeId=""
                onClick={() => {}}
                location={
                  facilityMode ? schedule.username : schedule.facilityName
                }
                description={schedule.subFacilityName ?? " "}
                deleteInfo="Czy na pewno chcesz usunąć wpis z grafiku"
                hideDelete={today < new Date(schedule.startDate) ? false : true}
                onClickDelete={
                  today < new Date(schedule.startDate) && !!handleDelete
                    ? () => handleDelete(schedule.id)
                    : undefined
                }
              />
            ))}
          </BaseTable>
          {children}
        </Box>
      </Container>
    </Modal>
  );
};
