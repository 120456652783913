import {Box, InputLabel, TextField} from "@mui/material";
import {useEffect, useState} from "react";
import {useDebouncedState} from "src/hooks/useDebounce";
import {DutyTaskList} from "src/redux/duties/duties.types";
import {
  useAddDutyTaskMutation,
  useDeleteDutyTaskMutation,
  useUpdateDutyNameMutation,
  useUpdateDutyTaskMutation,
} from "src/redux/duties/dutiesApi";
import {DutyTaskItem} from "./DutyTaskItem";
import {toast} from "react-toastify";

type DutyTaskListProps = {
  duty: DutyTaskList;
  refreshDuty: () => void;
};

export const DutyTaskListView = ({duty, refreshDuty}: DutyTaskListProps) => {
  const [taskName, setTaskName] = useState("");
  const [activeTask, setActiveTask] = useState("");
  const [dutyName, setDutyName] = useState("");

  useEffect(() => {
    setDutyName(duty.dutyName); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duty]);

  const debouncedDutyName = useDebouncedState(dutyName, 500);

  const debouncedTaskName = useDebouncedState(taskName, 500);

  const [updateDutyName] = useUpdateDutyNameMutation();

  const [createNewTask, {isSuccess: createSuccess}] = useAddDutyTaskMutation();

  const [updateTask, {isSuccess: updateSuccess}] = useUpdateDutyTaskMutation();

  const [deleteTask, {isSuccess: deleteSuccess}] = useDeleteDutyTaskMutation();

  useEffect(() => {
    if (updateSuccess) {
      toast.success("Pomyślnie zaktualizowano");
    }
    if (deleteSuccess) {
      toast.success("Usunięto pomyślnie");
    }
    if (createSuccess) {
      toast.success("Utworzono pomyślnie");
    }
  }, [deleteSuccess, updateSuccess, createSuccess]);

  const handleUpdateTask = (taskId: string, description: string) => {
    updateTask({id: taskId, description: description}).then(() =>
      refreshDuty()
    );
  };

  const handleDeleteTask = (taskId: string) => {
    deleteTask({id: taskId}).then(() => refreshDuty());
  };

  useEffect(() => {
    if (debouncedTaskName && debouncedTaskName.length > 3) {
      createNewTask({description: debouncedTaskName, dutyId: duty.id})
        .then(() => refreshDuty())
        .then(() => setTaskName(""));
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedTaskName]);

  useEffect(() => {
    if (debouncedDutyName && debouncedDutyName.length > 3) {
      updateDutyName({id: duty.id, name: debouncedDutyName}).then(() =>
        refreshDuty()
      );
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedDutyName]);

  return (
    <Box>
      <Box display="flex" alignItems="center" gap={2}>
        <InputLabel sx={{minWidth: "20%"}}>Nazwa Listy</InputLabel>
        <TextField
          margin="dense"
          fullWidth
          defaultValue={duty.dutyName}
          value={dutyName}
          onChange={(e) => setDutyName(e.target.value)}
        />
      </Box>
      <Box sx={{p: 2}}>
        {duty.tasks &&
          duty.tasks.map((task) => (
            <DutyTaskItem
              key={task.id}
              activeItem={activeTask}
              description={task.description}
              taskId={task.id}
              onClick={setActiveTask}
              updateTask={handleUpdateTask}
              deleteTask={handleDeleteTask}
            />
          ))}
        <TextField
          onFocus={() => setActiveTask("")}
          margin="dense"
          placeholder="Dodaj zadanie"
          fullWidth
          value={taskName}
          onChange={(e) => setTaskName(e.target.value)}
        />
      </Box>
    </Box>
  );
};
