import {
  Container,
  MenuItem,
  Select,
  SelectProps,
  Typography,
  useTheme,
} from "@mui/material";
import {FC} from "react";

export type SelectOption = {
  name: string;
  value: string | number | undefined;
  disabled?: boolean;
};

type IFormSelectProps = {
  name: string;
  label?: string;
  data: SelectOption[];
} & SelectProps;

export const FilterSelect: FC<IFormSelectProps> = ({
  name,
  label,
  data,
  ...otherProps
}) => {
  const theme = useTheme();

  return (
    <Container
      disableGutters
      maxWidth={false}
      sx={{display: "flex", gap: 2, alignItems: "center", ...otherProps.sx}}
    >
      {label && (
        <Typography fontFamily="Regular" minWidth={180} fontSize={20}>
          {label}
        </Typography>
      )}
      <Select
        sx={{
          fontSize: 18,
          borderWidth: 1,
          fontFamily: "Regular",
          borderColor: theme.palette.silver,
          borderStyle: "solid",
        }}
        fullWidth
        disableUnderline
        {...otherProps}
      >
        {data.map((item) => (
          <MenuItem key={item.name} disabled={item.disabled} value={item.value}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </Container>
  );
};
