import {Box, Container} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import {BaseTable} from "src/components/BaseTable";
import {
  useAddSubFacilityMutation,
  useGetFacilityDetailsQuery,
} from "src/redux/facilities/facilitiesApi";
import {useEffect} from "react";
import {toast} from "react-toastify";
import {SubFacilityForm} from "./components/SubFacilityForm";
import {AddSubFacilityType} from "src/redux/facilities/facilities.types";

export const SubFacilityFormPage = () => {
  const {id} = useParams();

  const navigate = useNavigate();

  const [addFacility, {isSuccess}] = useAddSubFacilityMutation();

  const {data: facility} = useGetFacilityDetailsQuery(
    {id: id ? id : ""},
    {refetchOnMountOrArgChange: true}
  );

  const handleGoBack = () => {
    navigate(`/facilities/sub/${id}`);
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success("Pomyślnie dodano obiekt");
      handleGoBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  const handleSubmitForm = (values: AddSubFacilityType) => {
    if (!id) return;
    addFacility({
      facilityId: id,
      name: values.name,
    });
  };

  if (!facility) {
    return null;
  }

  return (
    <Container
      maxWidth={false}
      sx={{
        minHeight: "100%",
        py: 2,
        display: "flex",
        gap: 4,
        flexDirection: "column",
      }}
    >
      <BaseTable
        headerTitle={facility?.name}
        headerButtonTitle="Powrót"
        headerClick={() => handleGoBack()}
      />
      <BaseTable headerTitle="dodaj podobiekt">
        <Box py={2} px={3} gap={3} display="flex" flexDirection="column">
          <SubFacilityForm onSubmit={handleSubmitForm} />
        </Box>
      </BaseTable>
    </Container>
  );
};
