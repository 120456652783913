import {AddBox, DeleteForever} from "@mui/icons-material";
import {Box, IconButton, Typography, useTheme} from "@mui/material";

type FacilityUserRowProps = {
  id: string;
  onClick: (id: string) => void;
  name?: string;
  description?: string;
  deleteMode?: boolean;
  disabled?: boolean;
};

export const FacilityUserRow = ({
  id,
  onClick,
  name,
  description,
  disabled = false,
  deleteMode = false,
}: FacilityUserRowProps) => {
  const theme = useTheme();
  return (
    <Box
      px={4}
      py={3}
      display="grid"
      gridAutoColumns="1fr 1fr 1fr"
      gridAutoFlow="column"
      alignItems="center"
      sx={{
        ":hover": {
          bgcolor: theme.palette.silver,
        },

        borderBottomWidth: 1,
        borderBottomColor: theme.palette.silver,
        borderBottomStyle: "solid",
      }}
    >
      {name && (
        <Typography fontFamily="Regular" fontSize={18}>
          {name}
        </Typography>
      )}

      {description && (
        <Typography fontFamily="Regular" fontSize={18}>
          {description}
        </Typography>
      )}
      <IconButton disabled={disabled} onClick={() => onClick(id)}>
        {deleteMode ? (
          <DeleteForever fontFamily="Regular" />
        ) : (
          <AddBox fontFamily="Regular" />
        )}
      </IconButton>
    </Box>
  );
};
