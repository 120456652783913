import {Box, Container, Menu, MenuItem, Typography} from "@mui/material";
import {useNavigate, useSearchParams} from "react-router-dom";
import {BaseTable} from "src/components/BaseTable";
import {
  useDeleteFacilityMutation,
  useGetFacilitiesQuery,
  useGetFacilityDetailsQuery,
  useUnassignFromFacilityMutation,
  useUpdateFacilityMutation,
} from "src/redux/facilities/facilitiesApi";
import {FacilityRow} from "../../components/FacilityRow";
import {useEffect, useState} from "react";
import {FacilityForm} from "./components/FacilityForm";
import {PrimaryButton} from "src/components/PrimaryButton";
import {CustomAccordion} from "src/components/CustomAccordion";
import {CustomConfirmationModal} from "src/components/CustomConfirmationModal";
import {AddFacility} from "src/redux/facilities/facilities.types";
import {FacilityUserRow} from "../../components/FacilityUserRow";

export const FacilitiesPage = () => {
  const navigate = useNavigate();

  let [searchParams, setSearchParams] = useSearchParams();
  let activeFacility = searchParams.get("id") ?? "";
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const setActiveFacility = (id: string) => {
    setSearchParams({id: id});
  };

  const {data: facilities, refetch} = useGetFacilitiesQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const {data: facility, refetch: refreshFacility} = useGetFacilityDetailsQuery(
    {id: activeFacility},
    {refetchOnMountOrArgChange: true}
  );

  const [deleteFacility] = useDeleteFacilityMutation();

  const [unassignUser] = useUnassignFromFacilityMutation();

  const [updateFacility] = useUpdateFacilityMutation();

  const handleSubmitDelete = async (id: string) => {
    await deleteFacility({id: id});
    setConfirmModalOpen(false);
    setActiveFacility("");
    refetch();
  };

  useEffect(() => {
    if (facilities && !activeFacility) {
      setActiveFacility(facilities[0].id);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilities, facility, activeFacility]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const redirectOnClick = (path: string) => {
    navigate(`/facilities/manage/${activeFacility}/${path}`);
  };

  const redirectToSubFacilities = (id = activeFacility) => {
    navigate(`/facilities/sub/${id}`);
  };

  const handleSubmitUpdate = (values: AddFacility) => {
    updateFacility({...values, id: activeFacility})
      .then(() => refreshFacility())
      .then(() => refetch());
  };

  const manageUsers = (userId: string) => {
    unassignUser({facilityId: activeFacility, userId: userId}).then(() =>
      refreshFacility()
    );
  };

  return (
    <Container
      maxWidth={false}
      sx={{
        display: "grid",
        gap: 6,
        gridAutoFlow: "column",
        gridAutoColumns: "1fr 1fr",
        minHeight: "100%",
        py: 2,
      }}
    >
      <BaseTable
        headerButtonTitle="dodaj obiekt"
        headerClick={() => navigate("/facilities/add")}
        headerTitle="obiekty"
      >
        {facilities?.map((facility) => (
          <FacilityRow
            onClickDelete={handleSubmitDelete}
            onClickSubFacilities={() => redirectToSubFacilities(facility.id)}
            activeId={activeFacility}
            onClick={setActiveFacility}
            key={facility.id}
            name={facility.name}
            description={facility.city}
            location={facility.location}
            id={facility.id}
          />
        ))}
      </BaseTable>
      <BaseTable headerTitle="dane obiektu">
        <Box py={2} px={3} gap={3} display="flex" flexDirection="column">
          {facility && (
            <FacilityForm
              onSubmit={handleSubmitUpdate}
              defaultValues={facility}
            />
          )}
          <Box justifyContent="space-between" display="flex">
            <PrimaryButton
              onClick={() => setConfirmModalOpen(true)}
              name="Usuń"
              px={1}
              py={1}
              fontSize={18}
            />
            <PrimaryButton
              onClick={handleClick}
              name="Przydziel..."
              px={1}
              py={1}
              fontSize={18}
            />

            <Menu
              onClose={() => setAnchorEl(null)}
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
            >
              <MenuItem onClick={() => redirectOnClick("leaders")}>
                <Typography>Kierownika</Typography>
              </MenuItem>
              <MenuItem onClick={() => redirectOnClick("employees")}>
                <Typography>Pracownika</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => redirectOnClick("seniorSecurityWorkers")}
              >
                <Typography>Staszego ochroniarza</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => redirectOnClick("customerRepresentatives")}
              >
                <Typography>Reprezentanta firmy</Typography>
              </MenuItem>
            </Menu>
          </Box>
          <PrimaryButton
            name="Podobiekty"
            fontSize={18}
            onClick={() => redirectToSubFacilities()}
          />
          {facility && facility.assignedUsers && (
            <Box>
              <CustomAccordion title="Przypisani kierownicy">
                {facility.assignedUsers.leaders.map((employee) => (
                  <FacilityUserRow
                    key={employee.id}
                    id={employee.id}
                    name={employee.username}
                    deleteMode
                    onClick={() => manageUsers(employee.id)}
                  />
                ))}
              </CustomAccordion>
              <CustomAccordion title="Przypisani pracownicy">
                {facility.assignedUsers.employees.map((employee) => (
                  <FacilityUserRow
                    key={employee.id}
                    id={employee.id}
                    name={employee.username}
                    deleteMode
                    onClick={() => manageUsers(employee.id)}
                  />
                ))}
              </CustomAccordion>
              <CustomAccordion title="Przypisani starsi ochroniarze">
                {facility.assignedUsers.seniorSecurityWorkers.map(
                  (employee) => (
                    <FacilityUserRow
                      key={employee.id}
                      id={employee.id}
                      name={employee.username}
                      deleteMode
                      onClick={() => manageUsers(employee.id)}
                    />
                  )
                )}
              </CustomAccordion>
              <CustomAccordion title="Przypisani reprezentanci">
                {facility.assignedUsers.customerRepresentatives.map(
                  (employee) => (
                    <FacilityUserRow
                      key={employee.id}
                      id={employee.id}
                      name={employee.username}
                      deleteMode
                      onClick={() => manageUsers(employee.id)}
                    />
                  )
                )}
              </CustomAccordion>
            </Box>
          )}
        </Box>
      </BaseTable>
      <CustomConfirmationModal
        confirmAction={() => handleSubmitDelete(activeFacility)}
        handleClose={() => setConfirmModalOpen(false)}
        isOpen={confirmModalOpen}
        title="czy chcesz usunąć dany obiekt?"
      />
    </Container>
  );
};
