import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {RootState} from "../store";
import {
  AddDutyFormType,
  AddDutyTaskType,
  DutiesListItem,
  DutyListUpdateType,
  DutyTaskItem,
  DutyTaskList,
  WorkLogDetailsType,
  WorkLogListItem,
} from "./duties.types";
import {
  PaginatorParams,
  PaginatorResponse,
  PaginatorWorkLogFilterType,
} from "../api/types";
import queryString from "query-string";

const BASE_URL = process.env.REACT_APP_API_URL as string;

export const dutiesApi = createApi({
  reducerPath: "dutiesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL + "/api/duties",

    prepareHeaders: (headers, {getState}) => {
      const token = (getState() as RootState).userState.accessToken;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getDuties: builder.query<DutiesListItem[], {}>({
      query() {
        return {
          url: `/`,
          method: "GET",
          credentials: "include",
        };
      },
    }),
    addDutyList: builder.mutation<{}, AddDutyFormType>({
      query(data) {
        return {
          url: `/`,
          method: "POST",
          body: data,
          credentials: "include",
        };
      },
    }),
    updateDutyName: builder.mutation<{}, DutyListUpdateType>({
      query(data) {
        return {
          url: `/${data.id}`,
          method: "PUT",
          body: data,
          credentials: "include",
        };
      },
    }),
    updateDutyListStatus: builder.mutation<{}, {id: string}>({
      query(data) {
        return {
          url: `/change-activity-status/${data.id}`,
          method: "PUT",
          credentials: "include",
        };
      },
    }),
    chandeDutyTaskStatus: builder.mutation<
      {},
      {taskId: string; isCheck: boolean}
    >({
      query(data) {
        return {
          url: `/change-duty-task-status/${data.taskId}`,
          method: "PUT",
          body: {taskId: data.taskId, isCheck: data.isCheck},
          credentials: "include",
        };
      },
    }),
    deleteDutyList: builder.mutation<{}, {id: string}>({
      query(data) {
        return {
          url: `/${data.id}`,
          method: "DELETE",
          credentials: "include",
        };
      },
    }),
    getDutyTasks: builder.query<DutyTaskList, {id?: string}>({
      query(data) {
        return {
          url: `/${data.id}`,
          method: "GET",
          credentials: "include",
        };
      },
    }),
    addDutyTask: builder.mutation<{}, AddDutyTaskType>({
      query(data) {
        return {
          url: "/task",
          method: "POST",
          body: data,
          credentials: "include",
        };
      },
    }),
    updateDutyTask: builder.mutation<{}, DutyTaskItem>({
      query(data) {
        return {
          url: `/task/${data.id}`,
          method: "PUT",
          body: data,
          credentials: "include",
        };
      },
    }),
    deleteDutyTask: builder.mutation<{}, {id: string}>({
      query(data) {
        return {
          url: `/task/${data.id}`,
          method: "DELETE",
          credentials: "include",
        };
      },
    }),
    getWorklogs: builder.query<
      PaginatorResponse<WorkLogListItem[]>,
      PaginatorParams<PaginatorWorkLogFilterType>
    >({
      query(query) {
        return {
          url: `/schedule-duties?${queryString.stringify(query)}`,
          method: "GET",
          credentials: "include",
        };
      },
    }),
    getWorklogById: builder.query<WorkLogDetailsType, {id: string}>({
      query(data) {
        return {
          url: `/schedule-duties/${data.id}`,
          method: "GET",
          credentials: "include",
        };
      },
    }),
    closeScheduleDuty: builder.mutation<{}, {id: string; description: string}>({
      query(data) {
        return {
          url: `/close-schedule-duty/${data.id}`,
          method: "PUT",
          body: {
            id: data.id,
            description: data.description,
          },
          credentials: "include",
        };
      },
    }),
  }),
});

export const {
  useAddDutyListMutation,
  useAddDutyTaskMutation,
  useDeleteDutyListMutation,
  useDeleteDutyTaskMutation,
  useGetDutiesQuery,
  useGetDutyTasksQuery,
  useUpdateDutyListStatusMutation,
  useUpdateDutyNameMutation,
  useUpdateDutyTaskMutation,
  useLazyGetWorklogByIdQuery,
  useGetWorklogsQuery,
  useChandeDutyTaskStatusMutation,
  useCloseScheduleDutyMutation,
} = dutiesApi;
