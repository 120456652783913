import {Route, Routes} from "react-router-dom";
import {HomePage} from "src/pages/home.page";
import {LoginPage} from "src/pages/login.page";
import {Layout} from "src/components/Layout";
import {UnauthorizedRoute} from "./ProtectedRoute";
import {MessagesPage} from "src/pages/Messages/messages.page";
import {IncidentsPage} from "src/pages/Incidents/incidents.page";
import {ForgotPasswordPage} from "src/pages/forgotPassword.page";
import {ResetPasswordPage} from "src/pages/resetPassword.page";
import {MyAccountPage} from "src/pages/MyAccount/myAccount.page";
import {FacilitiesPage} from "src/pages/Facilities/facilities.page";
import {FacilityFormPage} from "src/pages/Facilities/facilityform.page";
import {FacilityUsersPage} from "src/pages/Facilities/facilityUsers.page";
import {SubFacilitiesPage} from "src/pages/Facilities/subFacility.page";
import {SubFacilityFormPage} from "src/pages/Facilities/subFacilityForm.page";
import {SubFacilityUsersPage} from "src/pages/Facilities/subFacilitiesUsersManage";
import {routes} from "./routes";
import {UsersPage} from "src/pages/Users/users.page";
import {AddUserFormPage} from "src/pages/Users/addUser.page";
import {AddMessageFormPage} from "src/pages/Messages/addMessages.page";
import {AddIncidentsPage} from "src/pages/Incidents/addIncidentsPage";
import {DutiesPage} from "src/pages/Duties/duties.page";
import {AddDutiesFormPage} from "src/pages/Duties/addDutiesForm.page";
import {SchedulesPage} from "src/pages/Schedules/schedules.page";
import {FacilitySchedulesPage} from "src/pages/Schedules/facilitySchedules.page";
import {WorklogPage} from "src/pages/Worklog/worklog.page";
import {MySchedulePage} from "src/pages/Schedules/mySchedule.page";

export const AppRoutes = () => {
  return (
    <Routes>
      <Route
        path={routes.home.main}
        element={
          <Layout
            allowedRoles={[
              "admin",
              "employee",
              "leader",
              "senior_security_worker",
            ]}
          >
            <HomePage />
          </Layout>
        }
      />
      <Route
        path={routes.systemMessage.main}
        element={
          <Layout allowedRoles={["employee", "senior_security_worker"]}>
            <AddMessageFormPage />
          </Layout>
        }
      />
      <Route path={routes.messages.main}>
        <Route
          path=""
          element={
            <Layout
              allowedRoles={[
                "admin",
                "employee",
                "leader",
                "senior_security_worker",
              ]}
            >
              <MessagesPage />
            </Layout>
          }
        />
        <Route
          path={routes.messages.add}
          element={
            <Layout
              allowedRoles={[
                "admin",
                "employee",
                "leader",
                "senior_security_worker",
              ]}
            >
              <AddMessageFormPage />
            </Layout>
          }
        />
      </Route>

      <Route
        path={routes.myAccount.main}
        element={
          <Layout
            allowedRoles={[
              "admin",
              "employee",
              "leader",
              "senior_security_worker",
              "customer_representative",
            ]}
          >
            <MyAccountPage />
          </Layout>
        }
      />
      <Route
        path={routes.worklog.main}
        element={
          <Layout allowedRoles={["admin", "leader"]}>
            <WorklogPage />
          </Layout>
        }
      />
      <Route path={routes.incidents.main}>
        <Route
          path=""
          element={
            <Layout
              allowedRoles={[
                "admin",
                "employee",
                "leader",
                "senior_security_worker",
              ]}
            >
              <IncidentsPage />
            </Layout>
          }
        />
        <Route
          path={routes.incidents.add}
          element={
            <Layout
              allowedRoles={[
                "admin",
                "employee",
                "leader",
                "senior_security_worker",
              ]}
            >
              <AddIncidentsPage />
            </Layout>
          }
        />
      </Route>

      <Route path={routes.duties.main}>
        <Route
          path=""
          element={
            <Layout allowedRoles={["admin", "leader"]}>
              <DutiesPage />
            </Layout>
          }
        />
        <Route
          path={routes.duties.add}
          element={
            <Layout allowedRoles={["admin", "leader"]}>
              <AddDutiesFormPage />
            </Layout>
          }
        />
      </Route>

      <Route path={routes.schedules.main}>
        <Route
          path=""
          element={
            <Layout allowedRoles={["admin", "leader"]}>
              <SchedulesPage />
            </Layout>
          }
        />
        <Route
          path={routes.schedules.facility}
          element={
            <Layout
              allowedRoles={["admin", "leader", "senior_security_worker"]}
            >
              <FacilitySchedulesPage />
            </Layout>
          }
        />
        <Route
          path={routes.schedules.me}
          element={
            <Layout
              allowedRoles={[
                "admin",
                "employee",
                "leader",
                "senior_security_worker",
              ]}
            >
              <MySchedulePage />
            </Layout>
          }
        />
      </Route>

      <Route path={routes.facilities.main}>
        <Route
          path=""
          index
          element={
            <Layout allowedRoles={["admin", "leader"]}>
              <FacilitiesPage />
            </Layout>
          }
        />
        <Route
          path={routes.facilities.add}
          element={
            <Layout allowedRoles={["admin", "leader"]}>
              <FacilityFormPage />
            </Layout>
          }
        />
        <Route
          path={routes.facilities.manage()}
          element={
            <Layout allowedRoles={["admin", "leader"]}>
              <FacilityUsersPage />
            </Layout>
          }
        />
        <Route
          path={routes.facilities.sub.main()}
          element={
            <Layout allowedRoles={["admin", "leader"]}>
              <SubFacilitiesPage />
            </Layout>
          }
        />
        <Route
          path={routes.facilities.sub.add()}
          element={
            <Layout allowedRoles={["admin", "leader"]}>
              <SubFacilityFormPage />
            </Layout>
          }
        />
        <Route
          path={routes.facilities.sub.manage()}
          element={
            <Layout allowedRoles={["admin", "leader"]}>
              <SubFacilityUsersPage />
            </Layout>
          }
        />
      </Route>

      <Route path={routes.users.main}>
        <Route
          path={routes.users.list()}
          element={
            <Layout allowedRoles={["admin", "leader"]}>
              <UsersPage />
            </Layout>
          }
        ></Route>
        <Route
          path={routes.users.add()}
          element={
            <Layout allowedRoles={["admin", "leader"]}>
              <AddUserFormPage />
            </Layout>
          }
        />
      </Route>

      <Route
        path={routes.unauthorized.login}
        element={
          <UnauthorizedRoute>
            <LoginPage />
          </UnauthorizedRoute>
        }
      />
      <Route
        path={routes.unauthorized.forgotPassword}
        element={
          <UnauthorizedRoute>
            <ForgotPasswordPage />
          </UnauthorizedRoute>
        }
      />
      <Route
        path={routes.unauthorized.resetPassword}
        element={
          <UnauthorizedRoute>
            <ResetPasswordPage />
          </UnauthorizedRoute>
        }
      />
    </Routes>
  );
};
