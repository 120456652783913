import {zodResolver} from "@hookform/resolvers/zod";
import {Box, CircularProgress} from "@mui/material";
import {useEffect} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {AutocompleteFormInput} from "src/components/AutocompleteFormInput";
import {FormInput} from "src/components/FormInput";
import {FormRadio} from "src/components/FormRadio";
import {PrimaryButton} from "src/components/PrimaryButton";
import {AddDutyFormType} from "src/redux/duties/duties.types";
import {boolean, object, string} from "zod";

type DutyFormProps = {
  onSubmit: (values: AddDutyFormType) => void;
};

const dutyFormSchema = object({
  name: string({required_error: "Nazwa jest wymagana"}),
  facilityId: string({required_error: "Obiekt jest wymagany"}),
  isActive: boolean().nullable(),
});

export const DutyForm = ({onSubmit}: DutyFormProps) => {
  const methods = useForm<AddDutyFormType>({
    resolver: zodResolver(dutyFormSchema),
  });

  const {
    handleSubmit,
    reset,
    formState: {isDirty, isValid, isLoading},
  } = methods;

  useEffect(() => {
    return () => {
      reset();
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        display="flex"
        flexDirection="column"
        gap={2}
        sx={{width: "100%"}}
      >
        <FormInput fullWidth label="Nazwa" name="name" type="text" />

        <FormRadio
          options={[
            {
              name: "Nieaktywny",
              value: false,
            },
            {
              name: "Aktywny",
              value: true,
            },
          ]}
          fullWidth
          label="Aktualny status"
          name="isActive"
          type="radio"
        />

        <AutocompleteFormInput
          fullWidth
          label="Obiekt"
          name="facilityId"
          type="text"
        />

        {isDirty && (
          <>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <PrimaryButton
                fontSize={16}
                px={1}
                py={1}
                disabled={!isValid}
                type="submit"
                name={"Zapisz"}
              />
            )}
          </>
        )}
      </Box>
    </FormProvider>
  );
};
