import {Box, useTheme} from "@mui/material";
import {FormProvider, SubmitHandler, useForm} from "react-hook-form";
import {FormInput} from "src/components/FormInput";
import {TypeOf, object, string} from "zod";

import {Lock, Person} from "@mui/icons-material";
import {useEffect} from "react";
import {useLoginUserMutation} from "src/redux/api/authApi";
import {UnauthorizedLayout} from "src/components/UnauthorizedLayout";
import {PrimaryButton} from "src/components/PrimaryButton";
import {useNavigate} from "react-router-dom";
import {zodResolver} from "@hookform/resolvers/zod";

const loginSchema = object({
  email: string({required_error: "Adres email jest wymagany"}).email(
    "Adres email jest niepoprawny"
  ),
  password: string({required_error: "Hasło jest wymagane"}).max(
    32,
    "Hasło jest zbyt długie"
  ),
});

export type LoginInput = TypeOf<typeof loginSchema>;

export const LoginPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const methods = useForm<LoginInput>({resolver: zodResolver(loginSchema)});
  const {
    reset,
    handleSubmit,
    formState: {isSubmitSuccessful},
  } = methods;

  const [loginUser, {isLoading, isSuccess}] = useLoginUserMutation();

  const onSubmit: SubmitHandler<LoginInput> = (values) => {
    loginUser(values);
  };

  const handleForgotPassword = () => {
    navigate("/forgot-password");
  };

  useEffect(() => {
    if (isSuccess) {
      navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful]);

  return (
    <UnauthorizedLayout
      showFooter
      headerText="Zaloguj się do systemu"
      footerText="Zapomniałeś hasła?"
      onFooterButtonClick={handleForgotPassword}
      footerButtonText="Przypomnij hasło"
    >
      <FormProvider {...methods}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            paddingX: 10,
            paddingY: 5,
            gap: 2,
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: theme.palette.white,
          }}
          component="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Box
            sx={{marginBottom: 4}}
            component="img"
            src={require("../static/brand.png")}
          />
          <FormInput
            fullWidth
            sx={{
              padding: 1,
              borderWidth: 1,
              fontSize: 20,
              borderColor: theme.palette.silver,
              borderStyle: "solid",
            }}
            startAdornment={
              <Person fontSize="large" htmlColor={theme.palette.warningRed} />
            }
            placeholder="E-mail..."
            name="email"
            label=""
            type="email"
          />
          <FormInput
            fullWidth
            startAdornment={
              <Lock fontSize="large" htmlColor={theme.palette.warningRed} />
            }
            placeholder="Hasło..."
            name="password"
            label=""
            type="password"
          />
          <PrimaryButton type="submit" name="zaloguj się" />
        </Box>
      </FormProvider>
    </UnauthorizedLayout>
  );
};
