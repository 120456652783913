import {Box, Container, Typography, useTheme} from "@mui/material";
import {useState} from "react";
import {BaseTable} from "src/components/BaseTable";
import {PaginatorParamsFilterType} from "src/redux/api/types";
import {FilterBar} from "../Messages/components/FilterBar";
import {
  useGetIncidentByIdQuery,
  useGetIncidentsQuery,
} from "src/redux/incidents/incidentsApi";
import {IncidentRow} from "src/components/IncidentRow";
import {CustomPagination} from "src/components/CustomPagination";
import {IncidentDetailsRow} from "./components/IncidentDetailsRow";
import dayjs from "dayjs";
import {useSearchParams} from "react-router-dom";

const pageSize = 9;

export const IncidentsPage = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  let activeMessage = searchParams.get("id") ?? "";

  const theme = useTheme();
  const [pageNumber, setPageNumber] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [filterType, setFilterType] = useState<PaginatorParamsFilterType>();

  const setActiveMessage = (id: string) => {
    setSearchParams({id: id});
  };

  const handleSetFilterType = (value?: PaginatorParamsFilterType) => {
    setFilterType(value);
    setSearchValue("");
  };

  const {data: incidents} = useGetIncidentsQuery(
    {
      Page: pageNumber,
      PageSize: pageSize,
      SearchTerm: searchValue,
      FilterType: filterType,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const {data: currentIncident} = useGetIncidentByIdQuery(
    {
      id: activeMessage,
    },
    {refetchOnMountOrArgChange: true}
  );

  return (
    <Container
      maxWidth={false}
      sx={{
        display: "grid",
        gap: 6,
        gridAutoFlow: "column",
        gridAutoColumns: "1fr 1fr",
        minHeight: "100%",
        py: 2,
      }}
    >
      {incidents && (
        <BaseTable headerTitle={"Zgłoszenia"}>
          <FilterBar
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            filterType={filterType}
            setFilterType={handleSetFilterType}
          />
          <Box sx={{display: "flex", flexDirection: "column", height: "100%"}}>
            {incidents?.items.map((item) => (
              <IncidentRow
                key={item.id}
                handleClick={setActiveMessage}
                activeId={activeMessage}
                id={item.id}
                title={item.title}
                createdDate={item.createdDate}
                isImportant={item.isImportant}
              />
            ))}
          </Box>
          <CustomPagination
            page={pageNumber}
            setPage={setPageNumber}
            pageSize={pageSize}
            total={incidents.totalCount}
          />
        </BaseTable>
      )}
      {activeMessage && currentIncident && (
        <BaseTable headerTitle={currentIncident.title}>
          <Box sx={{p: 2, display: "flex", flexDirection: "column", gap: 3}}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 3,
                paddingBottom: 2,
                borderBottomColor: theme.palette.silver,
                borderBottomWidth: 1,
                borderBottomStyle: "solid",
              }}
            >
              <IncidentDetailsRow
                title="Data wysłania"
                value={dayjs(currentIncident.createdDate).format(
                  "DD.MM.YYYY HH:mm"
                )}
              />
              <IncidentDetailsRow
                title="Obiekt"
                value={currentIncident.facilityName}
              />
              <IncidentDetailsRow
                title="Autor"
                value={currentIncident.author}
              />
            </Box>
            <Typography fontFamily="Regular" fontSize={18}>
              {currentIncident.message}
            </Typography>
          </Box>
        </BaseTable>
      )}
    </Container>
  );
};
