import {Box, Container} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import {BaseTable} from "src/components/BaseTable";
import {useEffect} from "react";
import {toast} from "react-toastify";
import {UserCreateType, UserRole} from "src/redux/api/types";
import {useCreateUserMutation} from "src/redux/api/userApi";
import {UsersForm} from "./components/UsersForm";
import {getRoleText} from "./UsersView";

export const AddUserFormPage = () => {
  const {type} = useParams();

  const roleName = type as UserRole;

  const navigate = useNavigate();

  const [createUser, {isSuccess}] = useCreateUserMutation();

  const handleGoBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success("Pomyślnie dodano");
      handleGoBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  const handleSubmitForm = (values: UserCreateType) => {
    createUser(values);
  };

  if (!type) {
    return null;
  }

  return (
    <Container
      maxWidth={false}
      sx={{
        minHeight: "100%",
        py: 2,
        display: "flex",
        gap: 4,
        flexDirection: "column",
      }}
    >
      <BaseTable
        headerTitle={getRoleText(roleName).button}
        headerClick={handleGoBack}
        headerButtonTitle="Powrót"
      >
        <Box py={2} px={3} gap={3} display="flex" flexDirection="column">
          <UsersForm
            disableRoles
            defaultValues={{
              role: roleName,
              email: "",
              internalId: "",
              phone: "",
              username: "",
            }}
            onSubmit={handleSubmitForm}
          />
        </Box>
      </BaseTable>
    </Container>
  );
};
