import {Box, CircularProgress, Typography, useTheme} from "@mui/material";
import {FormProvider, SubmitHandler, useForm} from "react-hook-form";
import {FormInput} from "src/components/FormInput";
import {TypeOf, object, string} from "zod";

import {Person} from "@mui/icons-material";
import {useEffect} from "react";
import {toast} from "react-toastify";
import {useForgotPasswordMutation} from "src/redux/api/authApi";
import {UnauthorizedLayout} from "src/components/UnauthorizedLayout";
import {PrimaryButton} from "src/components/PrimaryButton";
import {useNavigate} from "react-router-dom";

const forgotPasswordSchema = object({
  email: string({required_error: "Adres email jest wymagany"})
    .min(1, "Adres email jest wymagany")
    .email("Adres email jest niepoprawny"),
});

export type ForgotPasswordInput = TypeOf<typeof forgotPasswordSchema>;

export const ForgotPasswordPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const methods = useForm<ForgotPasswordInput>({});
  const {
    reset,
    handleSubmit,
    formState: {isSubmitSuccessful},
  } = methods;

  const [forgotPassword, {isLoading, isSuccess}] = useForgotPasswordMutation();

  const onSubmit: SubmitHandler<ForgotPasswordInput> = (values) => {
    forgotPassword(values);
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success("Sprawdź email", {position: "top-center"});
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful]);

  return (
    <UnauthorizedLayout
      showFooter
      headerText="Zapomniałeś hasła?"
      footerText="Przejdź do strony"
      onFooterButtonClick={() => navigate("/login")}
      footerButtonText="logowania"
    >
      <FormProvider {...methods}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            paddingX: 10,
            paddingY: 5,
            gap: 2,
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: theme.palette.white,
          }}
          component="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Box
            sx={{marginBottom: 4}}
            component="img"
            src={require("../static/brand.png")}
          />
          {isLoading ? (
            <CircularProgress />
          ) : isSuccess ? (
            <Typography fontSize={20} fontFamily={"Regular"}>
              Wysłano e-mail z linkiem do resetu hasła.
            </Typography>
          ) : (
            <>
              <FormInput
                fullWidth
                sx={{
                  padding: 1,
                  borderWidth: 1,
                  fontSize: 20,
                  borderColor: theme.palette.silver,
                  borderStyle: "solid",
                }}
                startAdornment={
                  <Person
                    fontSize="large"
                    htmlColor={theme.palette.warningRed}
                  />
                }
                placeholder="E-mail..."
                name="email"
                label=""
                type="email"
              />
              <PrimaryButton type="submit" name="zaloguj się" />
            </>
          )}
        </Box>
      </FormProvider>
    </UnauthorizedLayout>
  );
};
