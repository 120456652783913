import {Box, Button, Container, Typography, useTheme} from "@mui/material";
import React from "react";

type UnauthorizedLayoutProps = {
  showFooter?: boolean;
  headerText?: string;
  footerText?: string;
  footerButtonText?: string;
  onFooterButtonClick?: () => void;
  children: React.ReactNode;
};

export const UnauthorizedLayout = ({
  children,
  showFooter,
  footerText,
  headerText,
  footerButtonText,
  onFooterButtonClick,
}: UnauthorizedLayoutProps) => {
  const theme = useTheme();

  return (
    <Container
      maxWidth={false}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: theme.palette.lightGray,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 100,
          minWidth: "30%",
        }}
      >
        <Container
          sx={{
            display: "flex",
            padding: 7,
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: theme.palette.raisinBlack,
          }}
        >
          <Typography
            fontFamily="Bold"
            textAlign="center"
            component="h1"
            fontSize={28}
            color={theme.palette.common.white}
            textTransform="uppercase"
          >
            {headerText}
          </Typography>
        </Container>
        {children}
        {showFooter && (
          <Container
            sx={{
              padding: 2,
              display: "flex",
              minHeight: "20%",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: theme.palette.raisinBlack,
            }}
          >
            {footerText && (
              <Typography
                display="inline"
                fontFamily="Regular"
                textAlign="center"
                component="h1"
                fontSize={18}
                color={theme.palette.common.white}
              >
                {footerText}
              </Typography>
            )}
            {footerButtonText && onFooterButtonClick && (
              <Button
                onClick={() => onFooterButtonClick()}
                type="button"
                sx={{textTransform: "none"}}
              >
                <Typography
                  fontFamily="Regular"
                  textAlign="center"
                  component="h1"
                  fontSize={18}
                  display="inline"
                  color={theme.palette.warningRed}
                  sx={{
                    ":hover": {
                      textDecoration: "underline",
                    },
                  }}
                >
                  {footerButtonText}
                </Typography>
              </Button>
            )}
          </Container>
        )}
      </Box>
      <Box
        sx={{right: 0, bottom: 0, position: "absolute", zIndex: 0}}
        component="img"
        src={require("../static/login_bg.png")}
      />
    </Container>
  );
};
