import {Box, Checkbox, TextField, Typography, useTheme} from "@mui/material";
import {useState} from "react";
import {BaseTable} from "src/components/BaseTable";
import {PrimaryButton} from "src/components/PrimaryButton";
import {DutyTaskListItem} from "src/redux/duties/duties.types";

type DutyTaskFormProps = {
  dutyTasks: DutyTaskListItem[];
  handleChangeStatus: (id: string, value: boolean) => void;
  handleSubmitDuty: (value: string) => void;
};

export const UserDutyTaskForm = ({
  dutyTasks,
  handleChangeStatus,
  handleSubmitDuty,
}: DutyTaskFormProps) => {
  const theme = useTheme();

  const [description, setDescription] = useState("");

  return (
    <BaseTable headerTitle="Lista służby">
      <Box display="flex" flexDirection="column" p={2} pb={1} gap={2}>
        {dutyTasks?.length === 0 ? (
          <Typography textTransform="uppercase">
            Lista zadań została ukończona
          </Typography>
        ) : (
          <>
            {dutyTasks?.map((task) => (
              <Box
                key={task.id}
                display="flex"
                alignItems="center"
                sx={{
                  borderBottomColor: theme.palette.silver,
                  borderBottomStyle: "solid",
                  borderBottomWidth: 1,
                }}
              >
                <Checkbox
                  checked={task.checked}
                  onChange={(e, v) => handleChangeStatus(task.id, v)}
                />
                <Typography fontFamily="Regular" fontSize={18}>
                  {task.description}
                </Typography>
              </Box>
            ))}

            <Box display="flex" flexDirection="column" gap={2}>
              <Typography fontFamily={"Bold"} fontSize={18}>
                Opis:{" "}
                {dutyTasks.filter((task) => task.checked).length !==
                dutyTasks.length
                  ? "Wymagane"
                  : ""}
              </Typography>
              <TextField
                multiline
                placeholder="Opis"
                onChange={(e) => setDescription(e.target.value)}
                value={description}
              />
            </Box>
            <PrimaryButton
              disabled={
                dutyTasks.filter((task) => task.checked).length !==
                  dutyTasks.length && description.length === 0
              }
              onClick={() => handleSubmitDuty(description)}
              name="Zatwierdź"
              px={0.5}
              py={0.5}
            />
          </>
        )}
      </Box>
    </BaseTable>
  );
};
