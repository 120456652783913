import { Box, TextField, Typography, useTheme } from "@mui/material";
import { PrimaryButton } from "./PrimaryButton";

type BaseTableProps = {
  headerTitle: string;
  headerButtonTitle?: string;
  headerClick?: () => void;
  children?: React.ReactNode;
  hideButton?: boolean;
  searchValue?: string;
  setSearchValue?: (value: string) => void;
};

export const BaseTable = ({
  headerTitle,
  headerButtonTitle,
  headerClick,
  children,
  hideButton = false,
  searchValue = "",
  setSearchValue,
}: BaseTableProps) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.lightGray,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          bgcolor: theme.palette.raisinBlack,
          py: 1.5,
          px: 2,
          display: "flex",
          minHeight: "60px",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        <Typography
          height={"100%"}
          fontSize={22}
          color={theme.palette.white}
          fontFamily={"Bold"}
          textTransform="uppercase"
        >
          {headerTitle}
        </Typography>
        {headerButtonTitle && !hideButton && headerClick && (
          <PrimaryButton
            onClick={headerClick}
            fontFamily="SemiBold"
            name={headerButtonTitle}
            fontSize={20}
            py={0.5}
            px={2}
          />
        )}
      </Box>
      {!!setSearchValue && (
        <Box
          sx={{
            bgcolor: theme.palette.raisinBlack,
            py: 1.5,
            px: 2,
            display: "flex",
            minHeight: "60px",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <TextField
            sx={{
              bgcolor: theme.palette.white,
              borderRadius: 2,
            }}
            fullWidth
            placeholder="Wyszukaj pracownika"
            variant="outlined"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </Box>
      )}
      {children}
    </Box>
  );
};
