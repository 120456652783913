import {isRejectedWithValue} from "@reduxjs/toolkit";
import type {MiddlewareAPI, Middleware} from "@reduxjs/toolkit";
import {toast} from "react-toastify";
import {ErrorResponse} from "./api/types";

/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action)) {
      if (action.error && action.payload) {
        const error = action.payload as ErrorResponse;
        if (error.data && error.data.reason) {
          if (error.data.code === "user_already_assigned_this_date") {
            return;
          }
          toast.warn(error.data.reason);
        }
      } else {
        toast.warn(
          "data" in action.error
            ? (action.error.data as {message: string}).message
            : action.error.message
        );
      }
    }

    return next(action);
  };
