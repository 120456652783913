import {Box, Typography} from "@mui/material";

type MessageInfoBarProps = {
  title: string;
  value: string;
  border?: string;
};

export const MessageInfoBar = ({title, value, border}: MessageInfoBarProps) => {
  return (
    <Box p={1} display="flex" flexDirection="column">
      <Box
        px={2}
        sx={
          border
            ? {
                borderRightStyle: "solid",
                borderRightWidth: 1,
                borderRightColor: border,
              }
            : {}
        }
      >
        <Typography fontFamily="Regular" fontSize={18}>
          {title}
        </Typography>
        <Typography fontFamily="Regular" fontSize={18} px={1}>
          {value}
        </Typography>
      </Box>
    </Box>
  );
};
