import {Box, Container, useTheme} from "@mui/material";
import {PrimaryButton} from "./PrimaryButton";
import {CalendarMonth, Warning, Person, Logout} from "@mui/icons-material";
import {useDispatch} from "react-redux";
import {logout} from "src/redux/user/userSlice";
import {useNavigate} from "react-router-dom";
import {forwardRef} from "react";
import {routes} from "src/routes/routes";
import {useAppSelector} from "src/redux/store";

const Header = forwardRef<HTMLDivElement>((props, ref) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.userState.user);

  const logoutPress = () => {
    dispatch(logout());
  };

  const handleRedirectSchedule = () => {
    if (user?.role === "employee" || user?.role === "senior_security_worker") {
      navigate(routes.schedules.me);
      return;
    }
    navigate(routes.schedules.main);
  };

  return (
    <div ref={ref}>
      <Container
        sx={{
          justifyContent: "end",
          display: "flex",
          alignItems: "center",
          backgroundColor: theme.palette.raisinBlack,
          position: "static",
          py: 3,
          px: 4,
        }}
        maxWidth={false}
        disableGutters
      >
        <Box
          sx={{
            height: 130,
            width: 180,
            mx: 6,
            left: 0,
            top: 0,
            padding: 3,
            alignItems: "center",
            justifyContent: "center",
            boxSizing: "border-box",
            zIndex: 100,
            position: "absolute",
            backgroundColor: theme.palette.white,
            boxShadow: `0px 0px 10px ${theme.palette.darkGray}`,
          }}
          component="img"
          src={require("../static/brand.png")}
        />

        <Box
          sx={{
            display: "grid",
            gridAutoFlow: "column",
            gridAutoColumns: "1fr",
            gap: 2,
          }}
        >
          {user?.role !== "customer_representative" && (
            <>
              <PrimaryButton
                onClick={() => navigate(routes.incidents.add)}
                name="zgłoś ZDARZENIE"
              >
                <Warning htmlColor={theme.palette.white} />
              </PrimaryButton>

              <PrimaryButton onClick={handleRedirectSchedule} name="GRAFIK">
                <CalendarMonth htmlColor={theme.palette.white} />
              </PrimaryButton>
            </>
          )}
          <PrimaryButton
            onClick={() => navigate("/my-account")}
            name="Moje konto"
          >
            <Person htmlColor={theme.palette.white} />
          </PrimaryButton>
          <PrimaryButton onClick={logoutPress} name="Wyloguj">
            <Logout htmlColor={theme.palette.white} />
          </PrimaryButton>
        </Box>
      </Container>
    </div>
  );
});

export default Header;
