import {PaginatorParamsFilterType} from "src/redux/api/types";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import dayjs from "dayjs";
type FilterInputProps = {
  filterType?: PaginatorParamsFilterType;
  searchValue: string;
  setSearchValue: (value: string) => void;
};

export const FilterInput = ({
  filterType,
  searchValue,
  setSearchValue,
}: FilterInputProps) => {
  switch (filterType) {
    case "created_30_days_ago": {
      return null;
    }
    case "date": {
      return (
        <DatePicker
          sx={{
            width: "100%",
          }}
          format="DD-MM-YYYY"
          defaultValue={dayjs(new Date()).format("DD-MM-YYYY")}
          value={dayjs(searchValue).format("DD-MM-YYYY")}
          onChange={(e) =>
            setSearchValue(e ? dayjs(e).format("DD-MM-YYYY") : "")
          }
        />
      );
    }
    case "eventType": {
      return (
        <FormControl>
          <RadioGroup row onChange={(e) => setSearchValue(e.target.value)}>
            <FormControlLabel
              value="information"
              control={<Radio />}
              label="Informacja"
            />
            <FormControlLabel value="alert" control={<Radio />} label="Alert" />
          </RadioGroup>
        </FormControl>
      );
    }
    case "facility": {
      return (
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Obiekt"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
      );
    }
    case "user": {
      return (
        <TextField
          fullWidth
          placeholder="Pracownik"
          variant="outlined"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
      );
    }
    default: {
      return null;
    }
  }
};
