import {Box, Container} from "@mui/material";
import {useNavigate, useSearchParams} from "react-router-dom";
import {BaseTable} from "src/components/BaseTable";
import {useEffect, useState} from "react";
import {PrimaryButton} from "src/components/PrimaryButton";
import {CustomConfirmationModal} from "src/components/CustomConfirmationModal";
import {FacilityRow} from "src/components/FacilityRow";
import {
  useDeleteUserMutation,
  useGetUserByIdQuery,
  useGetUsersByRoleQuery,
  useUpdateUserMutation,
} from "src/redux/api/userApi";
import {UserCreateType, UserRole} from "src/redux/api/types";
import {UsersForm} from "./components/UsersForm";
import {routes} from "src/routes/routes";
import {FacilityUserRow} from "src/components/FacilityUserRow";
import {CustomAccordion} from "src/components/CustomAccordion";
import {
  useUnassignFromFacilityMutation,
  useUnassignFromSubFacilityMutation,
} from "src/redux/facilities/facilitiesApi";
import {toast} from "react-toastify";
import {useDebouncedState} from "src/hooks/useDebounce";

type UsersViewPropsType = {
  role: UserRole;
};

export const getRoleText = (userType: UserRole) => {
  switch (userType) {
    case "employee":
      return {
        title: "Wszyscy pracownicy",
        details: "Dane pracownika",
        button: "Dodaj pracownika",
      };
    case "customer_representative":
      return {
        title: "Wszyscy reprezntanci",
        details: "Dane reprezentanta",
        button: "Dodaj reprezentanta",
      };
    case "senior_security_worker":
      return {
        title: "Wszyscy starsi ochroniarze",
        details: "Dane starszego ochroniarza",
        button: "Dodaj starszego ochroniarza",
      };
    case "leader":
      return {
        title: "Wszyscy kierownicy",
        details: "Dane kierownika",
        button: "Dodaj kierownika",
      };
    default: {
      return {
        title: "Wszyscy pracownicy",
        details: "Dane pracownika",
        button: "Dodaj pracownika",
      };
    }
  }
};

export const UsersView = ({role}: UsersViewPropsType) => {
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  let activeUser = searchParams.get("id") ?? "";

  const [searchValue, setSearchValue] = useState("");

  const searchTerm = useDebouncedState(searchValue, 500);

  const setActiveUser = (id: string) => {
    setSearchParams({id: id});
  };

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const currentText = getRoleText(role);

  const {data: users, refetch: refreshList} = useGetUsersByRoleQuery(
    {role: role, searchTerm: searchTerm},
    {refetchOnMountOrArgChange: true}
  );

  const {data: user, refetch: refreshUser} = useGetUserByIdQuery(
    {
      id: activeUser,
    },
    {refetchOnMountOrArgChange: true}
  );

  const [deleteUser] = useDeleteUserMutation();

  const [unassignUser] = useUnassignFromFacilityMutation();

  const [unasignFromSubFacility] = useUnassignFromSubFacilityMutation();

  const [updateUser] = useUpdateUserMutation();

  const handleUnassign = (id: string, subFacility: boolean = false) => {
    if (!user) return;
    if (subFacility) {
      unasignFromSubFacility({subFacilityId: id, userId: user.id}).then(() =>
        refreshUser()
      );
      return;
    } else {
      unassignUser({facilityId: id, userId: user.id}).then(() => refreshUser());
    }
  };

  const handleSubmitForm = (values: UserCreateType) => {
    updateUser({...values, id: activeUser})
      .then(() => refreshUser())
      .then(() => refreshList())
      .then(() => toast.done("Pomyślnie zaktualizowano użytkownika"));
  };

  const handleSubmitDelete = (id: string) => {
    deleteUser({id: id})
      .then(() => {
        refreshList();
      })
      .then(() => toast.done("Pomyślnie usunięto użytkownika"));
    setConfirmModalOpen(false);
  };

  useEffect(() => {
    if (users && users.length > 0) {
      setActiveUser(users[0].id);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users, role]);

  return (
    <Container
      maxWidth={false}
      sx={{
        display: "grid",
        gap: 6,
        gridAutoFlow: "column",
        gridAutoColumns: "1fr 1fr",
        minHeight: "100%",
        py: 2,
      }}
    >
      <BaseTable
        headerButtonTitle={"Dodaj"}
        headerClick={() => navigate(routes.users.add(role))}
        headerTitle={currentText.title}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      >
        {users?.map((user) => (
          <FacilityRow
            onClickDelete={handleSubmitDelete}
            activeId={activeUser}
            onClick={setActiveUser}
            key={user.id}
            id={user.id}
            name={user.username}
            description={user.internalId}
            deleteInfo="czy chcesz usunąć danego użytkownika?"
          />
        ))}
      </BaseTable>
      {activeUser && (
        <BaseTable headerTitle={currentText?.details}>
          <Box py={2} px={3} gap={3} display="flex" flexDirection="column">
            {user && (
              <UsersForm
                onSubmit={handleSubmitForm}
                defaultValues={user}
                disableRoles={user.role === "customer_representative"}
              />
            )}
            <Box justifyContent="space-between" display="flex">
              <PrimaryButton
                onClick={() => setConfirmModalOpen(true)}
                name="Usuń"
                px={1}
                py={1}
                fontSize={18}
              />
            </Box>
          </Box>
          {user && user.assignedFacilities && (
            <Box>
              <CustomAccordion title="Przypisane obiekty">
                {user.assignedFacilities.map((facility) => {
                  if (facility.assignedSubFacilities.length > 0) {
                    return (
                      <CustomAccordion
                        key={facility.id}
                        title={`${facility.name} (przypisane podobiekty)`}
                      >
                        {facility.assignedSubFacilities.map((subfacility) => (
                          <FacilityUserRow
                            key={subfacility.id}
                            id={subfacility.id}
                            name={subfacility.name}
                            deleteMode
                            onClick={() => handleUnassign(subfacility.id, true)}
                          />
                        ))}
                      </CustomAccordion>
                    );
                  } else {
                    return (
                      <FacilityUserRow
                        key={facility.id}
                        id={facility.id}
                        name={facility.name}
                        deleteMode
                        onClick={() => handleUnassign(facility.id)}
                      />
                    );
                  }
                })}
              </CustomAccordion>
            </Box>
          )}
        </BaseTable>
      )}
      <CustomConfirmationModal
        confirmAction={() => handleSubmitDelete(activeUser)}
        handleClose={() => setConfirmModalOpen(false)}
        isOpen={confirmModalOpen}
        title="czy chcesz usunąć danego użytkownika?"
      />
    </Container>
  );
};
