import {zodResolver} from "@hookform/resolvers/zod";
import {Box, CircularProgress} from "@mui/material";
import {useEffect, useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {PrimaryButton} from "src/components/PrimaryButton";
import {AddScheduleFormType} from "src/redux/schedules/schedules.types";
import {object, string} from "zod";
import {FormTimePicker} from "src/components/FormTimePicker";
import {BaseTable} from "src/components/BaseTable";
import {AutocompleteEmployeeInput} from "./AutocompleteEmployeeInput";
import {FacilityAssignedUserType} from "src/redux/facilities/facilities.types";
import {AutocompleteSubFacilityInput} from "src/components/AutocompleteSubFacilityInput";
import {CustomConfirmationModal} from "src/components/CustomConfirmationModal";

type FacilityScheduleFormProps = {
  date: string;
  assignedUsers: FacilityAssignedUserType[];
  facilityId: string;
  onSubmit: (
    values: AddScheduleFormType,
    isReplacement?: boolean
  ) => Promise<string | undefined>;
  isLoading?: boolean;
};

const scheduleFormSchema = object({
  startDate: string({required_error: "Data rozpoczęcia jest wymagana"}).min(
    1,
    "Data rozpoczęcia jest wymagana"
  ),
  endDate: string({required_error: "Data zakończenia jest wymagana"}).min(
    1,
    "Data zakończenia jest wymagana"
  ),
  userId: string({required_error: "Pracownik jest wymagany"}).min(
    1,
    "Pracownik jest wymagany"
  ),
  subFacilityId: string().optional().nullable(),
});

export const FacilityScheduleForm = ({
  onSubmit,
  date,
  assignedUsers,
  isLoading = false,
  facilityId,
}: FacilityScheduleFormProps) => {
  const [modalInfo, setModalInfo] = useState<string | undefined>();
  const methods = useForm<AddScheduleFormType>({
    resolver: zodResolver(scheduleFormSchema),
  });

  const {
    handleSubmit,
    reset,
    watch,
    resetField,
    formState: {isDirty, isValid},
  } = methods;

  useEffect(() => {
    return () => {
      handleReset();
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formDate = new Date(date).getDate();

  const userId = watch("userId");

  const handleSubmitForm = async (values: AddScheduleFormType) => {
    if (modalInfo) {
      await onSubmit(values, true);
      setModalInfo(undefined);
      console.log("aaaaaaaa");
      handleReset();
      return;
    } else {
      const res = await onSubmit(values);
      if (res) {
        setModalInfo(res);
      } else {
        handleReset();
      }
    }
  };

  const handleReset = () => {
    console.log("reset");
    resetField("userId", undefined);
    reset(
      {
        endDate: undefined,
        startDate: undefined,
        facilityId: undefined,
        subFacilityId: undefined,
        userId: undefined,
      },
      {
        keepValues: false,
        keepDefaultValues: false,
        keepDirtyValues: false,
        keepIsSubmitted: false,
        keepSubmitCount: false,
      }
    );
  };

  return (
    <BaseTable headerTitle={`Dodaj do dnia - ${formDate}`}>
      <FormProvider {...methods}>
        <Box
          component="form"
          onSubmit={handleSubmit(handleSubmitForm)}
          display="flex"
          flexDirection="column"
          gap={2}
          sx={{width: "100%", p: 2}}
        >
          <AutocompleteEmployeeInput
            data={assignedUsers}
            fullWidth
            label="Pracownik"
            name="userId"
            type="text"
          />

          <AutocompleteSubFacilityInput
            userId={userId}
            facilityId={facilityId}
            fullWidth
            label="Podobiekt"
            name="subFacilityId"
            type="text"
          />

          <FormTimePicker name="startDate" label="Data rozpoczęcia" />
          <FormTimePicker name="endDate" label="Data zakończenia" />

          {isDirty && (
            <>
              {isLoading ? (
                <CircularProgress sx={{margin: "auto"}} />
              ) : (
                <PrimaryButton
                  fontSize={16}
                  px={1}
                  py={1}
                  disabled={!isValid}
                  type="submit"
                  name={"Zapisz"}
                />
              )}
            </>
          )}
        </Box>
        <CustomConfirmationModal
          confirmAction={handleSubmit(handleSubmitForm)}
          isOpen={!!modalInfo}
          isLoading={isLoading}
          title={modalInfo ?? ""}
          handleClose={() => setModalInfo(undefined)}
        />
      </FormProvider>
    </BaseTable>
  );
};
