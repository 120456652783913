import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {RootState} from "../store";
import {AddScheduleFormType, ScheduleType} from "./schedules.types";
import querystring from "query-string";

const BASE_URL = process.env.REACT_APP_API_URL as string;

export const schedulesApi = createApi({
  reducerPath: "schedulesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL + "/api/schedules",

    prepareHeaders: (headers, {getState}) => {
      const token = (getState() as RootState).userState.accessToken;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getSchedules: builder.query<
      ScheduleType[],
      {FacilityId?: string; UserId?: string; Year: number; Month: number}
    >({
      query(queryParams) {
        return {
          url: `?${querystring.stringify(queryParams)}`,
          method: "GET",
          credentials: "include",
        };
      },
    }),
    createSchedule: builder.mutation<{}, AddScheduleFormType>({
      query(query) {
        return {
          url: `/`,
          method: "POST",
          body: query,
          credentials: "include",
        };
      },
    }),
    replaceSchedule: builder.mutation<{}, AddScheduleFormType>({
      query(query) {
        return {
          url: `/replacement`,
          method: "POST",
          body: query,
          credentials: "include",
        };
      },
    }),
    deleteSchedule: builder.mutation<{}, {id: string}>({
      query(query) {
        return {
          url: `/${query.id}`,
          method: "DELETE",
          body: query,
          credentials: "include",
        };
      },
    }),
  }),
});

export const {
  useGetSchedulesQuery,
  useLazyGetSchedulesQuery,
  useCreateScheduleMutation,
  useDeleteScheduleMutation,
  useReplaceScheduleMutation,
} = schedulesApi;
