import {zodResolver} from "@hookform/resolvers/zod";
import {Box, CircularProgress} from "@mui/material";
import {useEffect} from "react";
import {FormProvider, SubmitHandler, useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {FormInput} from "src/components/FormInput";
import {PrimaryButton} from "src/components/PrimaryButton";
import {Section} from "src/components/Section";
import {IUser} from "src/redux/api/types";
import {useUpdateMeMutation} from "src/redux/api/userApi";
import {TypeOf, object, string} from "zod";

const userDataSchema = object({
  username: string({required_error: "To pole jest wymagane"})
    .min(4, "Imię i nazwisko jest zbyt krótkie")
    .max(40),
  phone: string({required_error: "Numer telefonu jest wymagany"})
    .regex(
      /^\+\d{1,3}\s?\d{1,14}$/,
      "Numer telefonu powinien zawierać kod kraju zaczynający się znakiem +"
    )
    .max(14, "Niepoprawny numer telefonu"),
  password: string({required_error: "Hasło jest wymagane"}).max(
    32,
    "Hasło jest zbyt długie"
  ),
});

type UserDataFormType = TypeOf<typeof userDataSchema>;

type UserDataFormProps = {
  user: IUser;
};

export const UserDataForm = ({user}: UserDataFormProps) => {
  const methods = useForm<UserDataFormType>({
    resolver: zodResolver(userDataSchema),
    defaultValues: {
      phone: user?.phone,
      username: user?.username,
    },
    mode: "onChange",
  });

  const [updateUser, {isLoading, isSuccess}] = useUpdateMeMutation();

  const {
    reset,
    handleSubmit,
    formState: {isSubmitSuccessful, dirtyFields},
  } = methods;

  const onSubmit: SubmitHandler<UserDataFormType> = (values) => {
    updateUser({...values, email: user.email});
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success("Pomyślnie zaktualizowano dane użytkownika");
    }
  }, [isSuccess]);

  useEffect(() => {
    reset({phone: user.phone, username: user.username});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful, user]);

  const shouldShowSubmit = dirtyFields.phone || dirtyFields.username;

  return (
    <Section name="Moje dane">
      <FormProvider {...methods}>
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          sx={{width: "100%"}}
          component="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormInput fullWidth label="Imię i nazwisko" name="username" />
          <FormInput
            fullWidth
            label="Numer telefonu"
            name="phone"
            type="phone"
          />
          <FormInput
            fullWidth
            label="Podaj obecne hasło"
            name="password"
            type="password"
          />

          {shouldShowSubmit && (
            <>
              {isLoading ? (
                <CircularProgress />
              ) : (
                <PrimaryButton
                  fontSize={16}
                  px={1}
                  py={1}
                  type="submit"
                  name="Aktualizuj dane"
                />
              )}
            </>
          )}
        </Box>
      </FormProvider>
    </Section>
  );
};
