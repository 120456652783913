import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IUser} from "../api/types";

export interface IUserState {
  user?: IUser;
  accessToken?: string;
}

const initialState: IUserState = {
  user: undefined,
  accessToken: undefined,
};

export const userSlice = createSlice({
  initialState,
  name: "userSlice",
  reducers: {
    logout: () => initialState,
    setUser: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload;
    },
    setAccessToken: (state, action: PayloadAction<{accessToken: string}>) => {
      state.accessToken = action.payload.accessToken;
    },
  },
});

export default userSlice.reducer;

export const {logout, setUser, setAccessToken} = userSlice.actions;
