import {
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputProps,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import {FC} from "react";
import {Controller, useFormContext} from "react-hook-form";

export type RadioOption = {
  name: string;
  value: string | number | undefined | null | boolean;
  disabled?: boolean;
};

type IFormInputProps = {
  name: string;
  label: string;
  options: RadioOption[];
} & InputProps;

export const FormRadio: FC<IFormInputProps> = ({
  name,
  label,
  options,
  ...otherProps
}) => {
  const {
    control,
    formState: {errors},
  } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({field}) => (
        <FormControl fullWidth={otherProps.fullWidth}>
          <Container
            disableGutters
            maxWidth={false}
            sx={{display: "flex", gap: 2, alignItems: "center"}}
          >
            {label && (
              <Typography
                component={"span"}
                variant="body2"
                fontFamily="Regular"
                minWidth={180}
                fontSize={20}
              >
                {label}
              </Typography>
            )}
            <RadioGroup
              row
              defaultValue={field.value ?? ""}
              value={field.value ?? ""}
              onChange={(e) => {
                if (!e.target.value || e.target.value.length === 0) {
                  field.onChange("");
                }
                if (e.target.value === "false" || e.target.value === "true") {
                  field.onChange(e.target.value === "false" ? false : true);
                } else {
                  field.onChange(e.target.value);
                }
              }}
              name={name}
            >
              {options.map((option) => (
                <FormControlLabel
                  disabled={otherProps.disabled}
                  key={option.name}
                  value={option.value}
                  label={option.name}
                  control={<Radio />}
                />
              ))}
            </RadioGroup>
          </Container>
          <FormHelperText sx={{px: 0, mx: 0}} error={!!errors[name]}>
            <Typography component={"span"} textAlign={"left"} fontSize={20}>
              {errors[name] ? errors[name]?.message?.toString() : ""}
            </Typography>
          </FormHelperText>
        </FormControl>
      )}
    />
  );
};
