import {Box, Container, useTheme} from "@mui/material";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router";
import {BaseTable} from "src/components/BaseTable";
import {PaginatorParamsFilterType} from "src/redux/api/types";
import {
  useChangeActivityMessageMutation,
  useDeleteMessageMutation,
  useGetMessageByIdQuery,
  useGetMessagesQuery,
  useUpdateMessageMutation,
} from "src/redux/messages/messages.api";
import {FilterBar} from "./components/FilterBar";
import dayjs from "dayjs";
import {FacilityRow} from "src/components/FacilityRow";
import {MessageForm} from "./components/MessageForm";
import {MessageCreateFormType} from "src/redux/messages/messages.types";
import {toast} from "react-toastify";
import {MessageInfoBar} from "./components/MessageInfoBar";
import {PrimaryButton} from "src/components/PrimaryButton";
import {routes} from "../../routes/routes";
import {CustomPagination} from "src/components/CustomPagination";
import {useSearchParams} from "react-router-dom";
import {useAppSelector} from "src/redux/store";

const pageSize = 8;

export const MessagesPage = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  let activeMessage = searchParams.get("id") ?? "";
  const theme = useTheme();
  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [filterType, setFilterType] = useState<PaginatorParamsFilterType>();

  const user = useAppSelector((state) => state.userState.user);

  const setActiveMessage = (id: string) => {
    setSearchParams({id: id});
  };

  const handleSetFilterType = (value?: PaginatorParamsFilterType) => {
    setFilterType(value);
    setSearchValue("");
  };

  const {data: messages, refetch: refetchMessages} = useGetMessagesQuery(
    {
      Page: pageNumber,
      PageSize: pageSize,
      SearchTerm: searchValue,
      FilterType: filterType,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const {data: currentMessage, refetch} = useGetMessageByIdQuery(
    {
      id: activeMessage,
    },
    {refetchOnMountOrArgChange: true}
  );

  const [updateStatus, {isSuccess: statusSuccess}] =
    useChangeActivityMessageMutation();

  const [updateMessage, {isSuccess: updateSuccess}] =
    useUpdateMessageMutation();

  const [deleteMessage, {isSuccess: deleteSuccess}] =
    useDeleteMessageMutation();

  const handleUpdateStatus = async (id: string) => {
    await updateStatus({id: id});
    refetch();
    refetchMessages();
  };

  const handleUpdate = (values: MessageCreateFormType) => {
    updateMessage({
      ...values,
      id: activeMessage,
      targetRole:
        values.targetRole === "leader" ||
        values.targetRole === "senior_security_worker"
          ? values.targetRole
          : null,
    }).then(() => {
      refetch();
    });
  };

  const handleDelete = (id: string) => {
    deleteMessage({id: id}).then((res) => {
      setActiveMessage("");
      refetchMessages();
      setPageNumber(1);
    });
  };

  useEffect(() => {
    if (statusSuccess) {
      toast.success("Zaktualizowano komunikat");
    }
  }, [statusSuccess]);
  useEffect(() => {
    if (deleteSuccess) {
      toast.success("Usnięto komunikat");
    }
  }, [deleteSuccess]);

  useEffect(() => {
    if (updateSuccess) {
      toast.success("Zaktualizowano komunikat");
    }
  }, [updateSuccess]);

  if (!user) {
    return null;
  }

  const canDelete =
    user.role !== "employee" && user.role !== "senior_security_worker";

  return (
    <Container
      maxWidth={false}
      sx={{
        display: "grid",
        gap: 6,
        gridAutoFlow: "column",
        gridAutoColumns: "1fr 1fr",
        minHeight: "100%",
        py: 2,
      }}
    >
      <BaseTable
        headerButtonTitle={"Dodaj"}
        headerClick={() => navigate(routes.messages.add)}
        headerTitle={"Komunikaty czasowe"}
      >
        <FilterBar
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          filterType={filterType}
          setFilterType={handleSetFilterType}
        />
        {messages && (
          <Box sx={{display: "flex", flexDirection: "column", height: "100%"}}>
            {messages?.items.map((item) => (
              <FacilityRow
                key={item.id}
                onClick={setActiveMessage}
                onClickDelete={canDelete ? handleDelete : undefined}
                deleteInfo="Czy chcesz usunąć dany komunikat?"
                checked={!item.isActive}
                onCheckClick={handleUpdateStatus}
                activeId={activeMessage}
                id={item.id}
                name={item.title}
                description={dayjs(item.createdDate).format("DD-MM-YYYY")}
              />
            ))}
            <CustomPagination
              page={pageNumber}
              setPage={setPageNumber}
              pageSize={pageSize}
              total={messages.totalCount}
            />
          </Box>
        )}
      </BaseTable>
      {activeMessage && currentMessage && (
        <BaseTable headerTitle="Zarządzaj komunikatem">
          <Box sx={{p: 2, display: "flex", flexDirection: "column", gap: 3}}>
            <Box
              display="flex"
              sx={{
                borderBottomStyle: "solid",
                borderBottomColor: theme.palette.silver,
                borderBottomWidth: 1,
              }}
            >
              <MessageInfoBar
                border={theme.palette.silver}
                title="Data dodania:"
                value={dayjs(currentMessage?.createdDate).format(
                  "DD.MM.YYYY HH:mm"
                )}
              />
              {currentMessage?.updatedDate && (
                <MessageInfoBar
                  border={theme.palette.silver}
                  title="Data aktualizacji:"
                  value={dayjs(currentMessage?.updatedDate).format(
                    "DD.MM.YYYY HH:mm"
                  )}
                />
              )}
              <MessageInfoBar
                title="Autor:"
                value={currentMessage?.author || ""}
              />
            </Box>
            <MessageForm
              userRole={user.role}
              onSubmit={handleUpdate}
              defaultValues={currentMessage}
            />
            <Box display="flex" justifyContent="space-between">
              {user.role !== "employee" && (
                <PrimaryButton
                  px={1}
                  py={1}
                  fontSize={18}
                  onClick={() => handleUpdateStatus(activeMessage)}
                  name={!currentMessage?.isActive ? "Aktywuj" : "Dezaktywuj"}
                />
              )}

              {canDelete && (
                <PrimaryButton
                  onClick={() => handleDelete(activeMessage)}
                  px={1}
                  py={1}
                  fontSize={18}
                  name="Usuń"
                />
              )}
            </Box>
          </Box>
        </BaseTable>
      )}
    </Container>
  );
};
