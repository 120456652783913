import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  IUser,
  NotificationListItem,
  PaginatorResponse,
  UserCreateType,
  UserDetailsType,
} from "./types";
import { setUser } from "../user/userSlice";
import { RootState } from "../store";
import { UpdateUserFormType } from "src/pages/MyAccount/myAccount.page";
import { UpdatePasswordType } from "src/pages/MyAccount/components/ChangePasswordForm";
import querystring from "query-string";
import { UsersFacility } from "../facilities/facilities.types";
import { UserDutyType } from "../duties/duties.types";
import { ScheduleType } from "../schedules/schedules.types";

const BASE_URL = process.env.REACT_APP_API_URL as string;

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL + "/api/users",
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).userState.accessToken;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getMe: builder.query<IUser, null>({
      query() {
        return {
          url: "me",
          credentials: "include",
          method: "GET",
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setUser(data));
        } catch (error) {
          console.log("error", error);
        }
      },
    }),
    getUserById: builder.query<UserDetailsType, { id: string }>({
      query(query) {
        return {
          url: `/${query.id}`,
          credentials: "include",
          method: "GET",
        };
      },
    }),
    getUsersByRole: builder.query<
      IUser[],
      { role: string; searchTerm?: string }
    >({
      query(query) {
        return {
          url: `/?${querystring.stringify(query)}`,
          credentials: "include",
          method: "GET",
        };
      },
    }),
    getUsers: builder.query<IUser[], {}>({
      query() {
        return {
          method: "GET",
          url: `/`,
          credentials: "include",
        };
      },
    }),
    updateUser: builder.mutation<{}, IUser>({
      query(data) {
        return {
          url: `/${data.id}`,
          method: "PUT",
          body: data,
          credentials: "include",
        };
      },
    }),
    getFacilitiesByUser: builder.query<
      UsersFacility[],
      { id: string; name: string }
    >({
      query(data) {
        return {
          url: `/${data.id}/facilities/?name=${data.name}`,
          method: "GET",
          credentials: "include",
        };
      },
    }),
    createUser: builder.mutation<{}, UserCreateType>({
      query(data) {
        return {
          url: `/sign-up`,
          method: "POST",
          body: data,
          credentials: "include",
        };
      },
    }),
    deleteUser: builder.mutation<{}, { id: string }>({
      query(data) {
        return {
          url: `/${data.id}`,
          method: "DELETE",
          body: data,
          credentials: "include",
        };
      },
    }),
    updateMe: builder.mutation<{}, UpdateUserFormType>({
      query(data) {
        return {
          url: "me",
          method: "PUT",
          body: data,
          credentials: "include",
        };
      },

      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            userApi.endpoints.getMe.initiate(null, { forceRefetch: true })
          );
        } catch (error) {
          console.log("error", error);
        }
      },
    }),

    updatePassword: builder.mutation<{}, UpdatePasswordType>({
      query(data) {
        return {
          url: "me/password",
          method: "PUT",
          body: data,
          credentials: "include",
        };
      },

      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            userApi.endpoints.getMe.initiate(null, { forceRefetch: true })
          );
        } catch (error) {
          console.log("error", error);
        }
      },
    }),

    getNotifications: builder.query<
      PaginatorResponse<NotificationListItem[]>,
      { Page: number; PageSize: number }
    >({
      query(query) {
        return {
          url: `me/notifications/?${querystring.stringify(query)}`,
          method: "GET",
          credentials: "include",
        };
      },
    }),
    markAsReadNotification: builder.mutation<{}, { id: string }>({
      query(data) {
        return {
          url: `me/notifications/${data.id}`,
          method: "PUT",
          credentials: "include",
        };
      },
    }),
    deleteNotification: builder.mutation<{}, { id: string }>({
      query(data) {
        return {
          url: `me/notifications/${data.id}`,
          method: "DELETE",
          credentials: "include",
        };
      },
    }),
    getCurrentDutyTasks: builder.query<UserDutyType, {}>({
      query(data) {
        return {
          url: "me/duty-tasks",
          method: "GET",
          credentials: "include",
        };
      },
    }),
    getCurrentSchedule: builder.query<ScheduleType[], {}>({
      query() {
        return {
          url: "me/schedules",
          method: "GET",
          credentials: "include",
        };
      },
    }),
  }),
});

export const {
  useGetMeQuery,
  useUpdateMeMutation,
  useUpdatePasswordMutation,
  useDeleteUserMutation,
  useGetUserByIdQuery,
  useGetUsersByRoleQuery,
  useUpdateUserMutation,
  useCreateUserMutation,
  useGetNotificationsQuery,
  useDeleteNotificationMutation,
  useMarkAsReadNotificationMutation,
  useGetUsersQuery,
  useLazyGetUsersQuery,
  useGetFacilitiesByUserQuery,
  useLazyGetFacilitiesByUserQuery,
  useLazyGetCurrentDutyTasksQuery,
  useLazyGetCurrentScheduleQuery,
  useGetCurrentScheduleQuery,
} = userApi;
