import {
  Autocomplete,
  Box,
  Container,
  FormControl,
  FormHelperText,
  InputProps,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useDebouncedState } from "src/hooks/useDebounce";
import { useGetFacilitiesByQueryQuery } from "src/redux/facilities/facilitiesApi";

type IFormInputProps = {
  name: string;
  label: string;
  defaultTextInputValue?: string;
} & InputProps;

export const AutocompleteFormInput: FC<IFormInputProps> = ({
  name,
  label,
  defaultValue,
  defaultTextInputValue = "",
  ...otherProps
}) => {
  const {
    control,
    reset,
    formState: { errors },
  } = useFormContext();
  const [query, setQuery] = useState(defaultTextInputValue);
  const debSearchText = useDebouncedState(query, 500);

  const { data } = useGetFacilitiesByQueryQuery(
    { searchTerm: debSearchText },
    { refetchOnMountOrArgChange: true }
  );

  const theme = useTheme();

  useEffect(() => {
    return () => {
      reset();
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!data) return null;

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={({ field }) => {
        const defaultVal = data?.find((item) => item.id === field.value);
        return (
          <FormControl fullWidth={otherProps.fullWidth}>
            <Container
              disableGutters
              maxWidth={false}
              sx={{ display: "flex", gap: 2, alignItems: "center" }}
            >
              {label && (
                <Typography fontFamily="Regular" minWidth={180} fontSize={20}>
                  {label}
                </Typography>
              )}
              <Autocomplete
                disabled={otherProps.disabled}
                fullWidth
                options={data ?? []}
                getOptionLabel={(option) => option.name}
                value={defaultVal ?? undefined}
                disableClearable
                onChange={(event, value) => {
                  field.onChange(value.id);
                }}
                renderOption={(props, option) => (
                  <Box
                    bgcolor={
                      field.value === option.id ? theme.palette.lightGray : ""
                    }
                    component="li"
                    {...props}
                    key={option.id}
                  >
                    <Typography>{option.name}</Typography>
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                    }}
                    placeholder={
                      defaultTextInputValue
                        ? defaultTextInputValue
                        : "Wpisz nazwę"
                    }
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                  />
                )}
              />
            </Container>
            <FormHelperText sx={{ px: 0, mx: 0 }} error={!!errors[name]}>
              <Typography textAlign={"left"} fontSize={20}>
                {errors[name] ? errors[name]?.message?.toString() : ""}
              </Typography>
            </FormHelperText>
          </FormControl>
        );
      }}
    />
  );
};
