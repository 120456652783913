import {Box, Typography} from "@mui/material";

type DetailsRowType = {
  title: string;
  value: string;
};

export const IncidentDetailsRow = ({title, value}: DetailsRowType) => {
  return (
    <Box display="flex" gap={1}>
      <Typography fontSize={16} fontFamily="Regular">
        {title}:
      </Typography>
      <Typography fontSize={16} fontFamily="Regular">
        {value}
      </Typography>
    </Box>
  );
};
