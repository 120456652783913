import {Box, Container, Typography, useTheme} from "@mui/material";
import {useGetIncidentsQuery} from "src/redux/incidents/incidentsApi";
import {BaseTable} from "src/components/BaseTable";
import {IncidentRow} from "src/components/IncidentRow";
import {useGetMessagesQuery} from "src/redux/messages/messages.api";
import {useNavigate} from "react-router-dom";
import {useAppSelector} from "src/redux/store";
import {useLazyGetCurrentDutyTasksQuery} from "src/redux/api/userApi";
import {useEffect, useMemo} from "react";
import {
  useChandeDutyTaskStatusMutation,
  useCloseScheduleDutyMutation,
} from "src/redux/duties/dutiesApi";
import {toast} from "react-toastify";
import {UserDutyTaskForm} from "./Duties/components/UserDutyTaskForm";

export const HomePage = () => {
  const theme = useTheme();
  const user = useAppSelector((state) => state.userState.user);
  const navigate = useNavigate();

  const {data: incidents} = useGetIncidentsQuery(
    {Page: 1, PageSize: 20, SearchTerm: ""},
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const {data: messages} = useGetMessagesQuery(
    {Page: 1, PageSize: 20, SearchTerm: ""},
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const [fetchDutyTasks, {data: dutySchedule}] =
    useLazyGetCurrentDutyTasksQuery();
  const [changeStatus, {isSuccess}] = useChandeDutyTaskStatusMutation();

  useEffect(() => {
    fetchDutyTasks({}); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeStatus = async (taskId: string, isCheck: boolean) => {
    if (!dutySchedule) return;
    await changeStatus({
      isCheck,
      taskId,
    });

    fetchDutyTasks({});
  };

  const messagesToShow = useMemo(() => {
    if (!messages) return [];
    if (user?.role === "employee") {
      return messages.items.filter((msg) => msg.isActive === true);
    } else {
      return messages.items;
    }
  }, [messages, user]);

  const [closeDuty, {isSuccess: isDutyClosed}] = useCloseScheduleDutyMutation();

  const handleCloseDuty = async (description: string) => {
    if (!dutySchedule) return;
    await closeDuty({description, id: dutySchedule.scheduleDutyId});

    fetchDutyTasks({});
  };

  useEffect(() => {
    if (isDutyClosed) {
      toast.success("Pomyślnie zamknięto służbę");
    }
  }, [isDutyClosed]);

  useEffect(() => {
    if (isSuccess) {
      toast.success("Zmieniono status zadania");
    }
  }, [isSuccess]);

  return (
    <Container
      maxWidth={false}
      sx={{
        display: "grid",
        columnGap: 5,
        rowGap: 2,
        gridAutoFlow: dutySchedule?.facilityName ? "row" : "column",
        gridAutoColumns: "1fr 1fr",
        gridAutoRows: "auto 1fr",
        minHeight: "100%",
        py: 2,
      }}
    >
      {dutySchedule?.facilityName && (
        <Box
          sx={{
            gridColumnStart: 1,
            p: 4,
            gridColumnEnd: 3,
            justifyContent: "center",
            maxHeight: 100,
            display: "flex",
            alignItems: "center",
            background: `linear-gradient(90deg,${theme.palette.info.dark},${theme.palette.info.light})`,
          }}
        >
          <Typography
            color={theme.palette.white}
            fontSize={28}
            fontFamily="Bold"
            textTransform="uppercase"
          >
            Twoja służba : {dutySchedule?.facilityName}
            {dutySchedule?.subFacilityName
              ? ", Posterunek: " + dutySchedule?.subFacilityName
              : ""}
          </Typography>
        </Box>
      )}
      {user?.role === "employee" || user?.role === "senior_security_worker" ? (
        <UserDutyTaskForm
          dutyTasks={
            dutySchedule?.isDutyClosed ? [] : dutySchedule?.tasks ?? []
          }
          handleChangeStatus={handleChangeStatus}
          handleSubmitDuty={handleCloseDuty}
        />
      ) : (
        <BaseTable
          headerButtonTitle="sprawdź wszystkie"
          headerClick={() => navigate("/incidents")}
          headerTitle="OSTATNIE ZGŁOSZENIA"
        >
          {incidents?.items.map((incident) => (
            <IncidentRow key={incident.id} {...incident} />
          ))}
        </BaseTable>
      )}
      {user?.role === "employee" || user?.role === "senior_security_worker" ? (
        <Box display="flex" flexDirection="column" gap={3}>
          {dutySchedule?.tasks && !dutySchedule.isDutyClosed ? (
            <Box
              minHeight={200}
              p={4}
              sx={{
                background: `linear-gradient(90deg,${theme.palette.warningRed},${theme.palette.error.dark})`,
              }}
            >
              <Typography
                color={theme.palette.white}
                fontSize={24}
                fontFamily="Bold"
                textTransform="uppercase"
              >
                uzupełnij listę służby!
              </Typography>
            </Box>
          ) : (
            <Box
              minHeight={200}
              p={4}
              sx={{
                background: `linear-gradient(90deg,${theme.palette.success.dark},${theme.palette.success.light})`,
              }}
            >
              <Typography
                color={theme.palette.white}
                fontSize={24}
                fontFamily="Bold"
                textTransform="uppercase"
              >
                Lista zadań ukończona!
              </Typography>
            </Box>
          )}
          <BaseTable
            headerButtonTitle="sprawdź wszystkie"
            headerClick={() => navigate("/messages")}
            headerTitle="Komunikaty"
          >
            {messagesToShow.map((messages) => (
              <IncidentRow key={messages.id} {...messages} />
            ))}
          </BaseTable>
        </Box>
      ) : (
        <BaseTable
          headerButtonTitle="sprawdź wszystkie"
          headerClick={() => navigate("/messages")}
          headerTitle="Komunikaty"
        >
          {messagesToShow.map((messages) => (
            <IncidentRow key={messages.id} {...messages} />
          ))}
        </BaseTable>
      )}
    </Container>
  );
};
