import {zodResolver} from "@hookform/resolvers/zod";
import {Box, CircularProgress} from "@mui/material";
import {useEffect, useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {PrimaryButton} from "src/components/PrimaryButton";
import {AddScheduleFormType} from "src/redux/schedules/schedules.types";
import {object, string} from "zod";
import {AutocompleteFacilityInput} from "./AutocompleteFacilityInput";
import {FormTimePicker} from "src/components/FormTimePicker";
import {BaseTable} from "src/components/BaseTable";
import {AutocompleteSubFacilityInput} from "src/components/AutocompleteSubFacilityInput";
import {CustomConfirmationModal} from "src/components/CustomConfirmationModal";

type EmployeeScheduleFormProps = {
  date: string;
  userId: string;
  onSubmit: (
    values: AddScheduleFormType,
    isReplacement?: boolean
  ) => Promise<string | undefined>;
  defaultValues?: Partial<AddScheduleFormType>;
  isLoading?: boolean;
};

const scheduleFormSchema = object({
  startDate: string({required_error: "Data rozpoczęcia jest wymagana"}),
  endDate: string({required_error: "Data zakończenia jest wymagana"}),
  facilityId: string({required_error: "Obiekt jest wymagany"}),
  subFacilityId: string().optional().nullable(),
});

export const EmployeeScheduleForm = ({
  defaultValues,
  onSubmit,
  date,
  userId,
  isLoading = false,
}: EmployeeScheduleFormProps) => {
  const [modalInfo, setModalInfo] = useState<string | undefined>();

  const methods = useForm<AddScheduleFormType>({
    resolver: zodResolver(scheduleFormSchema),
    defaultValues, // ustawienie defaultValues
  });

  const {
    handleSubmit,
    reset,
    watch,
    resetField,
    formState: {isDirty, isValid},
  } = methods;

  useEffect(() => {
    if (defaultValues) {
      reset(defaultValues);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  useEffect(() => {
    return () => {
      reset();
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formDate = new Date(date).getDate();

  const facilityId = watch("facilityId");

  useEffect(() => {
    resetField("subFacilityId"); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilityId]);

  const handleSubmitForm = async (values: AddScheduleFormType) => {
    if (modalInfo) {
      await onSubmit(values, true);
      setModalInfo(undefined);
      reset();
      return;
    } else {
      const res = await onSubmit(values);
      if (res) {
        setModalInfo(res);
      } else {
        reset();
      }
    }
  };

  if (!userId) {
    return null;
  }

  return (
    <BaseTable headerTitle={`Dodaj do dnia - ${formDate}`}>
      <FormProvider {...methods}>
        <Box
          component="form"
          onSubmit={handleSubmit(handleSubmitForm)}
          display="flex"
          flexDirection="column"
          gap={2}
          sx={{width: "100%", p: 2}}
        >
          <AutocompleteFacilityInput
            userId={userId}
            label="Obiekt"
            name="facilityId"
          />

          <AutocompleteSubFacilityInput
            userId={userId}
            facilityId={facilityId}
            fullWidth
            label="Podobiekt"
            name="subFacilityId"
            type="text"
          />

          <FormTimePicker
            defaultValue={null}
            name="startDate"
            label="Data rozpoczęcia"
          />
          <FormTimePicker
            defaultValue={null}
            name="endDate"
            label="Data zakończenia"
          />

          {isDirty && (
            <>
              {isLoading ? (
                <CircularProgress sx={{margin: "auto"}} />
              ) : (
                <PrimaryButton
                  fontSize={16}
                  px={1}
                  py={1}
                  disabled={!isValid}
                  type="submit"
                  name={"Zapisz"}
                />
              )}
            </>
          )}
        </Box>
        <CustomConfirmationModal
          confirmAction={handleSubmit(handleSubmitForm)}
          isOpen={!!modalInfo}
          isLoading={isLoading}
          title={modalInfo ?? ""}
          handleClose={() => setModalInfo(undefined)}
        />
      </FormProvider>
    </BaseTable>
  );
};
