import {combineReducers, configureStore} from "@reduxjs/toolkit";
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import {authApi} from "./api/authApi";
import userReducer from "./user/userSlice";
import {userApi} from "./api/userApi";
import {incidentsApi} from "./incidents/incidentsApi";
import {messagesApi} from "./messages/messages.api";
import persistReducer from "redux-persist/es/persistReducer";
import storage from "redux-persist/lib/storage";
import persistStore from "redux-persist/es/persistStore";
import {rtkQueryErrorLogger} from "./errorLogger";
import {facilitiesApi} from "./facilities/facilitiesApi";
import {dutiesApi} from "./duties/dutiesApi";
import {schedulesApi} from "./schedules/schedules.Api";

const persistConfig = {
  key: "root",
  storage: storage,
  whiteList: [userReducer],
};

const rootReducer = combineReducers({
  [authApi.reducerPath]: authApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [incidentsApi.reducerPath]: incidentsApi.reducer,
  [messagesApi.reducerPath]: messagesApi.reducer,
  [facilitiesApi.reducerPath]: facilitiesApi.reducer,
  [dutiesApi.reducerPath]: dutiesApi.reducer,
  [schedulesApi.reducerPath]: schedulesApi.reducer,
  userState: userReducer,
});

export type RootReducer = ReturnType<typeof rootReducer>;

const persistedReducer = persistReducer<RootReducer>(
  persistConfig,
  rootReducer
);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV === "development",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([
      authApi.middleware,
      userApi.middleware,
      incidentsApi.middleware,
      messagesApi.middleware,
      facilitiesApi.middleware,
      dutiesApi.middleware,
      schedulesApi.middleware,
      rtkQueryErrorLogger,
    ]),
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
