import {Delete} from "@mui/icons-material";
import {Box, IconButton, TextField, Typography} from "@mui/material";
import {useEffect, useRef, useState} from "react";
import {useDebouncedState} from "src/hooks/useDebounce";

type DutyTaskItemProps = {
  activeItem: string;
  taskId: string;
  description: string;
  onClick: (taskId: string) => void;
  updateTask: (taskId: string, taskDescription: string) => void;
  deleteTask: (taskId: string) => void;
};

export const DutyTaskItem = ({
  taskId,
  description,
  onClick,
  activeItem,
  updateTask,
  deleteTask,
}: DutyTaskItemProps) => {
  const [taskName, setTaskName] = useState(description);
  const debouncedTaskName = useDebouncedState(taskName, 500);
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (activeItem === taskId) {
      ref.current?.focus();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeItem]);

  useEffect(() => {
    if (
      debouncedTaskName &&
      debouncedTaskName.length > 3 &&
      debouncedTaskName !== description
    ) {
      updateTask(taskId, debouncedTaskName);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedTaskName]);

  if (activeItem === taskId) {
    return (
      <Box sx={{px: 2, py: 1}}>
        <TextField
          inputRef={ref}
          sx={{py: 1}}
          variant="standard"
          fullWidth
          value={taskName}
          onChange={(e) => setTaskName(e.target.value)}
        />
      </Box>
    );
  }

  return (
    <Box
      sx={{px: 2, py: 1, cursor: "pointer"}}
      display="flex"
      borderBottom={"1px solid silver"}
      alignItems="center"
      justifyContent="space-between"
      onClick={() => onClick(taskId)}
    >
      <Typography>{description}</Typography>
      <IconButton
        onClick={(e) => {
          deleteTask(taskId);
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <Delete />
      </IconButton>
    </Box>
  );
};
