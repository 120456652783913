import {
  Box,
  CircularProgress,
  Modal,
  Typography,
  useTheme,
} from "@mui/material";
import {PrimaryButton} from "./PrimaryButton";

type CustomModalProps = {
  isOpen: boolean;
  handleClose: () => void;
  confirmAction: () => void;
  title: string;
  isLoading?: boolean;
};

export const CustomConfirmationModal = ({
  isOpen,
  handleClose,
  confirmAction,
  title,
  isLoading = false,
}: CustomModalProps) => {
  const theme = useTheme();
  return (
    <Modal
      sx={{alignItems: "center", display: "flex", justifyContent: "center"}}
      open={isOpen}
      onClose={() => handleClose()}
    >
      <Box
        maxWidth="sm"
        bgcolor={theme.palette.white}
        padding={4}
        display="flex"
        gap={4}
        flexDirection="column"
      >
        <Typography
          fontFamily={"Regular"}
          textTransform={"uppercase"}
          fontSize={22}
        >
          {title}
        </Typography>
        <Box
          display="grid"
          gridAutoFlow="column"
          gridAutoColumns="1fr 1fr"
          gap={4}
        >
          {isLoading ? (
            <CircularProgress sx={{margin: "auto"}} />
          ) : (
            <>
              <PrimaryButton
                name="nie"
                onClick={(e) => {
                  handleClose();
                  e.stopPropagation();
                }}
              />
              <PrimaryButton
                name="tak"
                onClick={(e) => {
                  confirmAction();
                  e.stopPropagation();
                }}
              />
            </>
          )}
        </Box>
      </Box>
    </Modal>
  );
};
