import {Box, Container} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {BaseTable} from "src/components/BaseTable";
import {toast} from "react-toastify";
import {useEffect} from "react";
import {IncidentForm} from "./components/IncidentsForm";
import {IncidentCreateForm} from "src/redux/incidents/incidents.types";
import {useCreateIncidentMutation} from "src/redux/incidents/incidentsApi";

export const AddIncidentsPage = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  const [addIncident, {isSuccess, isLoading}] = useCreateIncidentMutation();

  const handleSubmitForm = (values: IncidentCreateForm) => {
    addIncident({
      ...values,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success("Dodano komunikat");
      handleGoBack();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  return (
    <Container
      maxWidth={false}
      sx={{
        minHeight: "100%",
        py: 2,
        display: "flex",
        gap: 4,
        flexDirection: "column",
      }}
    >
      <BaseTable
        headerTitle={"Zgłoś zdarzenie"}
        headerClick={handleGoBack}
        headerButtonTitle="Powrót"
      >
        <Box py={2} px={3} gap={3} display="flex" flexDirection="column">
          <IncidentForm isLoading={isLoading} onSubmit={handleSubmitForm} />
        </Box>
      </BaseTable>
    </Container>
  );
};
