import {createTheme} from "@mui/material";

export const theme = createTheme({
  palette: {
    raisinBlack: "#32292f",
    lightGray: "#f9f9f9d9",
    gray: "e2e2e2cc",
    darkGray: "#5e5e5e",
    white: "#ffffff",
    black: "#000000",
    warningRed: "#fe0002",
    silver: "#C0C0C0",
  },
});
