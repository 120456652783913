import {Box, Typography, useTheme} from "@mui/material";
import React from "react";
import {UserRole} from "src/redux/api/types";
import {useAppSelector} from "src/redux/store";

type MenuItemProps = {
  name: string;
  visibility: UserRole[];
  active?: boolean;
  onClick: () => void;
  children: (props: {color: string}) => React.ReactNode;
};

export const MenuItem = ({
  name,
  onClick,
  children,
  active = false,
  visibility,
}: MenuItemProps) => {
  const user = useAppSelector((state) => state.userState.user);

  const theme = useTheme();

  const color = active ? theme.palette.warningRed : theme.palette.raisinBlack;

  if (!user || !visibility.includes(user.role)) {
    return null;
  }

  return (
    <Box
      onClick={onClick}
      sx={{
        cursor: "pointer",
        ":hover": {opacity: 0.5},
        borderRadius: 0,
        gap: 1,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      {children({color: color})}
      <Typography
        textTransform="uppercase"
        fontFamily="Bold"
        fontSize={20}
        color={color}
      >
        {name}
      </Typography>
    </Box>
  );
};
