import {Box, Container} from "@mui/material";
import {useEffect, useMemo, useState} from "react";
import {ErrorResponse, useNavigate, useSearchParams} from "react-router-dom";
import {BaseTable} from "src/components/BaseTable";
import {FacilityRow} from "src/components/FacilityRow";
import {useGetUsersQuery} from "src/redux/api/userApi";
import {
  useCreateScheduleMutation,
  useDeleteScheduleMutation,
  useLazyGetSchedulesQuery,
  useReplaceScheduleMutation,
} from "src/redux/schedules/schedules.Api";
import {routes} from "src/routes/routes";
import {CalendarView} from "./components/CalendarView";
import {DayModal} from "./components/DayModal";
import {EmployeeScheduleForm} from "./components/EmployeeScheduleForm";
import {AddScheduleFormType} from "src/redux/schedules/schedules.types";
import {toast} from "react-toastify";

export const SchedulesPage = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  let activeUser = searchParams.get("id") ?? "";
  const [year, setYear] = useState<number>(new Date().getFullYear());
  const [month, setMonth] = useState<number>(new Date().getMonth());
  const [dayModalOpen, setDayModalOpen] = useState(false);
  const [modalDate, setModalDate] = useState<string | undefined>(
    new Date().toISOString()
  );
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const {data: users} = useGetUsersQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const [addSchedule, {isSuccess: createSuccess}] = useCreateScheduleMutation();

  const [deleteSchedule, {isSuccess: deleteSuccess}] =
    useDeleteScheduleMutation();

  const [addReplacementSchedule, {isSuccess: replacementSuccess}] =
    useReplaceScheduleMutation();

  const [fetchSchedules, {data: schedules}] = useLazyGetSchedulesQuery();

  const handleSetActive = (id: string) => {
    setSearchParams({id: id});
    setYear(new Date().getFullYear());
    setMonth(new Date().getMonth());
  };

  useEffect(() => {
    if (activeUser) {
      fetchSchedules({Month: month + 1, Year: year, UserId: activeUser});
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year, month, activeUser]);

  const employees = useMemo(
    () =>
      users?.filter(
        (user) =>
          user.role === "employee" || user.role === "senior_security_worker"
      ), // eslint-disable-next-line react-hooks/exhaustive-deps
    [users]
  );

  const handleDayClick = (date: string) => {
    setDayModalOpen(true);
    setModalDate(date);
  };

  const handleSetMonth = (newMonth: number) => {
    if (newMonth === 12) {
      setMonth(0);
      setYear((prev) => prev + 1);
    } else if (newMonth === -1) {
      setMonth(11);
      setYear((prev) => prev - 1);
    } else {
      setMonth(newMonth);
    }
  };

  const handleCloseModal = () => {
    setDayModalOpen(false);
    setModalDate(undefined);
  };

  const shouldShowForm = useMemo(
    () => (modalDate ? new Date(modalDate) > new Date() : false), // eslint-disable-next-line react-hooks/exhaustive-deps
    [modalDate]
  );

  useEffect(() => {
    if (deleteSuccess) {
      toast.success("Usunięto pomyślnie");
    }
  }, [deleteSuccess]);

  useEffect(() => {
    if (createSuccess || replacementSuccess) {
      toast.success("Dodano służbę");
    }
  }, [createSuccess, replacementSuccess]);

  const handleSubmit = async (
    values: AddScheduleFormType,
    isReplacement?: boolean
  ) => {
    if (!modalDate) return;

    setIsLoading(true);

    const startDate = new Date(modalDate);
    const formStartDate = new Date(values.startDate);
    startDate.setHours(formStartDate.getHours());
    startDate.setMinutes(formStartDate.getMinutes());
    const endDate = new Date(modalDate);
    const formEndDate = new Date(values.endDate);
    endDate.setHours(formEndDate.getHours());
    endDate.setMinutes(formEndDate.getMinutes());
    if (startDate > endDate) {
      endDate.setDate(startDate.getDate() + 1);
    }

    if (isReplacement) {
      await addReplacementSchedule({
        facilityId: values.facilityId,
        userId: activeUser,
        startDate: startDate,
        endDate: endDate,
        subFacilityId: values.subFacilityId,
      });

      fetchSchedules({
        Month: month + 1,
        Year: year,
        UserId: activeUser,
      });

      setIsLoading(false);
      return undefined;
    }

    return addSchedule({
      facilityId: values.facilityId,
      userId: activeUser,
      startDate: startDate,
      endDate: endDate,
      subFacilityId: values.subFacilityId,
    })
      .unwrap()
      .then(() => {
        fetchSchedules({
          Month: month + 1,
          Year: year,
          UserId: activeUser,
        });
        return undefined;
      })
      .catch((err: ErrorResponse) => {
        console.log("err", err);
        if (err.data.code === "user_already_assigned_this_date") {
          return err.data.reason;
        } else {
          return undefined;
        }
      })
      .finally(() => setIsLoading(false));
  };

  const handleDelete = (id: string) => {
    deleteSchedule({id}).then(() =>
      fetchSchedules({Month: month + 1, Year: year, UserId: activeUser})
    );
  };

  const selectedDate = useMemo(() => new Date(year, month), [month, year]);

  const daySchedules = useMemo(
    () =>
      modalDate
        ? schedules?.filter(
            (item) =>
              new Date(item.startDate).getDate() ===
                new Date(modalDate).getDate() &&
              new Date(item.startDate).getMonth() === month
          )
        : [], // eslint-disable-next-line react-hooks/exhaustive-deps
    [modalDate, schedules]
  );

  return (
    <Container
      maxWidth={false}
      sx={{
        display: "grid",
        gap: 6,
        gridAutoFlow: "column",
        gridAutoColumns: "1fr 1fr",
        minHeight: "100%",
        py: 2,
      }}
    >
      <BaseTable
        headerTitle="Pracownicy"
        headerButtonTitle="Grafik budynków"
        headerClick={() => navigate(routes.schedules.facility)}
      >
        <Box>
          {employees?.map((item) => (
            <FacilityRow
              key={item.id}
              activeId={activeUser}
              id={item.id}
              name={item.username}
              description={item.internalId ?? "-"}
              onClick={handleSetActive}
            />
          ))}
        </Box>
      </BaseTable>
      {schedules && (
        <Box sx={{gridColumnStart: 2, gridColumnEnd: 4}}>
          <BaseTable
            headerTitle={
              users?.find((item) => item.id === activeUser)?.username ?? ""
            }
          >
            <CalendarView
              schedules={schedules}
              month={month}
              year={year}
              selectedDate={selectedDate}
              setMonth={handleSetMonth}
              setYear={setYear}
              onClick={handleDayClick}
            />
          </BaseTable>
        </Box>
      )}
      <DayModal
        handleDelete={handleDelete}
        handleClose={handleCloseModal}
        daySchedules={daySchedules}
        isOpen={dayModalOpen}
        title={
          `Grafik - ${
            users?.find((item) => item.id === activeUser)?.username
          }` ?? ""
        }
      >
        {modalDate && shouldShowForm && (
          <EmployeeScheduleForm
            isLoading={isLoading}
            userId={activeUser}
            onSubmit={handleSubmit}
            date={modalDate}
          />
        )}
      </DayModal>
    </Container>
  );
};
