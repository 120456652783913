import {zodResolver} from "@hookform/resolvers/zod";
import {Box, CircularProgress} from "@mui/material";
import {useEffect, useMemo} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {AutocompleteFormInput} from "src/components/AutocompleteFormInput";
import {FormDateInput} from "src/components/FormDateInput";
import {FormInput} from "src/components/FormInput";
import {FormRadio} from "src/components/FormRadio";
import {PrimaryButton} from "src/components/PrimaryButton";
import {UserRole} from "src/redux/api/types";
import {MessageCreateFormType} from "src/redux/messages/messages.types";
import {boolean, object, string} from "zod";

type MessageFormProps = {
  onSubmit: (values: MessageCreateFormType) => void;
  defaultValues?: MessageCreateFormType;
  userRole: UserRole;
  isLoading?: boolean;
};

const messageFormSchema = object({
  startDate: string().optional().nullable(),
  endDate: string().optional().nullable(),
  title: string({required_error: "Tytuł jest wymagany"}),
  message: string({required_error: "Opis jest wymagany"}),
  facilityId: string({required_error: "Obiekt jest wymagany"}),
  isActive: boolean().nullable().optional(),
  eventType: string({required_error: "Typ jest wymagany"}),
  targetRole: string().nullable().optional(),
});

const messageFormSchemaAdmin = object({
  startDate: string({required_error: "Data rozpoczęcia jest wymagana"}),
  endDate: string({required_error: "Data zakończenia jest wymagana"}),
  title: string({required_error: "Tytuł jest wymagany"}),
  message: string({required_error: "Opis jest wymagany"}),
  facilityId: string({required_error: "Obiekt jest wymagany"}),
  isActive: boolean().nullable().optional(),
  eventType: string({required_error: "Typ jest wymagany"}),
  targetRole: string().nullable().optional(),
});

export const MessageForm = ({
  defaultValues,
  onSubmit,
  userRole,
  isLoading = false,
}: MessageFormProps) => {
  const methods = useForm<MessageCreateFormType>({
    resolver: zodResolver(
      userRole === "leader" ||
        userRole === "admin" ||
        userRole === "customer_representative"
        ? messageFormSchemaAdmin
        : messageFormSchema
    ),
    defaultValues: defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: {isDirty},
  } = methods;

  useEffect(() => {
    if (defaultValues) {
      reset(defaultValues);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  const isFormDisabled = useMemo(
    () =>
      !!defaultValues &&
      (userRole === "employee" || userRole === "senior_security_worker"),
    [userRole, defaultValues]
  );

  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        display="flex"
        flexDirection="column"
        gap={2}
        sx={{width: "100%"}}
      >
        {!defaultValues && (
          <FormRadio
            options={[
              {
                name: "Nieaktywny",
                value: false,
              },
              {
                name: "Aktywny",
                value: true,
              },
            ]}
            fullWidth
            label="Aktualny status"
            name="isActive"
            type="radio"
          />
        )}

        <FormRadio
          disabled={isFormDisabled}
          options={[
            {
              name: "Kierownicy",
              value: "leader",
            },
            {
              name: "Wszyscy",
              value: "",
            },
            ...(userRole === "admin" || userRole === "leader"
              ? [
                  {
                    name: "Starszy pracownik",
                    value: "senior_security_worker",
                  },
                ]
              : []),
          ]}
          fullWidth
          label="Adresowanie"
          name="targetRole"
          type="radio"
        />

        <FormRadio
          disabled={isFormDisabled}
          options={[
            {
              name: "Informacje",
              value: "information",
            },
            {
              name: "Ważne",
              value: "alert",
            },
          ]}
          fullWidth
          label="Rodzaj zdarzenia"
          name="eventType"
          type="radio"
        />

        {userRole !== "employee" && userRole !== "senior_security_worker" && (
          <>
            <FormDateInput name="startDate" label="Data rozpoczęcia" />
            <FormDateInput name="endDate" label="Data zakończenia" />
          </>
        )}
        <AutocompleteFormInput
          {...(userRole !== "leader" &&
            userRole !== "admin" && {
              defaultTextInputValue: defaultValues?.facilityName,
            })}
          disabled={isFormDisabled}
          defaultValue={defaultValues?.facilityId}
          fullWidth
          label="Obiekt"
          name="facilityId"
          type="text"
        />

        <FormInput
          disabled={isFormDisabled}
          fullWidth
          label="Tytuł"
          name="title"
          type="text"
          placeholder="Tytuł"
        />
        <FormInput
          disabled={isFormDisabled}
          fullWidth
          label="Wiadomość"
          placeholder="Treść wiadomości"
          name="message"
          multiline
          type="text"
        />
        {isDirty && !isFormDisabled && (
          <>
            {isLoading ? (
              <CircularProgress sx={{margin: "auto"}} />
            ) : (
              <PrimaryButton
                fontSize={16}
                px={1}
                py={1}
                type="submit"
                name={"Zapisz"}
              />
            )}
          </>
        )}
      </Box>
    </FormProvider>
  );
};
