import {
  Autocomplete,
  Container,
  FormControl,
  FormHelperText,
  InputProps,
  TextField,
  Typography,
} from "@mui/material";
import {FC} from "react";
import {Controller, useFormContext} from "react-hook-form";

import {FacilityAssignedUserType} from "src/redux/facilities/facilities.types";

type IFormInputProps = {
  name: string;
  label: string;
  data: FacilityAssignedUserType[];
} & InputProps;

export const AutocompleteEmployeeInput: FC<IFormInputProps> = ({
  name,
  label,
  data,
  ...otherProps
}) => {
  const {
    control,
    formState: {errors},
  } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({field}) => {
        const selectedOption =
          data?.find((item) => item.id === field.value) || null;

        return (
          <FormControl fullWidth={otherProps.fullWidth}>
            <Container
              disableGutters
              maxWidth={false}
              sx={{display: "flex", gap: 2, alignItems: "center"}}
            >
              {label && (
                <Typography fontFamily="Regular" minWidth={180} fontSize={20}>
                  {label}
                </Typography>
              )}
              <Autocomplete
                fullWidth
                options={data}
                getOptionLabel={(option) => option.username}
                onChange={(event, newValue) =>
                  field.onChange(newValue?.id ?? undefined)
                }
                value={selectedOption}
                renderInput={(params) => (
                  <TextField
                    value={field.value}
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                    }}
                  />
                )}
              />
            </Container>
            <FormHelperText sx={{px: 0, mx: 0}} error={!!errors[name]}>
              <Typography textAlign={"left"} fontSize={20}>
                {errors[name] ? errors[name]?.message?.toString() : ""}
              </Typography>
            </FormHelperText>
          </FormControl>
        );
      }}
    />
  );
};
