import {CellRenderedProps} from "@aldabil/react-scheduler/types";
import {Box, Typography, useTheme} from "@mui/material";
import dayjs from "dayjs";
import {useMemo} from "react";
import {ScheduleType} from "src/redux/schedules/schedules.types";

type CalendarCellProps = {
  schedules: ScheduleType[];
  month: number;
  year: number;
  handleClick: (date: string) => void;
  facilityMode?: boolean;
} & CellRenderedProps;

export const CalendarCell = ({
  schedules,
  month,
  year,
  handleClick,
  facilityMode = false,
  ...props
}: CalendarCellProps) => {
  const theme = useTheme();
  const schedulesList = useMemo(
    () =>
      schedules.filter(
        (schedule) =>
          props.end.getDate() === new Date(schedule.startDate).getDate() &&
          props.end.getMonth() === month
      ), // eslint-disable-next-line react-hooks/exhaustive-deps
    [schedules, year, month, props.end]
  );

  const {scheduleStart, scheduleEndDate} = useMemo(
    () =>
      schedulesList.reduce(
        (acc, event) => {
          if (!acc.scheduleStart || event.startDate < acc.scheduleStart) {
            acc.scheduleStart = event.startDate;
          }

          if (!acc.scheduleEndDate || event.endDate > acc.scheduleEndDate) {
            acc.scheduleEndDate = event.endDate;
          }

          return acc;
        },
        {} as {
          scheduleStart: Date;
          scheduleEndDate: Date;
        }
      ), // eslint-disable-next-line react-hooks/exhaustive-deps
    [schedulesList]
  );

  const background = useMemo(
    () =>
      new Date().getDate() === new Date(props.end).getDate() &&
      month === new Date().getMonth() &&
      year === new Date().getFullYear()
        ? theme.palette.warningRed
        : new Date(scheduleStart) < new Date()
        ? theme.palette.raisinBlack
        : new Date(scheduleStart) > new Date()
        ? theme.palette.error.dark
        : theme.palette.white, // eslint-disable-next-line react-hooks/exhaustive-deps
    [props, scheduleStart, scheduleEndDate, month, year]
  );

  if (props.end.getMonth() !== month) {
    return <Box />;
  }

  return (
    <Box
      onClick={() => handleClick(props.end.toISOString())}
      sx={{
        flex: 1,
        display: "flex",
        cursor: "pointer",
        px: 1,
        py: 0,
        height: "100%",
        justifyContent: "space-between",
        flexDirection: "column",
        bgcolor: background,
      }}
    >
      {month === props.end.getMonth() && (
        <>
          {scheduleStart && scheduleEndDate && (
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              py={1}
            >
              <Typography color={theme.palette.white} fontFamily={"SemiBold"}>
                {dayjs(scheduleStart).format("HH:mm")}
              </Typography>
              <Typography color={theme.palette.white} fontFamily={"SemiBold"}>
                {" "}
                -{" "}
              </Typography>
              <Typography color={theme.palette.white} fontFamily={"SemiBold"}>
                {dayjs(scheduleEndDate).format("HH:mm")}
              </Typography>
            </Box>
          )}
          <Box display="flex" flexDirection="column">
            <Typography
              noWrap
              color={
                scheduleStart ? theme.palette.white : theme.palette.raisinBlack
              }
              fontFamily={"SemiBold"}
            >
              {schedulesList &&
                (schedulesList.length > 1
                  ? "+2"
                  : facilityMode === false
                  ? schedulesList[0]?.facilityName
                  : schedulesList[0]?.username)}
            </Typography>
            {schedulesList && schedulesList.length === 1 && (
              <Typography
                noWrap
                color={
                  scheduleStart
                    ? theme.palette.white
                    : theme.palette.raisinBlack
                }
                fontFamily={"SemiBold"}
              >
                {schedulesList[0]?.subFacilityName}
              </Typography>
            )}
          </Box>

          <Typography
            color={
              scheduleStart ? theme.palette.white : theme.palette.raisinBlack
            }
            fontFamily={"SemiBold"}
            width={"100%"}
            alignSelf="end"
            textAlign={"end"}
          >
            {props.end.getMonth() === month
              ? new Date(props.end).getDate()
              : ""}
          </Typography>
        </>
      )}
    </Box>
  );
};
