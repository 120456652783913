import {Box} from "@mui/system";
import {FilterSelect, SelectOption} from "src/components/FilterSelect";
import {
  PaginatorParamsFilterType,
  PaginatorWorkLogFilterType,
} from "src/redux/api/types";
import {FilterInput} from "./FilterInput";

const filterData: SelectOption[] = [
  {
    name: "po obiekcie",
    value: "facility",
  },
  {
    name: "po dacie",
    value: "date",
  },
  {
    name: "po statusie",
    value: "eventType",
  },
  {
    name: "po pracowniku",
    value: "user",
  },
  {
    name: "utworzone 30 dni temu",
    value: "created_30_days_ago",
  },
  {
    name: "Wyczyść",
    value: undefined,
  },
];

const worklogFilterData: SelectOption[] = [
  {
    name: "po obiekcie",
    value: "facility",
  },
  {
    name: "po dacie",
    value: "date",
  },
  {
    name: "po pracowniku",
    value: "user",
  },
  {
    name: "Wyczyść",
    value: undefined,
  },
];

type FilterBarProps = {
  filterType:
    | PaginatorParamsFilterType
    | PaginatorWorkLogFilterType
    | undefined;
  setFilterType: (
    value?: PaginatorParamsFilterType | PaginatorWorkLogFilterType
  ) => void;
  searchValue: string;
  variant?: "default" | "worklog";
  setSearchValue: (value: string) => void;
};

export const FilterBar = ({
  filterType,
  setFilterType,
  searchValue,
  setSearchValue,
  variant = "default",
}: FilterBarProps) => {
  return (
    <Box
      sx={{
        display: "grid",
        gridAutoFlow: "column",
        gridAutoColumns: "1fr 1fr 1fr 1fr",
        gap: 2,
        padding: 2,
      }}
    >
      <Box sx={{gridColumn: 3, width: "100%"}}>
        <FilterInput
          filterType={filterType}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
      </Box>
      {variant === "worklog" ? (
        <FilterSelect
          sx={{
            gridColumn: 4,
          }}
          value={filterType}
          onChange={(e) => {
            const value = e.target.value as PaginatorWorkLogFilterType;
            setFilterType(value);
          }}
          data={worklogFilterData}
          name=""
        />
      ) : (
        <FilterSelect
          sx={{
            gridColumn: 4,
          }}
          value={filterType}
          onChange={(e) => {
            const value = e.target.value as PaginatorParamsFilterType;
            setFilterType(value);
          }}
          data={filterData}
          name=""
        />
      )}
    </Box>
  );
};
