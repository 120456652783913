import {
  Box,
  CircularProgress,
  LinearProgress,
  Typography,
  useTheme,
} from "@mui/material";
import {FormProvider, SubmitHandler, useForm} from "react-hook-form";
import {FormInput} from "src/components/FormInput";
import {TypeOf, object, string} from "zod";
import {zodResolver} from "@hookform/resolvers/zod";

import {Lock, Person} from "@mui/icons-material";
import {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {useResetPasswordMutation} from "src/redux/api/authApi";
import {UnauthorizedLayout} from "src/components/UnauthorizedLayout";
import {PrimaryButton} from "src/components/PrimaryButton";
import {useNavigate, useSearchParams} from "react-router-dom";

const forgotPasswordSchema = object({
  email: string({required_error: "Adres email jest wymagany"})
    .min(1, "Adres email jest wymagany")
    .email("Adres email jest wymagany"),
  password: string({required_error: "Hasło jest wymagane"}).regex(
    /^(?=.[A-Za-z])(?=.*\d)(?=.*[^A-Za-z0-9]).{8,}$/,
    "Hasło musi mieć minimum 8 znaków, zawierać cyfrę i znak specjalny."
  ),
  confirmPassword: string({required_error: "Hasło jest wymagane"}),
}).refine((data) => data.password === data.confirmPassword, {
  message: "Hasła do siebie nie pasują",
  path: ["confirmPassword"],
});

export type ForgotPasswordInput = TypeOf<typeof forgotPasswordSchema>;

export type ResetPasswordPayload = Omit<
  ForgotPasswordInput,
  "confirmPassword"
> & {
  token: string;
};

export const ResetPasswordPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const methods = useForm<ForgotPasswordInput>({
    resolver: zodResolver(forgotPasswordSchema),
  });
  const [searchParams] = useSearchParams();
  const [token, setToken] = useState<string>("");
  const [progressValue, setProgressValue] = useState<number>(0);
  const [progressStatus, setProgressStatus] = useState<string>("Bardzo słabe");

  useEffect(() => {
    if (!window) return;
    const tokenParam = window.location.href.split("token=")[1];
    if (!tokenParam) {
      return;
    }
    setToken(tokenParam);
  }, [searchParams]);

  const {
    reset,
    handleSubmit,
    formState: {isSubmitSuccessful},
    watch,
  } = methods;

  const [resetPassword, {isLoading, isSuccess}] = useResetPasswordMutation();

  const onSubmit: SubmitHandler<ForgotPasswordInput> = (values) => {
    resetPassword({
      email: values.email,
      password: values.password,
      token: token,
    });
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful]);

  useEffect(() => {
    if (isSuccess) {
      toast.success("Hasło zostało zmienione", {position: "top-center"});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const passwordValue = watch("password");

  useEffect(() => {
    if (!passwordValue || passwordValue.length === 0) {
      setProgressStatus("Bardzo słabe");
      setProgressValue(0);
    } else if (
      passwordValue.match(/^(?=.[a-zA-Z])(?=.*\d)(?=.*[^A-Za-z\d])\S{12,}$/)
    ) {
      setProgressStatus("Bardzo dobre");
      setProgressValue(100);
    } else if (
      passwordValue.match(/^(?=.[A-Za-z])(?=.*\d)(?=.*[^A-Za-z0-9]).{8,}$/)
    ) {
      setProgressStatus("Solidne");
      setProgressValue(70);
    } else if (
      passwordValue.match(/^(?=.*[A-Za-z])(?=.*d)(?=.*[^A-Za-zd]).{6,}$/)
    ) {
      setProgressStatus("Przeciętne");
      setProgressValue(50);
    } else if (passwordValue.match(/^(?=.*[A-Za-zd]).{4,}$/)) {
      setProgressStatus("Słabe");
      setProgressValue(20);
    }
  }, [passwordValue]);

  return (
    <UnauthorizedLayout
      showFooter
      headerText="Zapomniałeś hasła?"
      footerText="Przejdź do strony"
      onFooterButtonClick={() => navigate("/login")}
      footerButtonText="logowania"
    >
      <FormProvider {...methods}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            paddingX: 10,
            paddingY: 5,
            gap: 2,
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: theme.palette.white,
          }}
          component="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Box
            sx={{marginBottom: 4}}
            component="img"
            src={require("../static/brand.png")}
          />
          {isLoading ? (
            <CircularProgress />
          ) : isSuccess ? (
            <Typography fontSize={20} fontFamily={"Regular"}>
              Hasło zostało zmienione
            </Typography>
          ) : (
            <>
              <FormInput
                fullWidth
                sx={{
                  padding: 1,
                  borderWidth: 1,
                  fontSize: 20,
                  borderColor: theme.palette.silver,
                  borderStyle: "solid",
                }}
                startAdornment={
                  <Person
                    fontSize="large"
                    htmlColor={theme.palette.warningRed}
                  />
                }
                placeholder="E-mail..."
                name="email"
                label=""
                type="email"
              />{" "}
              <FormInput
                fullWidth
                sx={{
                  padding: 1,
                  fontSize: 20,
                  borderWidth: 1,
                  borderColor: theme.palette.silver,
                  borderStyle: "solid",
                }}
                startAdornment={
                  <Lock fontSize="large" htmlColor={theme.palette.warningRed} />
                }
                placeholder="Nowe hasło"
                name="password"
                label=""
                type="password"
              />
              <Box sx={{width: "100%"}}>
                <LinearProgress
                  variant="determinate"
                  color={
                    progressValue > 99
                      ? "success"
                      : progressValue > 60
                      ? "info"
                      : "warning"
                  }
                  value={progressValue}
                />
                <Typography fontFamily={"SemiBold"} fontSize={18}>
                  {progressStatus}
                </Typography>
              </Box>
              <FormInput
                fullWidth
                sx={{
                  padding: 1,
                  fontSize: 20,
                  borderWidth: 1,
                  borderColor: theme.palette.silver,
                  borderStyle: "solid",
                }}
                startAdornment={
                  <Lock fontSize="large" htmlColor={theme.palette.warningRed} />
                }
                placeholder="Potwierdź nowe hasło"
                name="confirmPassword"
                label=""
                type="password"
              />
              <PrimaryButton type="submit" name="Zmień hasło" />
            </>
          )}
        </Box>
      </FormProvider>
    </UnauthorizedLayout>
  );
};
