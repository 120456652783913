import {Box} from "@mui/material";
import {Container} from "@mui/system";
import {useEffect, useMemo, useState} from "react";
import {useNavigate} from "react-router";
import {BaseTable} from "src/components/BaseTable";
import {FacilityRow} from "src/components/FacilityRow";
import {
  useDeleteDutyListMutation,
  useGetDutiesQuery,
  useGetDutyTasksQuery,
  useUpdateDutyListStatusMutation,
} from "src/redux/duties/dutiesApi";
import {routes} from "src/routes/routes";
import {DutyTaskListView} from "./components/DutyTaskList";
import {PrimaryButton} from "src/components/PrimaryButton";
import {CustomConfirmationModal} from "src/components/CustomConfirmationModal";
import {toast} from "react-toastify";
import {useSearchParams} from "react-router-dom";

export const DutiesPage = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  let activeId = searchParams.get("id") ?? "";
  const navigate = useNavigate();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const setActiveId = (id: string) => {
    setSearchParams({id: id});
  };

  const {data: dutiesList, refetch: refreshList} = useGetDutiesQuery(
    {},
    {refetchOnMountOrArgChange: true}
  );

  const {data: activeDutyList, refetch: refreshDuty} = useGetDutyTasksQuery(
    {id: activeId ?? ""},
    {refetchOnMountOrArgChange: true}
  );

  const [deleteDutyList, {isSuccess: deleteSuccess}] =
    useDeleteDutyListMutation();

  const [updateStatus, {isSuccess: updateSuccess}] =
    useUpdateDutyListStatusMutation();

  const handleDeleteDutyList = async (id: string) => {
    await deleteDutyList({id: id});
    await refreshList();
    setActiveId("");
    setConfirmModalOpen(false);
  };

  const handleUpdateStatus = async (id: string) => {
    await updateStatus({id: id});
    refreshList();
  };

  const isActive = useMemo(
    () => dutiesList?.find((item) => item.id === activeDutyList?.id)?.isActive, // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeId, activeDutyList, handleUpdateStatus]
  );

  useEffect(() => {
    if (updateSuccess) {
      toast.success("Pomyślnie zaktualizowano listę");
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (deleteSuccess) {
      toast.success("Usunięto pomyślnie");
    }
  }, [deleteSuccess]);

  return (
    <Container
      maxWidth={false}
      sx={{
        display: "grid",
        gap: 6,
        gridAutoFlow: "column",
        gridAutoColumns: "1fr 1fr",
        minHeight: "100%",
        py: 2,
      }}
    >
      <BaseTable
        headerButtonTitle={"Dodaj Liste"}
        headerClick={() => navigate(routes.duties.add)}
        headerTitle={"Listy"}
      >
        {dutiesList?.map((list) => (
          <FacilityRow
            activeId={activeId ?? ""}
            onClick={setActiveId}
            id={list.id}
            name={list.dutyName}
            description={list.facilityName}
            location={list.facilityLocation}
            key={list.id}
            onClickDelete={handleDeleteDutyList}
            checked={!list.isActive}
            onCheckClick={handleUpdateStatus}
            deleteInfo="Czy na pewno chcesz usunąć tą listę"
          />
        ))}
      </BaseTable>
      {activeDutyList?.id && (
        <BaseTable
          headerClick={() => navigate(routes.messages.add)}
          headerTitle={activeDutyList.dutyName}
        >
          <Box sx={{p: 2, gap: 2}}>
            <DutyTaskListView duty={activeDutyList} refreshDuty={refreshDuty} />
            <Box display="flex" justifyContent="space-between">
              <PrimaryButton
                onClick={() => setConfirmModalOpen(true)}
                px={1}
                py={1}
                name="Usuń"
                fontSize={18}
              />
              <PrimaryButton
                px={1}
                py={1}
                fontSize={18}
                onClick={() => handleUpdateStatus(activeDutyList.id)}
                name={isActive ? "Dezaktywuj" : "Aktywuj"}
              />
            </Box>
          </Box>
          <CustomConfirmationModal
            confirmAction={() => handleDeleteDutyList(activeDutyList.id)}
            handleClose={() => setConfirmModalOpen(false)}
            isOpen={confirmModalOpen}
            title={"czy chcesz usunąć daną listę?"}
          />
        </BaseTable>
      )}
    </Container>
  );
};
