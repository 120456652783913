import {useParams} from "react-router";
import {UserRole} from "src/redux/api/types";
import {UsersView} from "./UsersView";
import {useAppSelector} from "src/redux/store";
import {Navigate} from "react-router-dom";
export const UsersPage = () => {
  const {type} = useParams();

  const userState = useAppSelector((state) => state.userState);

  if (!type || !userState) {
    return null;
  }

  const role = type as UserRole;

  if (role === "leader" && userState.user?.role === "leader") {
    return <Navigate to={"/"} />;
  }

  return <UsersView role={role} />;
};
