import {
  Container,
  FormControl,
  FormHelperText,
  InputProps,
  Typography,
} from "@mui/material";
import {MobileDateTimePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import {FC} from "react";
import {Controller, useFormContext} from "react-hook-form";

type IFormInputProps = {
  name: string;
  label: string;
} & InputProps;

export const FormDateInput: FC<IFormInputProps> = ({
  name,
  label,
  ...otherProps
}) => {
  const {
    control,
    formState: {errors},
  } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({field}) => (
        <FormControl fullWidth={otherProps.fullWidth}>
          <Container
            disableGutters
            maxWidth={false}
            sx={{display: "flex", gap: 2, alignItems: "center"}}
          >
            {label && (
              <Typography fontFamily="Regular" minWidth={180} fontSize={20}>
                {label}
              </Typography>
            )}
            <MobileDateTimePicker
              ampm={false}
              ampmInClock={false}
              sx={{width: "100%"}}
              value={field.value ? dayjs(field.value) : undefined}
              format="DD-MM-YYYY HH:mm"
              inputRef={field.ref}
              onChange={(date) =>
                date ? field.onChange(dayjs(date).toISOString()) : undefined
              }
            />
          </Container>
          <FormHelperText sx={{px: 0, mx: 0}} error={!!errors[name]}>
            <Typography textAlign={"left"} fontSize={20}>
              {errors[name] ? errors[name]?.message?.toString() : ""}
            </Typography>
          </FormHelperText>
        </FormControl>
      )}
    />
  );
};
