import {Pagination} from "@mui/material";

type PaginationProps = {
  page: number;
  total: number;
  pageSize: number;
  setPage: (page: number) => void;
};

export const CustomPagination = ({
  page,
  setPage,
  total,
  pageSize,
}: PaginationProps) => {
  return (
    <Pagination
      sx={{mt: "auto", alignSelf: "center", display: "flex", p: 1}}
      count={Math.ceil(total / pageSize)}
      page={page}
      onChange={(e, v) => setPage(v)}
    />
  );
};
