import {useAppSelector} from "src/redux/store";
import {UserDataForm} from "./components/userDataForm";
import {MailForm} from "./components/MailForm";
import {ChangePasswordForm} from "./components/ChangePasswordForm";
import {CircularProgress, Container} from "@mui/material";

export type UpdateUserFormType = {
  password: string;
  username?: string;
  internalId?: string;
  email?: string;
  phone?: string;
};

export const MyAccountPage = () => {
  const user = useAppSelector((root) => root.userState.user);

  if (!user) {
    return <CircularProgress />;
  }

  return (
    <Container
      maxWidth={false}
      sx={{gap: 2, display: "flex", flexDirection: "column"}}
    >
      <UserDataForm user={user} />
      <MailForm user={user} />
      <ChangePasswordForm user={user} />
    </Container>
  );
};
