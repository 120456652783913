import {Navigate} from "react-router-dom";
import {useAppSelector} from "src/redux/store";

type Props = {
  children: JSX.Element;
};

export const UnauthorizedRoute = ({children}: Props) => {
  const user = useAppSelector((state) => state.userState.user);

  if (!!user) {
    return <Navigate to={"/"} />;
  }
  return children;
};
