import {Delete, Flag} from "@mui/icons-material";
import {Box, IconButton, Typography, useTheme} from "@mui/material";

type NotificationItemProps = {
  id: string;
  title: string;
  created_date: string;
  handleClick: (id: string) => void;
  handleDelete: (id: string) => void;
  handleMarkAsRead: (id: string) => void;
  isUnread?: boolean;
  isActive?: boolean;
  hideDeleteButton?: boolean;
};

export const NotificationItem = ({
  id,
  title,
  created_date,
  handleClick,
  handleDelete,
  handleMarkAsRead,
  isUnread = false,
  isActive = false,
  hideDeleteButton = false,
}: NotificationItemProps) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        px: 2,
        py: 1,
        display: "flex",
        cursor: "pointer",
        justifyContent: "space-between",
        ...(isActive && {backgroundColor: theme.palette.silver}),
        ":hover": {
          backgroundColor: theme.palette.silver,
        },
      }}
      onClick={() => handleClick(id)}
    >
      <Box>
        <Typography fontSize={16} fontFamily="Light">
          {created_date}
        </Typography>
        <Typography
          fontSize={18}
          fontFamily="SemiBold"
          sx={{...(isUnread ? {color: theme.palette.warningRed} : {})}}
        >
          {title}
        </Typography>
      </Box>
      <Box sx={{display: "flex"}}>
        {!hideDeleteButton && (
          <IconButton
            onClick={(e) => {
              handleDelete(id);
              e.stopPropagation();
            }}
          >
            <Delete />
          </IconButton>
        )}
        <IconButton
          onClick={(e) => {
            handleMarkAsRead(id);
            e.stopPropagation();
          }}
        >
          <Flag />
        </IconButton>
      </Box>
    </Box>
  );
};
